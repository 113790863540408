import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import httpClient from 'http-client';
import Cookies from 'universal-cookie';
import { removeProjectImage } from '../projects.api';
import { getImageLink } from '../utils/get-image-link';

function getBase64(img: File, callback: (result: any) => void) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file: File) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error('Image must smaller than 10MB!');
  }
  return isJpgOrPng && isLt2M;
}

interface UploadButtonProps {
  loading: boolean;
}

const UploadButton: React.FC<UploadButtonProps> = ({ loading }) => {
  return (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
};

export const ImageUploaderSingle = ({
  className,
  id,
  defaultProjectImage
}: {
  className: string;
  id: string;
  defaultProjectImage?: string;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState('');

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl: string) => {
        setLoading(false);
        setImageUrl(imageUrl);
      });
    }
  };

  useEffect(() => {
    if (defaultProjectImage) {
      setImageUrl(getImageLink(defaultProjectImage));
    }
    // eslint-disable-next-line
  }, []);

  const cookies = new Cookies();

  const token = cookies.get('token');
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const action = () =>
    Promise.resolve(
      `${httpClient.defaults.baseURL}/admin-offers/${id}/project-image`
    );

  return (
    <Upload
      name="project-image"
      listType="picture-card"
      className={className}
      showUploadList={{ showRemoveIcon: true }}
      headers={headers}
      action={action}
      defaultFileList={
        defaultProjectImage
          ? [
              {
                uid: defaultProjectImage as any,
                name: 'Project image',
                url: getImageLink(defaultProjectImage) as any,
                thumbUrl: getImageLink(defaultProjectImage)
              }
            ]
          : []
      }
      beforeUpload={beforeUpload}
      onChange={handleChange}
      onRemove={async () => {
        await removeProjectImage(id);
        setImageUrl('');
      }}
    >
      {imageUrl ? (
        <img src={imageUrl} alt="preview" style={{ width: '100%' }} />
      ) : (
        <UploadButton loading={loading} />
      )}
    </Upload>
  );
};
