import httpClient from 'http-client';

const getWallet = async () => {
  console.log('GET_WALLET_START');
  return httpClient
    .get<{ walletKey: string }>('/profile/wallet-key')
    .then((result) => {
      console.log('WALLET_RES: ', result);
      return { wallet: result.data.walletKey };
    })
    .catch((err) => console.error(err));
};

const setWallet = async (wallet: string) => {
  return httpClient
    .post<any>('/profile/wallet-key', { walletKey: wallet })
    .then((result) => {
      return result.data;
    })
    .catch((err) => console.error(err));
};

export const walletAPI = {
  getWallet,
  setWallet
};
