import { useEffect, useState } from 'react';
import { calcAndFormatRangeForDiagrams } from '../utils/calcAndFormatRangeForDiagrams';

export interface IRange {
  startDate: string;
  endDate: string;
}

export interface IReturn {
  [key: string]: IRange
}


export const useRangeForDiagrams = (...keys: string[]): [IReturn, ((values: (string[] | any)) => void)] => {
  const [ranges, setRanges] = useState({});

  const setRangeForDiagrams = (values: string[] | any) => {
    if (Array.isArray(values)) {
      values.forEach((item) => {
        setRanges((prevState) => ({
          ...prevState,
          [item]: calcAndFormatRangeForDiagrams({})
        }))
      })
    } else {
      for (const key in values) {
        if (typeof values[key] === 'string') {
          setRanges((prevState) => ({
            ...prevState,
            [key]: calcAndFormatRangeForDiagrams({rangeFromNowInMonths: Number(values[key])})
          }))
        } else {
          setRanges((prevState) => ({
            ...prevState,
            [key]: calcAndFormatRangeForDiagrams({
              startDate: values[key].startDate + 'T00:00:00.0000',
              endDate: values[key].endDate + 'T23:59:59.0000'
            })
          }))
        }
      }
    }



  };

  useEffect(() => {
    setRangeForDiagrams(keys)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [ranges, setRangeForDiagrams]
};