import { ActionTypes } from 'actions';
import { authAPI } from 'api';
import { Dispatch } from 'redux';
import { GADisableRequestDto, GAVerifyRequestDto } from 'types/dto';

export const registerGoogleAuthenticator = (email: string) => async () => {
  const {
    data: { qrcode, googleCode, backupCode }
  } = await authAPI.registerGoogleAuthenticator(email);

  return {
    qrcode,
    googleCode,
    backupCode
  };
};

export const verifyGoogleAuthenticator =
  (payload: GAVerifyRequestDto) => async (dispatch: Dispatch) => {
    await authAPI.verifyGoogleAuthenticator(payload);
    dispatch({ type: ActionTypes.googleAuthenticatorEnabled });
  };

export const disableGoogleAuthenticator =
  (payload: GADisableRequestDto) => async (dispatch: Dispatch) => {
    await authAPI.disableGoogleAuthenticator(payload);
    dispatch({ type: ActionTypes.googleAuthenticatorDisabled });
  };

export const resendGoogleAuthenticatorCode = () => async () => {
  return authAPI.resendGoogleAuthenticatorCode();
};
