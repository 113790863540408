import { Card, Divider, Table, Empty } from 'antd';
import { useEffect, useState } from 'react';
import { getAllUsers } from '../users.api';
import { useFormik } from 'formik';
import { Moment } from 'moment';
import { SearchBox } from '../components/SearchBox';
import { userColumns } from './users-columns';

export const Users = () => {
  const [page, setPage] = useState(0);
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [searchResults, setSearchResults] = useState([]);

  const formik = useFormik({
    initialValues: {
      field: 'firstName',
      search: '',
      startDate: '',
      endDate: ''
    },
    onSubmit: async (values) => {
      const {
        data: { results }
      } = await getAllUsers(values);

      setSearchResults(results);
    }
  });

  useEffect(() => {
    getAllUsers({ page }).then(({ data }) => {
      const { results, total } = data;
      setUsers(results);
      setTotalUsers(total);
    });
  }, [page]);

  const handlePaginationChange = (currentPage: number) => {
    setPage(currentPage - 1);
  };

  const handleSelectChange = (
    value: string,
    field: keyof typeof formik.values
  ) => {
    formik.setFieldValue(field, value);
  };

  const handleDateChange = (
    date: [Moment, Moment] | any,
    dateString: [string, string]
  ) => {
    const [startDate, endDate] = dateString;
    formik.setFieldValue('startDate', startDate);
    formik.setFieldValue('endDate', endDate);
  };

  return (
    <>
      <SearchBox
        field={formik.values.field}
        onFieldChange={(value) => handleSelectChange(value, 'field')}
        search={formik.values.search}
        onSearchChange={formik.handleChange}
        onDateChange={handleDateChange}
        onSubmit={formik.handleSubmit}
      />
      <Divider className="admin-divider" />
      {searchResults.length ? (
        <Card className="admin-card">
          <h2>Search Results: </h2>
          <Divider className="admin-divider" />
          <Table
            className="admin-table"
            dataSource={searchResults}
            columns={userColumns}
          ></Table>
        </Card>
      ) : formik.submitCount > 0 ? (
        <Empty />
      ) : null}

      <Divider className="admin-divider" />
      <Card className="admin-card">
        <h2>Users: </h2>
        <Divider className="admin-divider" />
        <Table
          className="admin-table"
          dataSource={users}
          columns={userColumns}
          pagination={{
            pageSize: 20,
            onChange: handlePaginationChange,
            total: totalUsers
          }}
        />
      </Card>
    </>
  );
};
