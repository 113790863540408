import styles from 'pages/Admin/index.module.scss';
import { ProjectCreatingForm } from './ProjectCreatingForm';

export const ProjectCreating = () => {
  return (
    <div className={styles.projects}>
      <ProjectCreatingForm />
    </div>
  );
};
