import { ActionTypes } from 'actions';
import axios, { CancelTokenSource } from 'axios';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import Cookies from 'universal-cookie';
import i18n from 'i18next';
import { Document } from './types';

const { REACT_APP_API } = process.env;
//const REACT_APP_API = "http://localhost:3000/api";
const cookies = new Cookies();

export const uploadDocument =
  (
    document: File,
    type: string,
    description: string,
    onProgress?: (progress: number, source: CancelTokenSource) => void,
  ) =>
  (dispatch: Dispatch, getState: Function) => {
    const {
      login: {
        user: { id },
      },
    } = getState();
    const url = REACT_APP_API + `/profile/${id}/documents`;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const options = {
      headers: { Authorization: `Bearer ${cookies.get('token')}` },
      onUploadProgress: (data: { loaded: number; total: number }) => {
        if (!!onProgress) {
          onProgress(Math.round((100 * data.loaded) / data.total), source);
        }
      },
      cancelToken: source.token,
    };

    const formData = new FormData();

    formData.append('document', document);
    formData.append('name', document.name);
    formData.append('description', description);
    formData.append('type', type);

    return axios.post(url, formData, options);
  };

export const getDocuments = () => async (dispatch: Dispatch) => {
  const url = REACT_APP_API + '/profile/documents';
  const options = {
    headers: { Authorization: `Bearer ${cookies.get('token')}` },
  };

  return axios.get<Array<Document>>(url, options);
};

export const deleteDocument = (documentId: string) => (dispatch: Dispatch) => {
  const url = REACT_APP_API + `/profile/documents/${documentId}`;
  const options = {
    headers: { Authorization: `Bearer ${cookies.get('token')}` },
  };

  return axios.delete(url, options);
};

export const uploadAvatar = (avatar: File) => (dispatch: Dispatch, getState: Function) => {
  const {
    login: {
      user: { id },
    },
  } = getState();
  const url = REACT_APP_API + `/profile/${id}/avatar`;
  const options = {
    headers: { Authorization: `Bearer ${cookies.get('token')}` },
  };

  const formData = new FormData();

  formData.append('avatar', avatar);

  return axios.post(url, formData, options);
};

export const deleteAvatar = () => (dispatch: Dispatch) => {
  const url = REACT_APP_API + `/profile/avatar`;
  const options = {
    headers: { Authorization: `Bearer ${cookies.get('token')}` },
  };

  return axios.delete(url, options);
};

export const updateUser = (payload: any) => async (dispatch: Dispatch, getState: Function) => {
  const {
    login: {
      user: { id },
    },
  } = getState();
  const url = REACT_APP_API + `/profile/${id}`;
  const options = {
    headers: { Authorization: `Bearer ${cookies.get('token')}` },
  };

  try {
    const { data } = await axios.put(url, payload, options);
    dispatch({ type: ActionTypes.loginSuccess, user: data });
    toast.success(i18n.t('notifications:profileUpdated'));
  } catch {}
};

export const changePassword = (oldPassword: string, newPassword: string) => async (dispatch: Dispatch) => {
  const url = REACT_APP_API + `/accounts/change-password`;

  const options = {
    headers: {
      Authorization: `Bearer ${cookies.get('token')}`
    }
  }

  return axios.put(url, { oldPassword, newPassword }, options);
}
