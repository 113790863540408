import { ActionTypes, Action } from 'actions';

export const languageReducer = (
  state: { language: string; country: { alpha2: string; alpha3: string } } = {
    language: 'en',
    country: { alpha2: 'af', alpha3: 'AFG' },
  },
  action: Action,
) => {
  switch (action.type) {
    case ActionTypes.changeLanguage:
      return { ...state, language: action.payload };
    case ActionTypes.changeCountry:
      return { ...state, country: action.payload };
    default:
      return state;
  }
};
