import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { toast } from 'react-toastify';
import styles from './index.module.scss';

interface Props {
  text: string;
}

const CopyField: React.FC<Props> = ({ text }) => {
  const onClick = async () => {
    await navigator.clipboard.writeText(text);
    toast.success('Successfuly coppied');
  };

  return (
    <div className={styles.copy} onClick={onClick}>
      {text}
      <CopyIcon />
    </div>
  );
};

export default CopyField;
