import { Link } from 'react-router-dom';
import { getImageLink } from '../utils/get-image-link';
import { RenderDate } from '../utils/render-date';

export interface UserColumn {
  id: string;
  firstName: string;
  avatar: string;
  country: string;
  email: string;
  createdAt: string;
  categorizationCompletedAt: string;
  appropriatenessCompletedAt: string;
  kycCompletedAt: string;
}

export const userColumns: any = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
    sorter: (a: UserColumn, b: UserColumn) =>
      a.firstName.length - b.firstName.length,
    render: (firstName: string, column: UserColumn) => (
      <Link to={`/admin/users/${column.id}`}>{firstName}</Link>
    )
  },
  {
    title: 'Avatar',
    dataIndex: 'avatar',
    key: 'avatar',
    render: (avatar: string) => {
      return (
        <img src={getImageLink(avatar)} width="70px" height="70px" alt="user" />
      );
    }
  },
  {
    title: 'Categorization Completed',
    dataIndex: 'categorizationCompletedAt',
    key: 'categorizationCompletedAt',
    render: RenderDate
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: RenderDate
  },
  {
    title: 'Appropriateness Completed',
    dataIndex: 'appropriatenessCompletedAt',
    key: 'appropriatenessCompletedAt',
    render: RenderDate
  },
  {
    title: 'KYC Completed',
    dataIndex: 'kycCompletedAt',
    key: 'kycCompletedAt',
    render: RenderDate
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    sorter: (a: UserColumn, b: UserColumn) => a.country > b.country
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: (a: UserColumn, b: UserColumn) => a.email < b.email
  }
];
