import { useFormik } from 'formik';
import React, { useState } from 'react';
import { ChangePasswordScheme } from 'utils/validation';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import styles from './index.module.scss';
import { changePassword } from 'actions';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import { walletAPI } from 'api/wallet';
import { decrypt, encrypt, validSeed } from 'utils/wallet';

export const ChangePassword: React.FC = () => {
    const initialValues = {
        oldPassword: '',
        newPassword: '',
        repeatNewPassword: '',
        passphrase: '',
    }
    const [showPassphrase, setShowPassphrase] = useState(false);
    const [changeStatus, setChangeStatus] = useState(true);

    const { t } = useTranslation()
    const dispatch = useAppDispatch();

    const formik = useFormik({
        validationSchema: ChangePasswordScheme,
        initialValues,
        onSubmit: async ({ oldPassword, newPassword, passphrase }) => {
            changeStatus && setShowPassphrase(false);
            try{
                const walletRes = await walletAPI.getWallet();
                const { wallet: oldWallet } = walletRes!;
                console.log(oldWallet)
                if (oldWallet) {
                    const decryptedWithOldPass = decrypt(oldWallet, oldPassword);
                    if (decryptedWithOldPass && validSeed(decryptedWithOldPass)) {
                        const encryptedWithNewPass = encrypt(decryptedWithOldPass, newPassword);
                        await walletAPI.setWallet(encryptedWithNewPass);
                        await dispatch(changePassword(oldPassword, newPassword));
                        toast.success(t('notifications:passwordChangeSuccess'));
                        setChangeStatus(true);
                    } else {
                        if (passphrase !== '') {
                            const decryptedWithPassphrase = decrypt(oldWallet, passphrase);
                            if (decryptedWithPassphrase && validSeed(decryptedWithPassphrase)) {
                                const encryptedWithNewPass = encrypt(decryptedWithPassphrase, newPassword);
                                await walletAPI.setWallet(encryptedWithNewPass);
                                await dispatch(changePassword(oldPassword, newPassword));
                                toast.success(t('notifications:passwordChangeSuccess'));
                                toast.success("Now your passphrase is your new password");
                                setChangeStatus(true);
                            } else {
                                toast.error("Your passphrase is not valid, please try again");
                                setChangeStatus(false);
                            }
                        } else {
                            setShowPassphrase(true);
                            setChangeStatus(false);
                        }
                    }
                } else {
                    await dispatch(changePassword(oldPassword, newPassword));
                    toast.success(t('notifications:passwordChangeSuccess'));
                    setChangeStatus(true);
                }
                
            }catch(e: any){
                if(e.response.data.message.includes('does not match')){
                    toast.error(t('notifications:passwordDoesntMatch'));
                    return;
                }
                toast.error(t('notifications:passwordChangeError'));
            }finally{
                formik.setSubmitting(false)
            }
        }
    });

    return (
        <div className={styles.wrapper}>
           <div className={styles.content}>
            <h6 className={styles.title}>
                {t('notifications:passwordChange')}
            </h6>
            <div className={styles.form}>
            <div className={styles.inputWrapper}>
                <Input
                    id='oldPassword'
                    name='oldPassword'
                    type='password'
                    className={cls(styles.input, { [styles.inputError]: formik.touched.oldPassword && formik.errors.oldPassword })}
                    placeholder={t('notifications:oldPassword')}
                    value={formik.values.oldPassword}
                    onBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                />
                {formik.touched.oldPassword && formik.errors.oldPassword && (
                    <div className={styles.error}>
                        <div className={styles.circle} />
                        <div>
                        <div>{formik.errors.oldPassword}</div>
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.inputWrapper}>
                <Input
                    id='newPassword'
                    name='newPassword'
                    type='password'
                    placeholder={t('notifications:newPassword')}
                    className={cls(styles.input, { [styles.inputError]: formik.touched.newPassword && formik.errors.newPassword })}
                    value={formik.values.newPassword}
                    onBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                />

                {formik.touched.newPassword && formik.errors.newPassword && (
                    <div className={styles.error}>
                        <div className={styles.circle} />
                        <div>{formik.errors.newPassword}</div>
                    </div>
                )}
            </div>
            <div className={styles.inputWrapper}>
                <Input
                    id='repeatNewPassword'
                    name='repeatNewPassword'
                    type='password'
                    placeholder={t('notifications:repeatNewPassword')}
                    className={cls(styles.input, { [styles.inputError]: formik.touched.repeatNewPassword && formik.errors.repeatNewPassword })}
                    value={formik.values.repeatNewPassword}
                    onBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                />

                {formik.touched.repeatNewPassword && formik.errors.repeatNewPassword && (
                    <div className={styles.error}>
                        <div className={styles.circle} />
                        <div>{formik.errors.repeatNewPassword}</div>
                    </div>
                )}
            </div>
            {
                showPassphrase && <div className={styles.inputWrapper}>
                <Input
                    id='passphrase'
                    name='passphrase'
                    type='text'
                    placeholder='Enter your passphrase'
                    className={cls(styles.input, { [styles.inputError]: formik.touched.passphrase && formik.errors.passphrase })}
                    value={formik.values.passphrase}
                    onBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                />

                {formik.touched.passphrase && formik.errors.passphrase && (
                    <div className={styles.error}>
                        <div className={styles.circle} />
                        <div>{formik.errors.passphrase}</div>
                    </div>
                )}
                </div>
            }
            <div>
                <Button 
                    onClick={formik.handleSubmit} 
                    text={t('notifications:passwordChange')} 
                    type="secondary" 
                    loading={formik.isSubmitting}
                    disabled={formik.isSubmitting}
                    className={styles.btn}
                />
            </div>
            </div>
           </div>
        </div>
    )
} 