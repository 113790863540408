import * as React from 'react';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import key from 'assets/icons/key.svg';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import cls from 'classnames';
import { ResetSchema } from 'utils/validation';

interface FormProps {
  handleSubmit: (password: string) => void;
}

interface InitialValues {
  password: string;
  repeatPassword: string;
}

const Form: React.FC<FormProps> = ({ handleSubmit }) => {
  const { t } = useTranslation();

  const initialValues: InitialValues = {
    password: '',
    repeatPassword: '',
  };

  const formik = useFormik({
    validationSchema: ResetSchema,
    initialValues,
    onSubmit: ({ password }) => handleSubmit(password),
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}> {t('login:forgotPassword')}</div>
      <img className={styles.icon} src={key} alt='' />
      <div className={styles.text}>{t('login:resetPrompt')}</div>
      {((formik.touched.password && formik.errors.password) ||
        (formik.touched.repeatPassword && formik.errors.repeatPassword)) && (
        <div className={styles.errorsMobile}>
          {formik.touched.password && formik.errors.password && (
            <div className={styles.errorMobile}>
              <div className={styles.circle} />
              <div>{formik.errors.password}</div>
            </div>
          )}
          {formik.touched.repeatPassword && formik.errors.repeatPassword && (
            <div className={styles.errorMobile}>
              <div className={styles.circle} />
              <div>{formik.errors.repeatPassword}</div>
            </div>
          )}
        </div>
      )}
      <div className={styles.inputWrapper}>
        <Input
          autoFocus
          id='password'
          name='password'
          className={cls(styles.input, {
            [styles.inputError]: formik.touched.password && formik.errors.password,
          })}
          type='password'
          placeholder={t('common:password')}
          value={formik.values.password}
          onBlur={formik.handleBlur}
          handleChange={formik.handleChange}
        />
        {formik.touched.password && formik.errors.password && (
          <div className={styles.error}>
            <div className={styles.circle} />
            <div>{formik.errors.password}</div>
          </div>
        )}
      </div>
      <div className={styles.inputWrapper}>
        <Input
          id='repeatPassword'
          name='repeatPassword'
          className={cls(styles.input, {
            [styles.inputError]: formik.touched.repeatPassword && formik.errors.repeatPassword,
          })}
          type='password'
          placeholder={t('common:repeatPassword')}
          value={formik.values.repeatPassword}
          onBlur={formik.handleBlur}
          handleChange={formik.handleChange}
        />
        {formik.touched.repeatPassword && formik.errors.repeatPassword && (
          <div className={styles.error}>
            <div className={styles.circle} />
            <div>{formik.errors.repeatPassword}</div>
          </div>
        )}
      </div>
      <Button
        text={t('common:submit')}
        type='secondary'
        className={styles.button}
        disabled={formik.isSubmitting}
        loading={formik.isSubmitting}
        onClick={formik.handleSubmit}
      />
    </div>
  );
};

export default Form;
