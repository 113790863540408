import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import httpClient from 'http-client';
import Cookies from 'universal-cookie';
import { deleteProjectDocumentByID } from '../projects.api';

function beforeUpload(file: File) {
  const isLt10M = file.size / 1024 / 1024 > 10;

  if (isLt10M) {
    message.error('File must be smaller than 10MB!');

    return false;
  }

  return true;
}

export const DocumentsUploader: React.FC<{
  id: string;
  defaultFileList?: Array<any>;
  languageCode: string;
}> = ({ id, defaultFileList = [], languageCode }) => {
  const cookies = new Cookies();

  const token = cookies.get('token');
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const action = () =>
    Promise.resolve(
      `${httpClient.defaults.baseURL}/admin-offers/${id}/documents?languageCode=${languageCode}`
    );

  return (
    <div>
      <Upload
        action={action}
        headers={headers}
        onRemove={async (document: any) => {
          const key = document.key ? document.key : document.response.key;
          try {
            await deleteProjectDocumentByID(id, key);
            return true;
          } catch (e: any) {
            message.error('Error when deleting document', e.response.data);
            return false;
          }
        }}
        onChange={(res: any) => {}}
        name="document"
        defaultFileList={defaultFileList}
        beforeUpload={beforeUpload}
      >
        <Button icon={<UploadOutlined />}>Upload</Button>
      </Upload>
    </div>
  );
};
