import React from 'react';
import styles from './index.module.scss';

export interface CardProps {
  item: {
    text: string;
    marked?: boolean;
  };
  badge: string;
}

const Card: React.FC<CardProps> = ({ item: { text, marked = false }, badge }) => (
  <div className={styles.wrapper}>
    {text}
    {marked && <div className={styles.badge}>{badge}</div>}
  </div>
);

export default Card;
