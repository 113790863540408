import moment from 'moment';

interface IArgs {
  startDate?: string;
  endDate?: string;
  rangeFromNowInMonths?: number;
}

export const calcAndFormatRangeForDiagrams = ({
                                                startDate,
                                                endDate,
                                                rangeFromNowInMonths = 1
                                              }: IArgs) => {

  if (startDate && endDate) {
    const { start, end } = moment(endDate).isAfter(startDate)
      ?
      {
        start: startDate,
        end: endDate
      } :
      {
        start: endDate,
        end: startDate
      };

    return {
      endDate: moment(end)
        .format('YYYY-MM-DDTHH:mm:ss.SSSS'),
      startDate: moment(start)
        .format('YYYY-MM-DDTHH:mm:ss.SSSS')
    };
  }


  return {
    endDate: moment()
      .format('YYYY-MM-DDTHH:mm:ss.SSSS'),
    startDate: moment()
      .subtract(rangeFromNowInMonths, 'month')
      .format('YYYY-MM-DDTHH:mm:ss.SSSS')
  };
};