import React, { useEffect, useState } from 'react';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import cls from 'classnames';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { login, getUser, resendConfirmation } from 'actions';
import { useFormik } from 'formik';
import { useAppDispatch } from 'store';
import { LoginSchema } from 'utils/validation';
import { useSelector } from 'react-redux';
import { StoreState } from 'reducers';
import Cookies from 'universal-cookie';
import InputErrors from 'components/ui/input-errors';
import { getDomain } from 'utils/domain';

const cookies = new Cookies();
interface stateType {
  from: { pathname: string };
}

const Login: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [redirectToReferrer, toggleRedirectToReferrer] = useState(false);
  const initialValues = {
    email: '',
    password: '',
    googleCode: ''
  };
  const loggedIn: boolean = useSelector(
    (state: StoreState) => state.login.loggedIn
  );

  const user: any = useSelector((state: StoreState) => state.login.user);

  const location = useLocation<stateType>();
  const [isAuthenticatorEnabled, setIsAuthenticatorEnabled] = useState(false);
  const { from } = location.state || { from: { pathname: '/' } };

  const handleResendConfirmation = (email: string) => {
    dispatch(resendConfirmation(email));
  };

  useEffect(() => {
    if (loggedIn && user.role === 'ADMIN') {
      history.push('/admin');
      return;
    }

    if (loggedIn) {
      toggleRedirectToReferrer(true);
    }
  }, [loggedIn, history, user]);

  const formik = useFormik({
    validationSchema: LoginSchema,
    initialValues,
    onSubmit: ({ email, password, googleCode }) => {
      dispatch(login(email, password, googleCode))
        .then((r) => {
          console.log('LOGIN', r);
          console.log('LOGIN GET DOMAIN', getDomain());
          cookies.set('token', r.data.access_token, {
            maxAge: r.data.expires_in,
            path: '/',
            domain: getDomain()
          });
          console.log('AFTER SET COOKIE');
          console.log('COOKIE', cookies.get('token'));
          dispatch(getUser());
        })
        .catch((e) => {
          console.log('LOGIN CATCH', e);
          if (e.response.data.message.includes('password')) {
            formik.setFieldError('password', e.response.data.message);
          } else if (typeof e.response.data.message === 'string') {
            formik.setFieldError('email', e.response.data.message);
          }
        })
        .finally(() => formik.setSubmitting(false));
    }
  });

  if (redirectToReferrer === true) {
    return <Redirect to={from} />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.form}>
          <div className={styles.title}>{t('login:login')}</div>
          {((formik.touched.email && formik.errors.email) ||
            (formik.touched.password && formik.errors.password)) && (
            <div className={styles.errorsMobile}>
              {formik.touched.email && formik.errors.email && (
                <div className={styles.errorMobile}>
                  <div className={styles.circle} />
                  <div>
                    <div>{formik.errors.email}</div>
                    {formik.errors.email.includes('confirmed') && (
                      <div
                        className={styles.resendLink}
                        onClick={() =>
                          handleResendConfirmation(formik.values.email)
                        }
                      >
                        {t('login:resendConfirmation')}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {formik.touched.password && formik.errors.password && (
                <div className={styles.errorMobile}>
                  <div className={styles.circle} />
                  <div>{formik.errors.password}</div>
                </div>
              )}
            </div>
          )}
          <div className={styles.inputWrapper}>
            <Input
              autoFocus
              className={cls(styles.input, {
                [styles.inputError]: formik.touched.email && formik.errors.email
              })}
              id="email"
              name="email"
              value={formik.values.email}
              placeholder={t('common:email')}
              onBlur={formik.handleBlur}
              handleChange={formik.handleChange}
            />
            {formik.touched.email && formik.errors.email && (
              <div className={styles.error}>
                <div className={styles.circle} />
                <div>
                  <div>{formik.errors.email}</div>
                  {formik.errors.email.includes('confirmed') && (
                    <div
                      className={styles.resendLink}
                      onClick={() =>
                        handleResendConfirmation(formik.values.email)
                      }
                    >
                      {t('login:resendConfirmation')}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className={styles.inputWrapper}>
            <Input
              className={cls(styles.input, {
                [styles.inputError]:
                  formik.touched.password && formik.errors.password
              })}
              id="password"
              name="password"
              type="password"
              value={formik.values.password}
              placeholder={t('common:password')}
              onBlur={formik.handleBlur}
              handleChange={formik.handleChange}
            />
            {formik.touched.password && formik.errors.password && (
              <div className={styles.error}>
                <div className={styles.circle} />
                <div>{formik.errors.password}</div>
              </div>
            )}
          </div>
          {isAuthenticatorEnabled && (
            <div className={styles.inputs}>
              <div className={styles.inputWrapper}>
                <Input
                  name="googleCode"
                  id="googleCode"
                  placeholder={t(
                    'authenticator:input.placeholder.googleConfirmationCodeField'
                  )}
                  value={formik.values.googleCode}
                  handleChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                />
                {formik.touched.googleCode && formik.errors.googleCode && (
                  <InputErrors message={formik.errors.googleCode} />
                )}
              </div>
            </div>
          )}
          {!isAuthenticatorEnabled && (
            <div className={styles.authenticatorButton}>
              <Button
                onClick={() => setIsAuthenticatorEnabled(true)}
                text={t('authenticator:button.haveGoogleAuthenticator')}
                type="secondary"
                inverted
                size="sm"
                className={styles.btn}
              />
            </div>
          )}
          <Link to="/recovery" className={cls(styles.link, styles.row)}>
            {t('login:forgotPassword')}
          </Link>
          <Button
            text={t('login:login')}
            type="secondary"
            className={styles.button}
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting}
            onClick={formik.handleSubmit}
          />
        </div>
        <div className={styles.action}>
          <div>{t('login:notRegistered')}</div>
          <Link to="/registration" className={styles.link}>
            {t('login:createAccount')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
