import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface Props {
  id?: string;
  value: any;
  onChange: (attributes: any) => void;
}

export const HTMLEditor: React.FC<Props> = ({ value, onChange, id }) => {
  return <ReactQuill value={value} onChange={onChange} id={id} />;
};
