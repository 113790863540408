import httpClient from 'http-client';

export enum SearchDateType {
  Created = 'CREATED',
  Updated = 'UPDATED'
}

export enum ProjectStatus {
  ComingSoon = 'COMING_SOON',
  Ongoing = 'ONGOING',
  Past = 'PAST'
}

export enum LanguageCode {
  UK = 'uk',
  EN = 'en'
}

type GetAllProjectsConfig = {
  page?: number;
  field?: string;
  search?: string;
  startDate?: string;
  endDate?: string;
  limit?: number;
  searchDateType?: SearchDateType;
  status?: ProjectStatus;
  language?: LanguageCode;
};

export const getAllProjects = (config: GetAllProjectsConfig) => {
  return httpClient.get('/admin-offers', {
    params: {
      ...config,
      languageCode: config.language
    }
  });
};

export const getProjectById = (id: string) => {
  return httpClient.get(`/admin-offers/${id}`);
};

export const updateProjectById = (id: string, body: any) => {
  const {
    projectImage,
    goal,
    status,
    minimumInvestment,
    expectedReturns,
    website,
    startDate,
    endDate,
    currencyCode,
    categoryIds,
    isVisibleWebsite
  } = body;

  return httpClient.put(`/admin-offers/${id}`, {
    projectImage,
    goal,
    status,
    minimumInvestment,
    expectedReturns,
    website,
    currencyCode,
    startDate,
    endDate,
    categoryIds,
    isVisibleWebsite
  });
};

export const createProject = (languageCode: LanguageCode, body: any) => {
  return httpClient.post(`/admin-offers?languageCode=${languageCode}`, {
    ...body
  });
};

export const getAllCategories = () => {
  return httpClient.get('/categories');
};

export const getProjectTranslationsById = (id: string) => {
  return httpClient.get(`/admin-offers/${id}/translations`);
};

export const updateProjectTranslationById = (
  id: string,
  languageCode: string,
  body: any
) => {
  const {
    company,
    location,
    tagline,
    shortDescription,
    description,
    isDefault,
    terms,
    projectName,
    isVisibleCompany
  } = body;
  return httpClient.put(
    `/admin-offers/translation/${id}?languageCode=${languageCode}`,
    {
      company,
      location,
      tagline,
      shortDescription,
      description,
      isDefault,
      terms,
      projectName,
      isVisibleCompany
    }
  );
};

export const uploadProjectImage = (id: string, file: File) => {
  const formData = new FormData();
  formData.append('project-image', file);
  return httpClient.post(`/admin-offers/${id}/project-image`, formData);
};

export const removeProjectImage = (id: string) => {
  return httpClient.delete(`/admin-offers/${id}/project-image`);
};

export const removeProjectDescriptionImage = (id: string, key: string) => {
  return httpClient.delete(`/admin-offers/${id}/description-images`, {
    params: {
      key
    }
  });
};

export const getProjectDocumentsById = (id: string, languageCode: string) => {
  return httpClient.get(`/admin-offers/${id}/documents`, {
    params: {
      languageCode
    }
  });
};

export const deleteProjectDocumentByID = (id: string, key: string) => {
  return httpClient.delete(`/admin-offers/${id}/documents`, {
    params: {
      key,
      languageCode: 'en'
    }
  });
};
