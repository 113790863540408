import axios from 'axios';
import { Dispatch } from 'redux';
import Cookies from 'universal-cookie';

const { REACT_APP_API } = process.env;
//const REACT_APP_API = "http://localhost:3000/api";
const cookies = new Cookies();

export const createApplicant =
  (payload: {
    firstName?: string;
    lastName?: string;
    street?: string;
    postcode?: string;
    town?: string;
    country?: string;
    companyStreet?: string;
    companyPostcode?: string;
    companyTown?: string;
    companyCountry?: string;
    companyName?: string;
    jobTitle?: string;
    website?: string;
    companyPhoneNumber?: string;
    companyEmail?: string;
  }) =>
  (dispatch: Dispatch) => {
    const url = REACT_APP_API + '/kyc/applicants';
    const options = {
      headers: { Authorization: `Bearer ${cookies.get('token')}` },
    };

    return axios.post(
      url,
      {
        applicant: {
          lastName: payload.lastName,
          firstName: payload.firstName,
          address: {
            street: payload.street,
            postcode: payload.postcode,
            town: payload.town,
            country: payload.country,
          },
        },
        companyStreet: payload.companyStreet || null,
        companyPostcode: payload.companyPostcode || null,
        companyTown: payload.companyTown || null,
        companyCountry: payload.companyCountry || null,
        companyName: payload.companyName || null,
        jobTitle: payload.jobTitle || null,
        website: payload.website || null,
        companyPhoneNumber:
          payload.companyPhoneNumber && payload.companyPhoneNumber[0] !== '+'
            ? '+' + payload.companyPhoneNumber
            : payload.companyPhoneNumber
            ? payload.companyPhoneNumber
            : null,
        companyEmail: payload.companyEmail || null,
      },
      options,
    );
  };

export const createCheck = (applicantId: string) => (dispatch: Dispatch) => {
  const url = REACT_APP_API + '/kyc/checks';
  const options = {
    headers: { Authorization: `Bearer ${cookies.get('token')}` },
  };

  return axios.post(
    url,
    {
      applicantId,
      reportNames: ['document', 'facial_similarity_photo'],
    },
    options,
  );
};

export const getCheck = (checkId: string) => (dispatch: Dispatch) => {
  const url = REACT_APP_API + '/kyc/checks/' + checkId;
  const options = {
    headers: { Authorization: `Bearer ${cookies.get('token')}` },
  };

  return axios.get(url, options);
};
