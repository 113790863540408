import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { resetPassword, verifyToken } from 'actions';
import styles from './index.module.scss';
import Form from './Form';
import Confirmation from './Confirmation';
import { toast } from 'react-toastify';

export interface ResetProps {}

const Reset: React.FC<ResetProps> = () => {
  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const { token }: { token: string } = useParams();
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(verifyToken(token))
      .then(() => setLoading(false))
      .catch(e => {
        if (typeof e.response.data.message === 'string') {
          toast.error(e.response.data.message);
        }
        history.push('/');
      });
  }, [dispatch, history, token]);

  const handleSubmit = (password: string) => {
    dispatch(resetPassword(token, password))
      .then(() => setStep(1))
      .catch(e => {
        if (typeof e.response.data.message === 'string') {
          toast.error(e.response.data.message);
        }
      });
  };

  return loading ? null : (
    <div className={styles.wrapper}>{step === 0 ? <Form handleSubmit={handleSubmit} /> : <Confirmation />}</div>
  );
};

export default Reset;
