import React from 'react';
import styles from './index.module.scss';
import { ReactComponent as PhotoIcon } from 'assets/icons/photo.svg';
import { ReactComponent as RemovePhotoIcon } from 'assets/icons/removePhoto.svg';
import Button from 'components/common/Button';
import { Checkbox, Select } from 'antd';
import cls from 'classnames';
import { EditSchema } from 'utils/validation';
import { useFormik } from 'formik';
import Input from 'components/common/Input';
import { countries, getUser, uploadAvatar, deleteAvatar, updateUser } from 'actions';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import { StoreState } from 'reducers';
import { useAppDispatch } from 'store';
import { toast } from 'react-toastify';

const { REACT_APP_API } = process.env;
//const REACT_APP_API = "http://localhost:3000/api";

const { Option } = Select;

export interface EditProps {
  user: any;
}

interface InitialValues {
  firstName: string;
  lastName: string;
  street: string;
  email: string;
  phoneNumber: string;
  postcode: string;
  town: string;
  country: string | undefined;
  companyStreet: string;
  companyPostcode: string;
  companyTown: string;
  companyCountry: string | undefined;
  companyName: string;
  jobTitle: string;
  website: string;
  companyPhoneNumber: string;
  companyEmail: string;
}

const Edit: React.FC<EditProps> = ({ user }) => {
  const inputFile = React.useRef<HTMLInputElement>(null);
  const initialValues: InitialValues = {
    firstName: user.firstName || undefined,
    lastName: user.lastName || undefined,
    email: user.email || undefined,
    phoneNumber: user.phoneNumber || undefined,
    street: user.street || undefined,
    postcode: user.postcode || undefined,
    town: user.town || undefined,
    country: user.country || undefined,
    companyName: user.companyName || undefined,
    companyPhoneNumber: user.companyPhoneNumber || undefined,
    companyEmail: user.companyEmail || undefined,
    companyStreet: user.companyStreet || undefined,
    companyPostcode: user.companyPostcode || undefined,
    companyTown: user.companyTown || undefined,
    companyCountry: user.companyCountry || undefined,
    jobTitle: user.jobTitle || undefined,
    website: user.website || undefined,
  };
  const formik = useFormik({
    validationSchema: EditSchema,
    initialValues: initialValues,
    onSubmit: values => {
      dispatch(
        updateUser({
          ...JSON.parse(
            JSON.stringify(values, function (key, value) {
              return value === '' ? null : value;
            }),
          ),
          email: undefined,
        }),
      );
    },
  });
  const country: string = useSelector((state: StoreState) => state.language.country.alpha2);
  const dispatch = useAppDispatch();
  const disabled = user.kycStatus === 'pending' || user.kycStatus === 'clear';

  const openUpload = () => {
    inputFile.current?.click();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const files = e.target.files;

    if (!!files?.length) {
      const img = new Image();
      img.src = window.URL.createObjectURL(files[0]);
      img.onload = function () {
        const width = img.naturalWidth,
          height = img.naturalHeight;

        window.URL.revokeObjectURL(img.src);

        if (files[0].size > 10000000) {
          toast.error(t('notifications:invalidFileSize'));
        }

        if (width > 600 || height > 600) {
          toast.error(t('notifications:invalidAvatarDimensions'));
        }

        if (files[0].size < 10000000 && width <= 600 && height <= 600) {
          dispatch(uploadAvatar(files[0]))
            .then(() => {
              dispatch(getUser());
              toast.success(t('notifications:avatarUploaded'));
            })
            .catch(() => toast.error(t('notifications:error')))
            .finally(() => {
              if (inputFile.current) inputFile.current.value = '';
            });
        }
      };
    }
  };

  const handleDeleteAvatar = () => {
    dispatch(deleteAvatar())
      .then(() => {
        toast.success(t('notifications:avatarDeleted'));
        dispatch(getUser());
      })
      .catch(() => toast.error(t('notifications:error')));
  };

  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <div className={styles.block}>
          <div className={styles.title}>{t('profile:personalInfo')}</div>
          <div className={styles.row}>
            <div className={styles.inputWrapper}>
              <Input
                id='firstName'
                disabled={disabled}
                name='firstName'
                value={formik.values.firstName}
                className={cls(styles.input, {
                  [styles.inputError]: formik.touched.firstName && formik.errors.firstName,
                })}
                placeholder={t('common:firstName')}
                onBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Input
                id='lastName'
                name='lastName'
                disabled={disabled}
                value={formik.values.lastName}
                className={cls(styles.input, {
                  [styles.inputError]: formik.touched.lastName && formik.errors.lastName,
                })}
                placeholder={t('common:lastName')}
                onBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.inputWrapper}>
              <Input
                id='email'
                disabled
                name='email'
                value={formik.values.email}
                className={cls(styles.input, {
                  [styles.inputError]: formik.touched.email && formik.errors.email,
                })}
                placeholder={t('common:email')}
                onBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
            </div>
            <div className={styles.inputWrapper} style={{ marginTop: 6 }}>
              <PhoneInput
                inputProps={{
                  name: 'phoneNumber',
                  id: 'phoneNumber',
                  onBlur: formik.handleBlur,
                }}
                disabled={disabled}
                country={country}
                inputClass={cls(styles.phoneInput, {
                  [styles.inputError]: formik.touched.phoneNumber && formik.errors.phoneNumber,
                  [styles.disabled]: disabled,
                })}
                buttonClass={styles.dropdown}
                dropdownStyle={{ width: 205 }}
                countryCodeEditable={true}
                placeholder=''
                value={formik.values.phoneNumber}
                onChange={v => {
                  formik.setFieldValue('phoneNumber', v);
                }}
              />
            </div>
          </div>
          <div className={styles.inputWrapper}>
            <Input
              id='street'
              name='street'
              disabled={disabled}
              value={formik.values.street}
              className={cls(styles.input, {
                [styles.inputError]: formik.touched.street && formik.errors.street,
              })}
              placeholder={t('common:street')}
              onBlur={formik.handleBlur}
              handleChange={formik.handleChange}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.inputWrapper}>
              <Input
                id='town'
                name='town'
                disabled={disabled}
                value={formik.values.town}
                className={cls(styles.input, {
                  [styles.inputError]: formik.touched.town && formik.errors.town,
                })}
                placeholder={t('common:city')}
                onBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Input
                id='postcode'
                name='postcode'
                disabled={disabled}
                value={formik.values.postcode}
                className={cls(styles.input, {
                  [styles.inputError]: formik.touched.postcode && formik.errors.postcode,
                })}
                placeholder={t('common:postcode')}
                onBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Select
                showSearch
                className={cls(styles.select, {
                  [styles.inputError]: formik.touched.country && formik.errors.country,
                  [styles.disabledSelect]: disabled,
                })}
                disabled={disabled}
                placeholder={t('common:country')}
                optionFilterProp='children'
                onChange={v => formik.setFieldValue('country', v)}
                value={formik.values.country}
                filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {countries.map((country: { alpha3: string; name: string }) => (
                  <Option key={country.alpha3} value={country.alpha3}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className={styles.block}>
          <div className={styles.title}>{t('profile:companyInfo')}</div>
          <div className={styles.row}>
            <div className={styles.inputWrapper}>
              <Input
                id='companyName'
                name='companyName'
                value={formik.values.companyName}
                className={cls(styles.input, {
                  [styles.inputError]: formik.touched.companyName && formik.errors.companyName,
                })}
                placeholder={t('common:companyName')}
                onBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Input
                id='jobTitle'
                name='jobTitle'
                value={formik.values.jobTitle}
                className={cls(styles.input, {
                  [styles.inputError]: formik.touched.jobTitle && formik.errors.jobTitle,
                })}
                placeholder={t('common:jobTitle')}
                onBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.inputWrapper}>
              <Input
                id='companyEmail'
                name='companyEmail'
                value={formik.values.companyEmail}
                className={cls(styles.input, {
                  [styles.inputError]: formik.touched.companyEmail && formik.errors.companyEmail,
                })}
                placeholder={t('common:companyEmail')}
                onBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
            </div>
            <div className={styles.inputWrapper} style={{ marginTop: 6 }}>
              <PhoneInput
                inputProps={{
                  name: 'companyPhoneNumber',
                  id: 'companyPhoneNumber',
                  onBlur: formik.handleBlur,
                }}
                country={country}
                inputClass={cls(styles.phoneInput, {
                  [styles.inputError]: formik.touched.companyPhoneNumber && formik.errors.companyPhoneNumber,
                })}
                buttonClass={styles.dropdown}
                dropdownStyle={{ width: 205 }}
                countryCodeEditable={true}
                placeholder=''
                value={formik.values.companyPhoneNumber}
                onChange={v => {
                  formik.setFieldValue('companyPhoneNumber', v);
                }}
              />
            </div>
          </div>
          <div className={styles.inputWrapper}>
            <Input
              id='website'
              name='website'
              value={formik.values.website}
              className={cls(styles.input, {
                [styles.inputError]: formik.touched.website && formik.errors.website,
              })}
              placeholder={t('common:website')}
              onBlur={formik.handleBlur}
              handleChange={formik.handleChange}
            />
          </div>
          <div className={styles.inputWrapper}>
            <Input
              id='companyStreet'
              name='companyStreet'
              value={formik.values.companyStreet}
              className={cls(styles.input, {
                [styles.inputError]: formik.touched.companyStreet && formik.errors.companyStreet,
              })}
              placeholder={t('common:street')}
              onBlur={formik.handleBlur}
              handleChange={formik.handleChange}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.inputWrapper}>
              <Input
                id='companyTown'
                name='companyTown'
                value={formik.values.companyTown}
                className={cls(styles.input, {
                  [styles.inputError]: formik.touched.companyTown && formik.errors.companyTown,
                })}
                placeholder={t('common:city')}
                onBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Input
                id='companyPostcode'
                name='companyPostcode'
                value={formik.values.companyPostcode}
                className={cls(styles.input, {
                  [styles.inputError]: formik.touched.companyPostcode && formik.errors.companyPostcode,
                })}
                placeholder={t('common:postcode')}
                onBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Select
                showSearch
                className={cls(styles.select, {
                  [styles.inputError]: formik.touched.companyCountry && formik.errors.companyCountry,
                })}
                placeholder={t('common:country')}
                optionFilterProp='children'
                onChange={v => formik.setFieldValue('companyCountry', v)}
                value={formik.values.companyCountry}
                filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {countries.map((country: { alpha3: string; name: string }) => (
                  <Option key={country.alpha3} value={country.alpha3}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <Button text={t('profile:saveUpdates')} type='secondary' size='lg' onClick={() => formik.handleSubmit()} />
      </div>
      <div className={styles.sidebar}>
        <div>
          <div className={styles.avatar}>
            {user.avatar && <img src={REACT_APP_API + '/storage/file-stream/' + user.avatar} alt='' />}
          </div>
          <div className={styles.actions}>
            <div className={styles.action} onClick={openUpload}>
              <PhotoIcon />
              {user.avatar ? t('profile:changePhoto') : t('profile:uploadAvatar')}
            </div>
            {user.avatar && (
              <div className={styles.action} onClick={handleDeleteAvatar}>
                <RemovePhotoIcon />
                {t('profile:removePhoto')}
              </div>
            )}
          </div>
          <div className={styles.changePasswordButton}>
            <Button to={'/profile/edit/change-password'} text={t('profile:changePassword')} type={'secondary'} inverted={true}></Button>
          </div>
          <Checkbox
            className={styles.checkbox}
            checked={user.isSubscribed}
            onChange={() => dispatch(updateUser({ isSubscribed: !user.isSubscribed }))}
            name='isSubscribed'
          >
            {t('profile:emailSubscription')}
          </Checkbox>
        </div>
      </div>
      <input
        type='file'
        id='file'
        ref={inputFile}
        onChange={handleChange}
        accept='image/*'
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default Edit;
