import { Category } from 'actions';
import { combineReducers } from 'redux';
import { languageReducer } from './language';
import { offersReducer } from './offers';
import { loginReducer } from './login';
import { currencyReducer } from './currency';
import { ICurrenciesState } from '../actions/currency';

export interface StoreState {
  language: { language: string; country: { alpha2: string; alpha3: string } };
  offers: Category[];
  login: {
    loggedIn: boolean;
    user: any;
    loggingIn: boolean;
  };
  currency: ICurrenciesState
}

export const reducers = combineReducers<StoreState>({
  currency: currencyReducer,
  language: languageReducer,
  offers: offersReducer,
  login: loginReducer,
});
