import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import Email from './Email';
import Password from './Password';
import Confirmation from './Confirmation';
import { useTranslation } from 'react-i18next';
import { register, resendConfirmation, verifyEmail } from 'actions';
import { useFormik } from 'formik';
import { useAppDispatch } from 'store';
import { Steps } from 'antd';
import { RegistrationSchema } from 'utils/validation';
import { toast } from 'react-toastify';
import { sendMetrikGoogleEvents } from '../../utils/sendMetrikGoogleEvents';

const { Step } = Steps;

interface InitialValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  repeatPassword: string;
  terms: boolean;
  isSubscribed: boolean;
}

const Registration: React.FC = () => {
  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const initialValues: InitialValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
    repeatPassword: '',
    terms: false,
    isSubscribed: true,
  };

  const formik = useFormik({
    validationSchema: RegistrationSchema,
    initialValues,
    onSubmit: ({ firstName, lastName, email, password, isSubscribed, phoneNumber }) => {
      dispatch(register(firstName, lastName, email, password, isSubscribed, '+' + phoneNumber))
        .then(() => {
          sendMetrikGoogleEvents('registration', 'registration-completed')
          setStep(2);
        })
        .catch(e => {
          if (typeof e.response.data.message === 'string') {
            toast.error(e.response.data.message);
          }
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  const handleNext = () => {
    formik
      .setTouched({ terms: true, email: true, lastName: true, firstName: true, phoneNumber: true }, true)
      .then((errors: any) => {
        if (!errors.terms && !errors.email && !errors.lastName && !errors.firstName && !errors.phoneNumber) {
          setLoading(true);
          dispatch(verifyEmail(formik.values.email))
            .then(() => {
              setStep(prevStep => prevStep + 1);
            })
            .catch(e => {
              if (typeof e.response.data.message === 'string') {
                formik.setFieldError('email', e.response.data.message);
              }
            })
            .finally(() => setLoading(false));
        }
      });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.stepper}>
          <Steps size='small' current={step} labelPlacement='vertical'>
            <Step title={<span className={styles.step}>{t('login:details')}</span>} />
            <Step title={<span className={styles.step}>{t('common:password')}</span>} />
            <Step title={<span className={styles.step}>{t('login:verifyEmail')}</span>} />
          </Steps>
        </div>
        <div className={styles.form}>
          {step === 0 ? (
            <Email formik={formik} loading={loading} handleNext={handleNext} />
          ) : step === 1 ? (
            <Password formik={formik} />
          ) : (
            <Confirmation />
          )}
        </div>
        {step === 2 ? (
          <div className={styles.resend}>
            <div>{t('login:notReceivedEmail')}</div>
            <div className={styles.link} onClick={() => dispatch(resendConfirmation(formik.values.email))}>
              {t('login:resendConfirmation')}
            </div>
          </div>
        ) : (
          <div className={styles.action}>
            <div>{t('login:alreadyRegistered')}</div>
            <Link to='/login' className={styles.link}>
              {t('login:login')}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Registration;
