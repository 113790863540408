import React, { ChangeEvent, FC } from 'react';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../../../reducers';
import { changeUserCurrency } from '../../../../../actions/currency';

interface CardProps {
}

const Currency: FC<CardProps> = () => {
  const dispatch = useDispatch();
  const currencyList = useSelector((store: StoreState) => store.currency.allowedCurrenciesList)
  const user = useSelector((store: StoreState) => store.login.user);
  const { t } = useTranslation('profile');

  const icon = <InfoCircleOutlined className={styles.icon} />

  const onChangeCurrency = (e: ChangeEvent<HTMLSelectElement>) => {

    dispatch(changeUserCurrency(user.id, e.target.value))

  }

  return (
    <div className={styles.card}>
      <div className={styles.card__header}>
        {icon}
        <span className={styles.title}>{t('selectCurrency')}</span>
      </div>

      <select
        value={user.currencyCode}
        className={styles.select}
        onChange={onChangeCurrency}
      >
        {currencyList.map((item) => (
          <option key={Math.random()} value={item.code}>{item.code}</option>
        ))}
      </select>
    </div>
  );
};

export default Currency;