import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import httpClient from 'http-client';
import Cookies from 'universal-cookie';
import { removeProjectDescriptionImage } from '../projects.api';

function beforeUpload(file: File) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }

  const isLt10M = file.size / 1024 / 1024 < 10;

  if (!isLt10M) {
    message.error('Image must smaller than 10MB!');
  }

  return isJpgOrPng && isLt10M;
}

export const ImagesUploaderMultiple: React.FC<{
  id: string;
  defaultFileList?: Array<any>;
}> = ({ id, defaultFileList = [] }) => {
  const cookies = new Cookies();

  const token = cookies.get('token');
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const action = () =>
    Promise.resolve(
      `${httpClient.defaults.baseURL}/admin-offers/${id}/description-images`
    );

  return (
    <div>
      <Upload
        action={action}
        headers={headers}
        listType="picture-card"
        onRemove={(info: any) => {
          const key = info?.response?.key ? info.response.key : info.key;
          console.log({ key, info });
          removeProjectDescriptionImage(id, key);
        }}
        onChange={(res: any) => {}}
        name="description-image"
        defaultFileList={defaultFileList}
        beforeUpload={beforeUpload}
        onPreview={(file) => {
          console.log(file);
          const key = file?.response?.key
            ? file.response.key
            : (file as any).key;
          window.open(
            `${httpClient.defaults.baseURL}/storage/file-stream/${key}`
          );
        }}
      >
        <Button icon={<UploadOutlined />}>Upload</Button>
      </Upload>
    </div>
  );
};
