import React, { useState } from 'react';
import cls from 'classnames';
import styles from './index.module.scss';
import ReactPlayer from 'react-player';
import { Document, Page } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import { Offer } from 'actions';
import Status from '../Status';
import { DownloadOutlined } from '@ant-design/icons';
import { getImageLink } from 'pages/Admin/utils/get-image-link';

interface DescriptionProps {
  project: Offer;
}

const Description: React.FC<DescriptionProps> = ({ project }) => {
  const [tab, setTab] = useState<string>('overview');
  const { t } = useTranslation();

  const renderContent = () => {
    switch (tab) {
      case 'overview':
        return (
          <>
            <div
              dangerouslySetInnerHTML={{ __html: project.description }}
            ></div>
            {/* {!!project.description?.length &&
              // project.description.map((block: { type: 'Header' | 'image' | 'text'; value: string }, i: number) =>
              //   block.type === 'text' ? (
              //     <div key={i} className={styles.block}>
              //       {block.value.replace(/\\n/g, '\n')}
              //     </div>
              //   ) : block.type === 'Header' ? (
              //     <div key={i} className={styles.Header}>
              //       {block.value}
              //     </div>
              //   ) : block.type === 'image' ? (
              //     <img key={i} className={styles.img} src={block.value} alt='' />
              //   ) : null,
              // )
              } */}
            {!!project.tagline && (
              <div className={styles.tagline}>{project.tagline}</div>
            )}
            {!!project.video && (
              <div className={styles.playerWrapper}>
                <ReactPlayer
                  className={styles.player}
                  url={project.video}
                  width="100%"
                  height="100%"
                  controls={true}
                />
              </div>
            )}
          </>
        );
      case 'documents':
        return (
          <>
            {!!project.documents?.length && (
              <div className={styles.documents}>
                {project.documents.map(
                  (document: { url: string; name: string }, i: number) => (
                    <div className={styles.document} key={i}>
                      <div className={styles.name}>{document.name}</div>
                      <Document
                        file={getImageLink((document as any).url)}
                        error=""
                        loading=""
                      >
                        <Page pageNumber={1} />
                      </Document>
                      <DownloadOutlined
                        className={styles.download}
                        onClick={() =>
                          window.open(getImageLink((document as any).url))
                        }
                      />
                    </div>
                  )
                )}
              </div>
            )}
          </>
        );
    }
  };

  return (
    <div>
      <div className={styles.links}>
        {!!project.description?.length && (
          <div
            onClick={() => setTab('overview')}
            className={cls(styles.link, {
              [styles.active]: tab === 'overview'
            })}
          >
            {t('common:overview')}
          </div>
        )}
        {!!project.documents?.length && (
          <div
            onClick={() => setTab('documents')}
            className={cls(styles.link, {
              [styles.active]: tab === 'documents'
            })}
          >
            {t('common:documents')}
          </div>
        )}
      </div>

      <div className={styles.wrapper}>
        <div className={styles.content}>
          {project.shortDescription && (
            <div className={styles.shortDescription}>
              <div className={styles.inner}>{project.shortDescription}</div>
            </div>
          )}

          <div className={styles.title}>{t(`common:${tab}`)}</div>

          {renderContent()}
        </div>
        <Status project={project} className={styles.statusDesktop} />
      </div>
    </div>
  );
};

export default Description;
