import { ActionTypes, countries, languages } from './types';
import i18n from 'i18next';
import Cookies from 'universal-cookie';
import { Dispatch } from 'redux';
import axios from 'axios';
import { getDomain } from '../utils/domain';

const cookies = new Cookies();

export interface ChangeLanguageAction {
  type: ActionTypes.changeLanguage;
  payload: string;
}

export interface ChangeCountryAction {
  type: ActionTypes.changeCountry;
  payload: { alpha2: string; alpha3: string };
}

export const changeLanguage = (language: string = 'en'): ChangeLanguageAction => {
  i18n.changeLanguage(language);
  cookies.set('currentLang', language, { path: '/', domain: getDomain() });

  return {
    type: ActionTypes.changeLanguage,
    payload: language,
  };
};

export const changeCountry = (country: { alpha2: string; alpha3: string }): ChangeCountryAction => {
  return {
    type: ActionTypes.changeCountry,
    payload: country,
  };
};

export const detectLanguage = () => (dispatch: Dispatch) => {
  const languageCookie = cookies.get('currentLang');

  if (languages.find(lng => lng.value === languageCookie)) {
    dispatch(changeLanguage(languageCookie));
  } else {
    dispatch(changeLanguage('en'));
  }
};

export const detectCountry = () => (dispatch: Dispatch) => {
  const url: string = 'https://www.cloudflare.com/cdn-cgi/trace';

  axios
    .get(url)
    .then(r => {
      const loc = r.data.match(/^(?:loc)=(.*)$/gm);
      const country = loc && loc[0].split('=')[1].toLowerCase();

      if (country) {
        dispatch(
          changeCountry({ alpha2: country, alpha3: countries.find(item => item.alpha2 === country)?.alpha3 || '' }),
        );
      }
    })
    .catch(() => {});
};
