import styles from './index.module.scss';

interface Props {
  onClick: () => void;
}

const BackButton: React.FC<Props> = ({ onClick }) => {
  return (
    <div className={styles.back} onClick={onClick}>
      <span className={styles.back__icon}>
        <svg
          width="5"
          height="7"
          viewBox="0 0 5 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.940519 3.71609C0.945351 3.7798 0.973541 3.84064 1.02106 3.88861L3.92057 6.75199C4.03977 6.87011 4.24435 6.88013 4.37724 6.7749C4.51014 6.66896 4.52141 6.48713 4.40302 6.36902C4.40221 6.3683 4.40221 6.3683 4.4014 6.36758L1.696 3.69676L4.40141 1.02522C4.52141 0.907824 4.51175 0.725996 4.37885 0.619335C4.24676 0.512673 4.04219 0.521265 3.92218 0.63938C3.92138 0.640096 3.92138 0.640098 3.92057 0.640813L1.02106 3.5042C0.96307 3.56218 0.934075 3.63878 0.940519 3.71609Z"
            fill="#276DF1"
          />
        </svg>
      </span>
      <span className={styles.back__text}>Back</span>
    </div>
  );
};

export default BackButton;
