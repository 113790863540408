import axios from 'axios';
import { Dispatch } from 'redux';
import { Offer } from 'actions';
import { ActionTypes, Category } from './types';
import { StoreState } from 'reducers';
import httpClient from '../http-client';

const { REACT_APP_API } = process.env;
//const REACT_APP_API = "http://localhost:3000/api";

export interface SetCategoriesAction {
  type: ActionTypes.setCategories;
  payload: Category[];
}

export const getOffers =
  (params?: { status?: string | null; categoryIds?: string[] }) =>
  async (dispatch: Dispatch, getState: () => StoreState) => {
    const url = '/offers';
    const {
      language: { language },
    } = getState();

    const response = await httpClient.get<{ results: Offer[]; total: number }>(url, {
      params: {
        ...params,
        languageCode: language,
      },
    });

    return response.data;
  };

export const getOffer = (id: string) => async (dispatch: Dispatch, getState: () => StoreState) => {
  const url = '/offers/' + id;
  const {
    language: { language },
  } = getState();

  const response = await httpClient.get<Offer>(url, {
    params: {
      languageCode: language,
    },
  });

  return response.data;
};

export const getCategories = () => async (dispatch: Dispatch, getState: () => StoreState) => {
  const url = REACT_APP_API + '/categories';
  const {
    language: { language },
  } = getState();

  const response = await axios.get<{
    results: Category[];
    total: number;
  }>(url, {
    params: {
      languageCode: language,
    },
  });

  dispatch(setCategories(response.data.results));
};

export const setCategories = (categories: Category[]): SetCategoriesAction => {
  return {
    type: ActionTypes.setCategories,
    payload: categories,
  };
};
