import * as Yup from 'yup';
import messages from './messages';
import {
  email,
  firstName,
  lastName,
  password,
  phone,
  terms,
  string,
  repeatPassword
} from './input-types';

export const LoginSchema = Yup.object().shape({
  email,
  password: Yup.string().required(messages.required)
});

export const RecoverySchema = Yup.object().shape({
  email
});

export const RegistrationSchema = Yup.object().shape({
  firstName,
  lastName,
  email,
  password,
  repeatPassword,
  phoneNumber: phone,
  terms
});

export const ResetPasswordSchema = Yup.object().shape({
  password,
  repeatPassword
});

export const EditSchema = Yup.object().shape({
  firstName,
  lastName,
  email,
  phoneNumber: phone,
  street: string.required,
  postcode: string.required,
  town: string.required,
  country: string.required,
  companyStreet: string.default,
  companyPostcode: string.default,
  companyTown: string.default,
  companyCountry: string.nullable,
  companyName: string.default,
  jobTitle: string.default,
  website: string.default,
  companyPhoneNumber: phone,
  companyEmail: email
});

export const VerifyGoogleAuthenticatorEmailCodeSchema = Yup.object().shape({
  emailCode: Yup.string()
    .required(messages.required)
    .min(6, 'Must be 6 digits')
    .max(6, 'Must be 6 digits')
});

export const VerifyGoogleAuthenticatorCodeSchema = Yup.object().shape({
  googleCode: Yup.string()
    .required(messages.required)
    .min(6, 'Must be 6 digits')
    .max(6, 'Must be 6 digits')
});

export const VerifyGoogleAuthenticationSchema = Yup.object().shape({
  googleCode: Yup.string()
    .required(messages.required)
    .min(6, 'Must be 6 digits')
    .max(6, 'Must be 6 digits'),
  emailCode: Yup.string()
    .required(messages.required)
    .min(6, 'Must be 6 digits')
    .max(6, 'Must be 6 digits')
});
