import { ActionTypes, Action } from 'actions';
import { ICurrenciesState } from '../actions/currency';

const initialState = {
  allowedCurrenciesList: [],
  exchangeCurrency: []
}

export const currencyReducer = (state: ICurrenciesState = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.setAllowedCurrenciesList:
      return {
        ...state,
        allowedCurrenciesList: action.allowedCurrenciesList
      };
    case ActionTypes.setExchangeCurrency:
      return {
        ...state,
        exchangeCurrency: {...state.exchangeCurrency, ...action.exchangeCurrency}
      };
    default:
      return state;
  }
};
