import React from 'react';
import Card from 'components/Card';
import styles from './index.module.scss';
import { useTranslation, Trans } from 'react-i18next';
import Button from 'components/common/Button';

interface BenefitsProps {
  handleScroll: (id: string) => void;
}

const Benefits: React.FC<BenefitsProps> = ({ handleScroll }) => {
  const { t } = useTranslation();

  const benefits: {
    text: string;
    marked?: boolean;
  }[] = [
    {
      text: t('landing:about.card1'),
      marked: true,
    },
    {
      text: t('landing:about.card2'),
    },
    {
      text: t('landing:about.card3'),
    },
    {
      text: t('landing:about.card4'),
    },
    {
      text: t('landing:about.card5'),
    },
    {
      text: t('landing:about.card6'),
    },
  ];

  return (
    <div id='about-project' className={styles.wrapper}>
      <div className={styles.title}>{t('landing:about.title')}</div>
      <div className={styles.cards}>
        {benefits.map(benefit => (
          <Card key={benefit.text} item={benefit} badge={t('common:new')} />
        ))}
      </div>
      <div className={styles.large}>
        <div className={styles.background}>Easily</div>
        <div className={styles.info}>
          <div className={styles.description}>
            <Trans i18nKey='landing:about.description'>
              <div className={styles.text} />
              <div className={styles.text} />
              <div className={styles.text} />
            </Trans>
          </div>
          <div className={styles.buttons}>
            <div className={styles.subtitle}>{t('landing:about.title2')}</div>
            <Button
              className={styles.button}
              type='secondary'
              size='lg'
              text={t('common:browseProjects')}
              onClick={() => handleScroll('projects')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
