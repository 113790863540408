import { ActionTypes, Action } from 'actions';

export const loginReducer = (
  state: {
    loggedIn: boolean;
    loggingIn: boolean;
    user: any;
  } = { loggedIn: false, user: null, loggingIn: true },
  action: Action
) => {
  switch (action.type) {
    case ActionTypes.loginSuccess:
      return {
        ...state,
        loggedIn: true,
        user: action.user,
        loggingIn: false
      };
    case ActionTypes.userCategory:
      return {
        ...state,
        user: {
          ...state.user,
          activeTill: action.data.activeTill,
          categoryName: action.data.categoryName,
        },
      };
    case ActionTypes.logoutSuccess:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        user: null
      };
    case ActionTypes.googleAuthenticatorEnabled:
      return {
        ...state,
        user: {
          ...state.user,
          isTfaEnabled: true
        }
      };
    case ActionTypes.googleAuthenticatorDisabled:
      return {
        ...state,
        user: {
          ...state.user,
          isTfaEnabled: false
        }
      };
    case ActionTypes.userCurrencyCode:
      return {
        ...state,
        user: {
          ...state.user,
          currencyCode: action.currencyCode
        }
      };
    default:
      return state;
  }
};
