import httpClient from 'http-client';
import {
  GADisableRequestDto,
  GARegisterResponseDto,
  GAVerifyRequestDto,
  LoginRequestDto
} from 'types/dto';

const login = async (payload: LoginRequestDto) => {
  return httpClient.post('/auth/login', payload);
};

const registerGoogleAuthenticator = async (email: string) => {
  return httpClient.post<GARegisterResponseDto>('/auth/tfa/register', {
    email
  });
};

const verifyGoogleAuthenticator = async (payload: GAVerifyRequestDto) => {
  return httpClient.put('/auth/tfa/verify', payload);
};

const resendGoogleAuthenticatorCode = async () => {
  return httpClient.put('/auth/tfa/new-smartlands-code');
};

const disableGoogleAuthenticator = async (payload: GADisableRequestDto) => {
  return httpClient.delete('/auth/tfa/disable', { data: payload });
};

export const authAPI = {
  login,
  registerGoogleAuthenticator,
  verifyGoogleAuthenticator,
  resendGoogleAuthenticatorCode,
  disableGoogleAuthenticator
};
