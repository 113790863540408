import { FC } from 'react';
import styles from './index.module.scss';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Button from 'components/common/Button';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';

interface CardProps {
  type: 'on' | 'off';
}

const TwoFA: FC<CardProps> = ({ type }) => {
  const { t } = useTranslation('authenticator');

  const iconClassNames = cls({
    [styles.icon]: true,
    [styles.error]: type === 'on'
  });

  const icon =
    type === 'on' ? (
      <CloseCircleOutlined className={iconClassNames} />
    ) : (
      <CheckCircleOutlined className={iconClassNames} />
    );

  const buttonType = type === 'on' ? 'error' : 'primary';
  const buttonText = type === 'on' ? t('button.disable') : t('button.enable');

  return (
    <div className={styles.card}>
      <div className={styles.card__header}>
        {icon}
        <span className={styles.title}>{t('text.twoFactorAuth')}</span>
      </div>
      <Button
        to='/2fa'
        text={buttonText}
        inverted
        className={styles.card__button}
        type={buttonType}
      />
    </div>
  );
};

export default TwoFA;