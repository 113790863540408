import { FileDoneOutlined } from '@ant-design/icons';
import { User } from 'actions';
import { Divider, Tabs } from 'antd';
import {
  banUserById,
  changeUserStatusById,
  getUserById,
  getUserDocumentsById,
  KycStatus
} from 'pages/Admin/users.api';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserActions } from './UserActions';
import { UserDocuments } from './UserDocuments';
import { UserHeader } from './UserHeader';

import styles from 'pages/Admin/index.module.scss';
// import UserLimits from './UserLimits';

const { TabPane } = Tabs;

export type UserInfo = User & {
  kycCompletedAt: string;
  categorizationCompletedAt: string;
  isBanned: boolean;
};

export interface UserDocument {
  id: string;
  name: string;
  key: string;
  description: string;
  type: string;
  assignedEntityId: string;
}

export const UserPage = () => {
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<any>(null);
  const [userDocuments, setUserDocuments] = useState<any>(null);

  useEffect(() => {
    const getInitialData = async () => {
      const user = (await getUserById(id)).data;
      const documents = (await getUserDocumentsById(id)).data;

      setUser(user);
      setUserDocuments(documents);
    };

    getInitialData();
  }, [id]);

  const handleBan = async (isBanned: boolean) => {
    const { data } = await banUserById(id, isBanned);
    setUser(data);
  };

  const handleChangeStatus = async (status: KycStatus) => {
    const { data } = await changeUserStatusById(id, status);
    setUser(data);
  };

  return (
    <div className={styles.user}>
      {user && <UserHeader user={user} />}

      {user && (
        <UserActions
          user={user}
          onBan={handleBan}
          onChangeStatus={handleChangeStatus}
        />
      )}
      <Divider className="admin-divider" />
      {/*<UserLimits />*/}
      <Divider className="admin-divider" />
      <Tabs defaultActiveKey="kyc" centered>
        <TabPane
          tab={
            <span>
              <FileDoneOutlined />
              Documents
            </span>
          }
          key="documents"
        >
          {userDocuments && <UserDocuments documents={userDocuments} />}
        </TabPane>
      </Tabs>
    </div>
  );
};
