import React from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { Helmet } from 'react-helmet';

export interface LayoutProps {
  children: React.ReactNode;
  title: string;
  isProfile?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  title,
  isProfile = false
}) => (
  <>
    <Helmet>
      <title>{title} - Smartlands</title>
    </Helmet>
    <Header />
    {children}
    <Footer isProfile={isProfile} />
  </>
);

export default Layout;
