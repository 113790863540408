import React from 'react';
import cls from 'classnames';
import { ReactComponent as Logo } from 'assets/images/logoWhite.svg';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

interface AboutProps {}

const About: React.FC<AboutProps> = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper} id='how-it-works'>
      <div className={styles.title}>{t('landing:howItWorks.title')}</div>
      <div className={styles.content}>
        <div className={styles.cards}>
          <div className={styles.card}>
            <div className={styles.number}>1</div>
            {t('landing:howItWorks.step1')}
          </div>
          <div className={styles.card}>
            <div className={styles.number}>2</div>
            {t('landing:howItWorks.step2')}
          </div>
          <div className={styles.card}>
            <div className={styles.number}>3</div>
            {t('landing:howItWorks.step3')}
          </div>
        </div>
        <div className={styles.flow}>
          <div className={styles.block}>
            <div className={styles.text}>{t('landing:howItWorks.assetOwner')}</div>
            <div className={styles.innerBlock} />
          </div>
          <div className={styles.arrows}>
            <div className={styles.arrow} />
            <div className={cls(styles.warning, styles.hidden)}>{t('landing:howItWorks.unsoldTokens')}</div>
            <div className={cls(styles.arrow, styles.arrowLeft)} />
          </div>
          <div className={styles.block}>
            <div className={styles.text} style={{ width: 'auto' }}>
              {t('landing:howItWorks.custodian')}
            </div>
            <div className={styles.innerBlock} />
          </div>
          <div className={styles.arrows}>
            <div className={styles.arrow} />
            <div className={styles.warning}>{t('landing:howItWorks.unsoldTokens')}</div>
            <div className={cls(styles.arrow, styles.arrowLeft)} />
          </div>
          <Logo className={styles.logo} />
          <div className={styles.arrows}>
            <div className={styles.arrow} />
            <div className={cls(styles.warning, styles.hidden)}>{t('landing:howItWorks.unsoldTokens')}</div>
            <div className={cls(styles.arrow, styles.arrowLeft)} />
          </div>
          <div className={styles.block}>
            <div className={styles.text}>{t('landing:howItWorks.investor')}</div>
            <div className={styles.innerBlock} />
          </div>
        </div>
        <div className={styles.cards}>
          <div className={styles.card}>
            <div className={styles.number}>6</div>
            {t('landing:howItWorks.step6')}
          </div>
          <div className={styles.card}>
            <div className={styles.number}>5</div>
            {t('landing:howItWorks.step5')}
          </div>
          <div className={styles.card}>
            <div className={styles.number}>4</div>
            {t('landing:howItWorks.step4')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
