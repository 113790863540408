import { useState } from 'react';
import { StoreState } from 'reducers';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import {
  registerGoogleAuthenticator,
  resendGoogleAuthenticatorCode,
  verifyGoogleAuthenticator,
  disableGoogleAuthenticator
} from 'store/actions';
import EnableGoogleAuthentification from 'components/google-authenticator/enable';
import DisableGoogleAuthentification from 'components/google-authenticator/disable';
import {
  RegisterAuthenticator,
  QRCode,
  BackupKey,
  VerifyAuthenticatorEmail,
  VerifyAuthenticatorCode
} from 'components/google-authenticator/steps';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const GoogleAuthenticator: React.FC = () => {
  const { t } = useTranslation('authenticator');
  const [step, setStep] = useState(0);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [emailCode, setEmailCode] = useState('');
  const history = useHistory();

  const [qrcode, setQrCode] = useState({
    qrcode: '',
    googleCode: '',
    backupCode: ''
  });

  const { isTfaEnabled, email } = useSelector(
    (state: StoreState) => state.login.user
  );

  const register = async () => {
    setIsLoading(true);
    const data = await dispatch(registerGoogleAuthenticator(email));
    setQrCode(data);
    setIsLoading(false);
    setStep(1);
  };

  const verify = async (googleCode: string) => {
    try {
      await dispatch(
        verifyGoogleAuthenticator({
          emailCode,
          googleCode
        })
      );

      toast.success(t('notification.googleAuthenticatorEnabled'));
      history.push('/profile/overview');
    } catch (e: any) {
      const { message } = e.response.data;

      (toast as any).error(message);
    }
  };

  const disable = async (googleCode: string) => {
    try {
      await dispatch(
        disableGoogleAuthenticator({
          emailCode,
          googleCode
        })
      );

      toast.error(t('notification.googleAuthenticatorDisabled'));
      history.push('/profile/overview');
    } catch (e: any) {
      const { message } = e.response.data;

      toast.error(message);
    }
  };

  const resendCode = async () => {
    await dispatch(resendGoogleAuthenticatorCode());
    toast.success(`${t('notification.codeWasSendedTo')} ${email}`);
  };

  if (isTfaEnabled) {
    return (
      <DisableGoogleAuthentification
        current={step}
        onPrevious={() => setStep(step - 1)}
        steps={[
          <VerifyAuthenticatorEmail
            email={email}
            handleResendCode={resendCode}
            handleSubmit={(emailCode) => {
              setStep(1);
              setEmailCode(emailCode);
            }}
            title={t('text.disableGoogleAuthenticatorSecurityCheck')}
            text={t('text.disablingTwoFactorAuthentication')}
          />,
          <VerifyAuthenticatorCode
            title={t('text.disableGoogleAuthenticatorSecurityCheck')}
            text={t('text.disablingTwoFactorAuthentication')}
            handleSubmit={disable}
          />
        ]}
      />
    );
  }

  return (
    <EnableGoogleAuthentification
      current={step}
      onPrevious={() => setStep(step - 1)}
      steps={[
        <RegisterAuthenticator disabled={isLoading} onClickNext={register} />,
        <QRCode qrcode={qrcode} onClickNext={() => setStep(2)} />,
        <BackupKey
          backupCode={qrcode.backupCode}
          onClickNext={() => setStep(3)}
        />,
        <VerifyAuthenticatorEmail
          email={email}
          handleResendCode={resendCode}
          handleSubmit={(emailCode) => {
            setStep(4);
            setEmailCode(emailCode);
          }}
          title="Email
          confirmation code"
          text="Enable Google Authenticator"
        />,
        <VerifyAuthenticatorCode
          handleSubmit={verify}
          title="Google Authenticator
          confirmation code"
          text="Enable Google Authenticator"
        />
      ]}
    />
  );
};

export default GoogleAuthenticator;
