import React from 'react';
import styles from './index.module.scss';
import mainImage from 'assets/images/main.png';
import mainImage2 from 'assets/images/main2.png';
import Button from 'components/common/Button';
import { useTranslation } from 'react-i18next';
import Slider from './Slider';

interface HeroProps {
  handleScroll: (id: string) => void;
}

const Hero: React.FC<HeroProps> = ({ handleScroll }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Slider>
        <div className={styles.card}>
          <div className={styles.cta}>
            <div className={styles.title}>{t('landing:hero.title')}</div>
            <div className={styles.buttons}>
              <Button
                onClick={() => handleScroll('how-it-works')}
                className={styles.button}
                text={t('landing:hero.ctaButton')}
              />
            </div>
          </div>
          <div className={styles.image}>
            <div className={styles.center}>
              <div className={styles.inside}>{t('landing:hero.description')}</div>
            </div>
            <img src={mainImage} alt='' />
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.ctaSecond}>
            <div className={styles.title}>{t('landing:hero.title2')}</div>
            <div className={styles.buttons}>
              <Button
                onClick={() => handleScroll('about-project')}
                className={styles.button}
                text={t('landing:hero.ctaButton')}
              />
            </div>
          </div>
          <div className={styles.image}>
            <img src={mainImage2} alt='' />
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Hero;
