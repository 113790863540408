import { createStore, AnyAction, applyMiddleware } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { reducers, StoreState } from 'reducers';
import { useDispatch } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';

const thunkMiddleware: ThunkMiddleware<StoreState, AnyAction> = thunk;

export const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunkMiddleware)));

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
