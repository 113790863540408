import * as React from 'react';
import note from 'assets/icons/note.svg';
import styles from './index.module.scss';
import Button from 'components/common/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import UploadModal from 'components/UploadModal';

export interface ConfirmationProps {}

const Confirmation: React.FC<ConfirmationProps> = () => {
  const { t } = useTranslation();

  const [uploadFinished, setUploadFinished] = React.useState(false);
  const [uploadOpened, setUploadOpened] = React.useState(false);

  const handleUploadFinished = () => {
    setUploadFinished(true);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.title}>{t('kyc:kycTitle')}</div>
        <img className={styles.icon} src={note} alt='' />
        <div className={styles.green}>{t('kyc:kycConfirmationTitle')}</div>
        <div className={styles.text}>
          <div>{t('kyc:kycConfirmationSubtitle')}</div>
          {!uploadFinished && <div>{t('kyc:kycConfirmationSubtitle2')}</div>}
        </div>
        {uploadFinished ? (
          <Button text={t('common:backToProfile')} type='secondary' className={styles.button} to='/profile' />
        ) : (
          <>
            <Button
              text={t('upload:upload')}
              type='secondary'
              className={styles.button}
              onClick={() => setUploadOpened(true)}
            />
            <div className={styles.resend}>
              <Link className={styles.link} to='/profile'>
                {t('common:backToProfile')}
              </Link>
            </div>
          </>
        )}
      </div>
      <UploadModal
        opened={uploadOpened}
        handleClose={() => setUploadOpened(false)}
        handleUploadFinished={handleUploadFinished}
        selectedType='poa'
      />
    </>
  );
};

export default Confirmation;
