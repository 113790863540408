import React, { useEffect, useState } from 'react';
import ProjectCard from 'components/ProjectCard';
import styles from './index.module.scss';
import Button from 'components/common/Button';
import { useTranslation } from 'react-i18next';
import { Category, Offer, statuses } from 'actions';
import cls from 'classnames';
import Slider from './Slider';
import { CloseOutlined } from '@ant-design/icons';
import Modal from 'react-modal';
import { ScrollLocky } from 'react-scroll-locky';
// @ts-ignore
import FullScreen from 'mobile-safari-fullscreen';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from 'reducers';

// @ts-ignore
import VideoEN from 'assets/images/banner-web-eng.mp4';
// @ts-ignore
import VideoUA from 'assets/images/banner-web-ua.mp4';
// @ts-ignore
import VideoMobUA from 'assets/images/banner-mob-ua.mp4';
// @ts-ignore
import VideoMobEN from 'assets/images/banner-mob-en.mp4';
import { useScreenWidth } from 'hooks/useScreenWidth';
import { getExchangeRate } from '../../../actions/currency';
import { sendMetrikGoogleEvents } from '../../../utils/sendMetrikGoogleEvents';

// import { Pagination } from 'antd';
interface ProjectsProps {
  offers: Offer[];
  types: string[];
  status: string | null;
  categories: Category[];
  toggleType: (v: string) => void;
  toggleStatus: (v: string) => void;
}

interface VideoProps {
  language: string;
}

const VideoDesktop = ({ language }: VideoProps) => {
  const source = language === 'en' ? VideoEN : VideoUA;

  return <video autoPlay loop muted className={styles.video} src={source} />;
};

const VideoMobile = ({ language }: VideoProps) => {
  const source = language === 'en' ? VideoMobEN : VideoMobUA;

  return <video autoPlay loop muted className={styles.video} src={source} />;
};

const Video = ({ language }: VideoProps) => {
  const [width] = useScreenWidth();

  return width >= 1025 ? (
    <VideoDesktop language={language} />
  ) : (
    <VideoMobile language={language} />
  );
};

const Projects: React.FC<ProjectsProps> = ({
                                             offers,
                                             categories,
                                             types,
                                             status,
                                             toggleType,
                                             toggleStatus
                                           }) => {
  const { t, i18n } = useTranslation();
  const [filtersModal, toggleFiltersModal] = useState<boolean>(false);
  const loggedIn: boolean = useSelector(
    (state: StoreState) => state.login.loggedIn
  );
  const exchangeCurrency = useSelector((state: StoreState) => state.currency.exchangeCurrency);
  const allowedCurrency = useSelector((state: StoreState) => state.currency.allowedCurrenciesList);
  const userCurrency = useSelector((state: StoreState) => state.login.user?.currencyCode);
  const [videoLanguage, setVideoLanguage] = useState(i18n.language);
  const dispatch = useDispatch();

  useEffect(() => {
    setVideoLanguage(i18n.language);
  }, [i18n.language]);

  // const [current, setCurrent] = useState(0);
  const [paginatedOffers, setPaginatedOffers] = useState<Array<Offer>>([]);

  // const PAGE_SIZE = 20;

  useEffect(() => {
    // const start = current * PAGE_SIZE;
    // const end = start + PAGE_SIZE;
    // setPaginatedOffers([...offers].slice(start, end));
    setPaginatedOffers([...offers]);
    const projects = document.getElementById('projects');
    projects?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // }, [current, offers]);
  }, [offers]);


  useEffect(() => {
    // Take currency rate, if it doesn't exist in store
    const allowedSymbols = allowedCurrency.map((item) => item.code).join(',');
    if (offers.length > 0) {
      Array.from(new Set(offers.map(({ currencyCode }) => currencyCode)))
        .filter(code => code !== userCurrency)
        .forEach((currencyCode) => {
          if (!exchangeCurrency[currencyCode]) {
            dispatch(getExchangeRate(currencyCode, allowedSymbols));
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offers]);

  return (
    <div className={styles.wrapper} id='projects'>
      <div className={styles.padding}>
        <div className={styles.title}>{t('landing:projects.title')}</div>
        <div className={styles.controls}>
          <div className={styles.filters}>
            <div className={styles.filter}>
              <div className={styles.name}>
                {t('landing:projects.categories')}:
              </div>
              <div className={styles.chips}>
                {categories.map((category) => (
                  <div
                    className={cls(styles.chip, {
                      [styles.active]: types.includes(category.id)
                    })}
                    key={category.id}
                    onClick={() => {
                      sendMetrikGoogleEvents(`Filter-${category.name}`, 'filter')
                      toggleType(category.id);
                    }}
                  >
                    {category.name}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.filter}>
              <div className={styles.name}>{t('landing:projects.status')}:</div>
              <div className={styles.chips}>
                {Object.keys(statuses).map((item: string, i: number) => (
                  <div
                    key={i}
                    className={cls(styles.chip, {
                      [styles.active]: status === item
                    })}
                    onClick={() => {
                      sendMetrikGoogleEvents(`Filter-${item}`, 'filter')
                      toggleStatus(item);
                    }}
                  >
                    {t(statuses[item as keyof typeof statuses].name)}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <FullScreen classNames={{ iosFix: 'iosFix' }} isOpen={filtersModal}>
            <Modal
              isOpen={filtersModal}
              onRequestClose={() => toggleFiltersModal(false)}
              overlayClassName={styles.opened}
              className={styles.modal}
            >
              <ScrollLocky className={styles.content}>
                <CloseOutlined
                  onClick={() => toggleFiltersModal(false)}
                  className={styles.close}
                />
                <div>
                  <div className={styles.filter}>
                    <div className={styles.name}>
                      {t('landing:projects.categories')}:
                    </div>
                    <div className={styles.chips}>
                      {categories.map((category) => (
                        <div
                          className={cls(styles.chip, {
                            [styles.active]: types.includes(category.id)
                          })}
                          key={category.id}
                          onClick={() => {
                            sendMetrikGoogleEvents(`Filter-${category.name}`, 'filter')
                            toggleType(category.id);
                          }}
                        >
                          {category.name}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={styles.filter}>
                    <div className={styles.name}>
                      {t('landing:projects.status')}:
                    </div>
                    <div className={styles.chips}>
                      {Object.keys(statuses).map((item: string, i: number) => (
                        <div
                          key={i}
                          className={cls(styles.chip, {
                            [styles.active]: status === item
                          })}
                          onClick={() => toggleStatus(item)}
                        >
                          {t(statuses[item as keyof typeof statuses].name)}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <Button
                  className={styles.button}
                  text={t('common:applyFilters')}
                  onClick={() => toggleFiltersModal(false)}
                />
              </ScrollLocky>
            </Modal>
          </FullScreen>
          <Button
            onClick={() => toggleFiltersModal(true)}
            text={t('landing:projects.filterProjects')}
            size='sm'
            inverted
            className={styles.filtersButton}
          />
          <div onClick={() => {
            sendMetrikGoogleEvents('tokenize-my-project', 'tokenize-click');
          }}>
            <Button
              to={{ pathname: t('common:hubspotForm') }}
              target='_blank'
              text={t('landing:projects.tokenize')}
              size='sm'
            />
          </div>
        </div>
      </div>
      {loggedIn ? (
        <>
          <Slider>
            {paginatedOffers.map((offer) => (
              <ProjectCard key={offer.id} item={offer} blocked={!loggedIn} />
            ))}
          </Slider>
          <div className={styles.cards}>
            {paginatedOffers.map((offer) => (
              <ProjectCard key={offer.id} item={offer} blocked={!loggedIn} />
            ))}
          </div>
          {/*<div className={styles.pagination}>*/}
          {/*  <Pagination*/}
          {/*    current={current + 1}*/}
          {/*    onChange={(page) => setCurrent(page - 1)}*/}
          {/*    total={offers.length}*/}
          {/*    pageSize={PAGE_SIZE}*/}
          {/*  />*/}
          {/*</div>*/}
        </>
      ) : (
        <Video language={videoLanguage} />
      )}
    </div>
  );
};

export default Projects;
