import Button from 'components/common/Button';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { Offer } from 'actions';
import moment from 'moment';
import { ReactComponent as HumanIcon } from 'assets/icons/human.svg';
import cls from 'classnames';
import { useEffect, useState } from 'react';
import { offersAPI } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from 'reducers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CurrencyInput from 'react-currency-input-field';
import styles2 from '../../../components/common/Input/index.module.scss';
import convertCurrency from '../../../utils/convertCurrency';
import { getExchangeRate } from '../../../actions/currency';
import { sendMetrikGoogleEvents } from '../../../utils/sendMetrikGoogleEvents';
import { toast } from 'react-toastify';

interface StatusProps {
  project: Offer;
  className: string;
}

const { REACT_APP_TOKENSALE_URL, REACT_APP_IS_INVESTMENT_DISABLED } =
  process.env;

const Status: React.FC<StatusProps> = ({ project, className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const progress: number = (project.raisedFunds * 100) / project.goal;
  const login = useSelector((state: StoreState) => state.login);
  const exchangeCurrency = useSelector((state: StoreState) => state.currency.exchangeCurrency);
  const allowedCurrency = useSelector((state: StoreState) => state.currency.allowedCurrenciesList);
  const [disabled, setDisabled] = useState(true);
  const [minInvestment, setMinInvestment] = useState('');
  const [raise, setRaise] = useState('');


  useEffect(() => {
    setMinInvestment(convertCurrency(
      project.minimumInvestment,
      login.user.currencyCode,
      exchangeCurrency[project.currencyCode]?.[login.user.currencyCode],
      project.currencyCode
    ));

    setRaise(convertCurrency(
      project.goal,
      login.user.currencyCode,
      exchangeCurrency[project.currencyCode]?.[login.user.currencyCode],
      project.currencyCode));

  }, [project, login.user.currencyCode, project.currencyCode, exchangeCurrency]);

  const formik = useFormik({
    initialValues: {
      amount: ''
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number().required().min(Number(minInvestment.replace(/[^0-9]/g, '')))
    }),
    onSubmit({ amount }) {
      let amountToInvest = null;
      if (exchangeCurrency[project.currencyCode]?.[login.user.currencyCode]) {
      amountToInvest =
        login.user.currencyCode === project.currencyCode ?
          Number(amount) :
          Number(Number(amount) / exchangeCurrency[project.currencyCode][login.user.currencyCode]).toFixed(2);
      } else {
        amountToInvest = Number(amount)
      }
      // const amountToInvest = Number(convertCurrency(
      //   Number(amount),
      //   project.currencyCode,
      //   exchangeCurrency[login.user.currencyCode][project.currencyCode],
      //   login.user.currencyCode,
      // ).replace(/[^0-9]/g, ''));
      sendMetrikGoogleEvents('Invest-button', 'invest-button');
      handleSubmit(amountToInvest as unknown as number);
    }
  });

  const handleSubmit = async (amount: number) => {
    setDisabled(true);
    const stringifiedAmount = String(amount);

    try {
      const response = await offersAPI.getTransactionForOffer(
        project.id,
        stringifiedAmount,
        stringifiedAmount
      );
      const transactionId = response.data.id;
      console.log(response);

      setDisabled(false);
      redirectToTokensale(stringifiedAmount, transactionId, project.id);
    } catch (e) {

      if (e?.response?.data?.message) {
        toast.error(e?.response?.data?.message || 'Error');
      } else {
        toast.error('Error');
      }
      setDisabled(true);
    }
  };

  const redirectToTokensale = (
    amount: string,
    nonce: string,
    offering_id: string
  ) => {
    const search = new URLSearchParams({
      amount,
      nonce,
      offering_id
    }).toString();

    const url = `${REACT_APP_TOKENSALE_URL}?${search}`;

    window.open(url, '_blank')?.focus();
  };

  useEffect(() => {
    const isInvestmentDisabled = !!Number(REACT_APP_IS_INVESTMENT_DISABLED);
    const canInvest = login.loggedIn && login.user?.kycStatus === 'clear';

    if (canInvest) {
      setDisabled(isInvestmentDisabled);
    }
  }, [login]);

  useEffect(() => {
  }, [formik.values.amount]);

  useEffect(() => {
    const allowedSymbols = allowedCurrency.map((item) => item.code).join(',');

    if (!exchangeCurrency[project.currencyCode]) {
      dispatch(getExchangeRate(project.currencyCode, allowedSymbols));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cls(styles.wrapper, className)}>
      <div className={styles.top}>
        <div className={styles.investors}>
          <div>
            <div className={styles.raised}>
              {t('project:raised')} {raise}
            </div>
            <div className={styles.total}>
              {/*${project.raisedFunds.toLocaleString('en-US')}*/}
              {convertCurrency(
                project.raisedFunds,
                login.user.currencyCode,
                exchangeCurrency[project.currencyCode]?.[login.user.currencyCode],
                project.currencyCode)}
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.raised}>{t('common:investors')}</div>
            <div className={styles.large}>
              <HumanIcon />
              {project.totalInvestors}
            </div>
          </div>
        </div>
        <div className={styles.bar}>
          <div
            style={{
              width: `${
                progress < 4 && progress !== 0
                  ? 4
                  : progress > 100
                    ? 100
                    : progress
              }%`
            }}
          />
        </div>
        <div className={styles.terms}>
          <div>
            {t('project:terms')}: {project.terms}
          </div>
          <div>
            {moment(project.endDate).diff(moment(), 'days')}{' '}
            {t('project:daysLeft')} {t('project:toGo')}
          </div>
        </div>
        <div className={styles.investors}>
          <div>
            <div className={styles.raised}>{t('project:goal')}</div>
            <div className={styles.large}>
              {/*${project.goal.toLocaleString('en-US')}*/}
              {raise}
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.raised}>{t('project:returns')}</div>
            <div className={styles.large}>{project.expectedReturns}%</div>
          </div>
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.bottom}>
        <div className={styles.invest}>
          <div>
            <div style={{ margin: '0 0 10px 0' }}>
              <CurrencyInput
                prefix={new Intl.NumberFormat('en-EN', {
                  style: 'currency',
                  currency: exchangeCurrency[project.currencyCode] ? login.user.currencyCode : project.currencyCode
                }).format(0).toString()[0]}
                id='amount'
                name='amount'
                value={formik.values.amount}
                onValueChange={(value) => formik.setFieldValue('amount', value)}
                onBlur={formik.handleBlur}
                className={cls(styles2.input)}
                placeholder='Your investment'
              />
              {/* <Input
                id="amount"
                name="amount"
                value={formik.values.amount}
                type="number"
                placeholder="Your investment"
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
              /> */}
              {formik.touched.amount && formik.errors.amount && (
                <span className={styles.error}>
                  Minimum investment must be {minInvestment}
                </span>
              )}
            </div>
            {t('project:minimumInvestment')}:
            {/*{project.minimumInvestment.toLocaleString('en-US')}*/}
            {minInvestment}
          </div>
          {/*{project.status === 'ONGOING' && (*/}
          {false && (
            <>
              <Button
                disabled={disabled}
                className={styles.button}
                onClick={formik.handleSubmit}
                text={t('common:invest')}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Status;
