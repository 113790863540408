import * as React from 'react';
import { ReactComponent as SmartlandsIcon } from 'assets/icons/smartlandsIcon.svg';
import styles from './index.module.scss';

export interface LoaderProps {}

const Loader: React.FC<LoaderProps> = () => {
  return (
    <div className={styles.wrapper}>
      <SmartlandsIcon />
    </div>
  );
};

export default Loader;
