import { Button, Card } from 'antd';
import { KycStatus } from 'pages/Admin/users.api';
import { UserInfo } from './index';

import styles from 'pages/Admin/index.module.scss';

interface UserActionsProps {
  user: UserInfo;
  onBan: (isBanned: boolean) => void;
  onChangeStatus: (status: KycStatus) => void;
}

export const UserActions: React.FC<UserActionsProps> = ({
  user,
  onBan,
  onChangeStatus
}) => {
  return (
    <Card className="admin-card">
      <div className={styles.actions}>
        <Button
          type="ghost"
          size="middle"
          disabled={user.kycStatus === KycStatus.Considered}
          className="admin-user-primary-btn"
          onClick={() => onChangeStatus(KycStatus.Considered)}
        >
          Set Status CONSIDERED
        </Button>
        <Button
          type="ghost"
          size="middle"
          disabled={user.kycStatus === KycStatus.Clear}
          onClick={() => onChangeStatus(KycStatus.Clear)}
          className="admin-user-success-btn"
        >
          Set Status CLEAR
        </Button>
        <Button
          type="ghost"
          size="middle"
          danger
          disabled={Boolean(user.isBanned)}
          className="admin-user-danger-btn"
          onClick={() => onBan(true)}
        >
          Ban User
        </Button>
        <Button
          type="ghost"
          size="middle"
          disabled={!Boolean(user.isBanned)}
          className="admin-user-default-btn"
          onClick={() => onBan(false)}
        >
          Unban User
        </Button>
      </div>
    </Card>
  );
};
