import React from 'react';

import styles from './index.module.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

interface ButtonsProps {
  [x: string]: any;
}

const Buttons: React.FC<ButtonsProps> = ({ next, previous }) => (
  <div className={styles.wrapper}>
    <div className={styles.left} onClick={() => previous()}>
      <LeftOutlined />
    </div>
    <div className={styles.right} onClick={() => next()}>
      <RightOutlined />
    </div>
  </div>
);

export default Buttons;
