import React, { useState } from 'react';
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/twitter.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as MediumIcon } from 'assets/icons/medium.svg';
import { ReactComponent as YoutubeIcon } from 'assets/icons/youtube.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.svg';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Input from 'components/common/Input';
import styles from './index.module.scss';
import { useAppDispatch } from 'store';
import { subscribe } from 'actions';
import * as Yup from 'yup';
import { email } from 'utils/validation';
import { toast } from 'react-toastify';
import cls from 'classnames';
import { useSelector } from 'react-redux';
import { StoreState } from '../../reducers';
import { sendMetrikGoogleEvents } from '../../utils/sendMetrikGoogleEvents';

let schema = Yup.object().shape({
  email: email
});

interface FooterProps {
  isProfile?: boolean;
}

const Footer: React.FC<FooterProps> = ({ isProfile }) => {
  const [value, setValue] = useState<string>('');
  const { t } = useTranslation();
  const loggedIn = useSelector((state: StoreState) => state.login.loggedIn);
  const user = useSelector((state: StoreState) => state.login.user);
  const dispatch = useAppDispatch();

  const handleSubscribe = () => {
    schema
      .isValid({
        email: value
      })
      .then((valid) => {
        if (valid) {
          dispatch(subscribe(value));
        } else {
          toast.error(t('notifications:invalidEmail'));
        }
        setValue('');
      });
  };
  const isAdmin = !!(loggedIn && (user as any)?.role === 'ADMIN');

  return (
    <div
      className={cls(styles.wrapper, { [styles.profileWrapper]: isProfile })}
    >
      <div className={styles.column}>
        <div className={styles.links}>
          <div className={styles.block}>
            <Link
              to={{ pathname: t('common:privacyPolicy') }}
              target='_blank'
              className={styles.link}
            >
              {t('footer:privacyPolicy')}
            </Link>
            <Link
              to={{ pathname: t('common:termsLink') }}
              target='_blank'
              className={styles.link}
            >
              {t('footer:termsOfConditions')}
            </Link>
            {!isAdmin && (
              <>
                <Link
                  to={{ pathname: '#' }}
                  target='_blank'
                  className={styles.link}
                >
                  {t('footer:investementTerms')}
                </Link>
                <Link
                  to={{ pathname: 'https://share.hsforms.com/1fX-vdlszR8C01a6NkWWKrA34kwv' }}
                  target='_blank'
                  className={styles.link}
                >
                  {t('footer:raiseCapital')}
                </Link>
              </>
            )
            }

          </div>
          <div>
            <div onClick={() => {sendMetrikGoogleEvents('About-us-click', 'company-info')}}>
              <Link
                to={{ pathname: 'https://definder.global/about/' }}
                target='_blank'
                className={styles.link}
              >
                {t('footer:aboutUs')}
              </Link>
            </div>
            <div onClick={() => {sendMetrikGoogleEvents('News-click', 'click-news-company')}}>
              <Link
                to={{ pathname: 'https://definder.global/category/blog/' }}
                target='_blank'
                className={styles.link}
              >
                {t('footer:news')}
              </Link>
            </div>
            <Link
              to={{ pathname: 'mailto:support@definder.global' }}
              target='_blank'
              className={styles.link}
            >
              {t('footer:contactUs')}
            </Link>
            <Link
              to={{ pathname: 'mailto:team@smartlands.network' }}
              target='_blank'
              className={styles.link}
            >
              {t('footer:help')}
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.form}>
          <Input
            placeholder={t('common:email')}
            value={value}
            handleChange={(e) => setValue(e.target.value)}
            className={styles.input}
          />
          <Button
            onClick={handleSubscribe}
            type='ghost'
            size='large'
            disabled={!value}
            shape='round'
            style={{
              borderColor: 'black',
              backgroundColor: 'transparent',
              color: 'black',
              fontWeight: 300
            }}
          >
            {t('common:subscribe')}
          </Button>
        </div>
        <div className={styles.icons}>
          <Link
            to={{ pathname: 'https://t.me/smartlandschat' }}
            target='_blank'
          >
            <TelegramIcon className={styles.icon} />
          </Link>
          <Link
            to={{ pathname: 'https://twitter.com/smartlands' }}
            target='_blank'
          >
            <TwitterIcon className={styles.icon} />
          </Link>
          <Link
            to={{ pathname: 'https://www.facebook.com/SmartlandsPlatform/' }}
            target='_blank'
          >
            <FacebookIcon className={styles.icon} />
          </Link>
          <Link
            to={{ pathname: 'https://www.instagram.com/smartlands_global/' }}
            target='_blank'
          >
            <InstagramIcon className={styles.icon} />
          </Link>
          <Link
            to={{
              pathname:
                'https://www.youtube.com/channel/UCyd4BGgS-oKabK5Y30X4h3g'
            }}
            target='_blank'
          >
            <YoutubeIcon className={styles.icon} />
          </Link>
          <Link
            to={{ pathname: 'https://medium.com/smartlands' }}
            target='_blank'
          >
            <MediumIcon className={styles.icon} />
          </Link>
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.content}>
          <div className={styles.info}>{t('footer:disclaimer')}</div>
          <div className={styles.info}>{t('footer:copyright')}</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
