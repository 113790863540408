import { useEffect, useState } from 'react';
import logo from 'assets/images/logo_beta.svg';
import { Menu, Dropdown } from 'antd';
import {
  DownCircleOutlined,
  LoginOutlined,
  UserOutlined,
  UserAddOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import styles from './index.module.scss';
import { StoreState } from 'reducers';
import { changeLanguage, languages, User } from 'actions';
import { useTranslation } from 'react-i18next';

const { REACT_APP_API } = process.env;
//const REACT_APP_API = "http://localhost:3000/api";

const Header: React.FC = () => {
  const language: string = useSelector(
    (state: StoreState) => state.language.language
  );
  const loggedIn: boolean = useSelector(
    (state: StoreState) => state.login.loggedIn
  );
  const user: User = useSelector((state: StoreState) => state.login.user);
  const [displayNavigation, setDisplayNavigation] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    setDisplayNavigation(location.pathname === '/');
  }, [location.pathname]);

  const handleScroll = (id: string) => {
    const yOffset = -80;
    const element = document.getElementById(id);

    if (element) {
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const isAdmin = user && (user as any).role === 'ADMIN';

  return (
    <div className={styles.wrapper}>
      {isAdmin ? (
        <img src={logo} alt="logo" className={styles.logo} />
      ) : (
        <Link to="/">
          <img src={logo} alt="logo" className={styles.logo} />
        </Link>
      )}

      <div className={styles.controls}>
        {displayNavigation && (
          <div className={styles.navigation}>
            <div onClick={() => handleScroll('projects')}>
              {t('landing:projects.title')}
            </div>
            <div onClick={() => handleScroll('how-it-works')}>
              {t('landing:howItWorks.title')}
            </div>
            <div onClick={() => handleScroll('about-project')}>
              {t('landing:about.title')}
            </div>
          </div>
        )}
        <nav className={styles.links}>
          {loggedIn ? (
            <Link to={isAdmin ? '/admin' : '/profile'} className={styles.link}>
              {user.avatar ? (
                <div className={styles.avatar}>
                  <img
                    src={REACT_APP_API + '/storage/file-stream/' + user.avatar}
                    alt=""
                  />
                </div>
              ) : (
                <UserOutlined />
              )}
              <div className={styles.text}>
                {[user.firstName, user.lastName].filter(Boolean).join(' ')}
              </div>
            </Link>
          ) : (
            <>
              <Link
                to="/login"
                className={styles.link}
                style={{ marginRight: 15 }}
              >
                <LoginOutlined />
                <div className={styles.text}>{t('login:login')}</div>
              </Link>
              <Link to="/registration" className={styles.link}>
                <UserAddOutlined />
                <div className={styles.text}>{t('login:registration')}</div>
              </Link>
            </>
          )}
        </nav>
        {isAdmin ? null : (
          <Dropdown
            trigger={['click']}
            className={styles.dropdown}
            overlay={
              <Menu>
                {languages.map((item) => (
                  <Menu.Item
                    key={item.value}
                    onClick={() => dispatch(changeLanguage(item.value))}
                    className={styles.item}
                  >
                    {item.label}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <div>
              {languages.find((item) => item.value === language)?.label}{' '}
              <DownCircleOutlined className={styles.icon} />
            </div>
          </Dropdown>
        )}
      </div>
    </div>
  );
};

export default Header;
