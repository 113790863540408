export const sendMetrikGoogleEvents = (action: string, category: string, label?: string, value?: string) => {
  const values: any = {};
  if (typeof category !== 'undefined') {
    values['event_category'] = category;
  }
  if (typeof label !== 'undefined') {
    values['event_label'] = label;
  }
  if (typeof value !== 'undefined') {
    values['value'] = value;
  }// @ts-ignore
  if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {// @ts-ignore
    window.gtag('event', action, values);
  } else {
    // на случай, если локально статистика отключена
    console.log('event', action, values);
  }
}