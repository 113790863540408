import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import Hero from './Hero';
import Projects from './Projects';
import About from './About';
import Benefits from './Benefits';
import { useAppDispatch } from 'store';
import { Category, getOffers, Offer } from 'actions';
import { useSelector } from 'react-redux';
import { StoreState } from 'reducers';
import Audience from './Audience';
import Partners from './Partners';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Landing: React.FC = () => {
  const categories: Category[] = useSelector(
    (state: StoreState) => state.offers
  );
  const language: string = useSelector(
    (state: StoreState) => state.language.language
  );

  const [types, setTypes] = useState<string[]>([]);
  const [status, setStatus] = useState<string | null>(null);

  const [offers, setOffers] = useState<Offer[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = cookies.get('token');
    if (token) {
      dispatch(getOffers({ status, categoryIds: types })).then((r) =>
        setOffers(r.results)
      );
    }
  }, [dispatch, types, status, language]);

  const handleScroll = (id: string) => {
    const yOffset = -80;
    const element = document.getElementById(id);

    if (element) {
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const toggleType = (v: string) => {
    if (types.includes(v)) {
      setTypes((prev) => prev.filter((item) => item !== v));
    } else {
      setTypes((prev) => [...prev, v]);
    }
  };

  const toggleStatus = (v: string) => {
    if (status === v) {
      setStatus(null);
    } else {
      setStatus(v);
    }
  };

  return (
    <div className={styles.content}>
      <Hero handleScroll={handleScroll} />
      <Projects
        types={types}
        offers={offers}
        status={status}
        toggleType={toggleType}
        categories={categories}
        toggleStatus={toggleStatus}
      />
      <About />
      <Benefits handleScroll={handleScroll} />
      <Audience />
      <Partners />
    </div>
  );
};

export default Landing;
