import React from 'react';
import { useTranslation } from 'react-i18next';
import agr from 'assets/images/partners/agr.png';
import big from 'assets/images/partners/big.png';
import es from 'assets/images/partners/es.png';
import of from 'assets/images/partners/of.png';
import qa from 'assets/images/partners/qa.png';
import smn from 'assets/images/partners/smn.png';
import st from 'assets/images/partners/st.png';
import tr from 'assets/images/partners/tr.png';
import uice from 'assets/images/partners/uice.png';
import ws from 'assets/images/partners/ws.png';
import styles from './index.module.scss';

export interface PartnersProps {}

const images = [
  { image: st, link: 'https://www.stellar.org/' },
  { image: of, link: 'https://onfido.com/' },
  { image: smn, link: 'https://smartlands.network/' },
  { image: qa, link: 'https://quantum-attorneys.com/' },
  { image: es, link: 'https://empirestatecap.com/' },
  { image: uice, link: 'https://www.uicegroup.com/' },
  { image: agr, link: 'https://agroxy.com.ua/' },
  { image: big, link: 'https://www.big-u.org/' },
  { image: ws, link: 'https://white-am.com.ua/' },
];

const Partners: React.FC<PartnersProps> = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper} id='partners'>
      <div className={styles.title}>{t('landing:partners.title')}</div>
      <div className={styles.icons}>
        {images.map((item, i) => (
          <a key={i} href={item.link} target='_blank' rel='noopener noreferrer'>
            <img src={item.image} alt='' />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Partners;
