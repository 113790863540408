import { useState, useRef } from 'react';
import Modal from 'react-modal';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as UploadingIcon } from 'assets/icons/uploading.svg';
import { ReactComponent as SuccessUploadIcon } from 'assets/icons/successUpload.svg';
import { ReactComponent as FailedUploadIcon } from 'assets/icons/failedUpload.svg';
import styles from './index.module.scss';
import Button from 'components/common/Button';
import { CloseCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store';
import { documentTypes, uploadDocument } from 'actions';

const { TextArea } = Input;
const { Option } = Select;

export interface UploadModalProps {
  opened: boolean;
  handleClose: () => void;
  handleUploadFinished: () => void;
  selectedType?: string;
}

const UploadModal: React.FC<UploadModalProps> = ({ opened, handleClose, handleUploadFinished, selectedType }) => {
  const [document, setDocument] = useState<null | File>(null);
  const [type, setType] = useState(selectedType || 'poa');
  const [description, setDescription] = useState('');
  const [cancelToken, setCancelToken] = useState<any>(null);
  const [progress, setProgress] = useState(0);
  const inputFile = useRef<HTMLInputElement>(null);
  const [status, setStatus] = useState('init');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const openUpload = () => {
    inputFile.current?.click();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const files = e.target.files;

    if (!!files?.length) {
      if (files[0].size > 10000000) {
        toast.error(t('notifications:invalidFileSize'));
        return;
      }

      if (files[0].type.includes('image')) {
        const img = new Image();
        img.src = window.URL.createObjectURL(files[0]);
        img.onload = function () {
          const width = img.naturalWidth,
            height = img.naturalHeight;

          window.URL.revokeObjectURL(img.src);

          if (width < 600 || height < 600) {
            toast.error(t('notifications:invalidFileDimensions'));
          }

          if (width >= 600 && height >= 600) {
            setDocument(files[0]);
          }
        };
      } else {
        setDocument(files[0]);
      }
    }
  };

  const handleUpload = () => {
    if (document && type) {
      setStatus('loading');

      dispatch(
        uploadDocument(document, type, description, (v, c) => {
          setProgress(v);
          if (!cancelToken) {
            setCancelToken(c);
          }
        }),
      )
        .then(() => {
          setDocument(null);
          setCancelToken(null);
          setProgress(0);
          setType('poa');
          setDescription('');
          handleUploadFinished();
          setStatus('success');
        })
        .catch(e => {
          if (e.message === 'canceled') {
            setDocument(null);
            setType('poa');
            setCancelToken(null);
            setProgress(0);
            setDescription('');
            setStatus('init');
            if (inputFile.current) inputFile.current.value = '';
          } else {
            toast.error(t('notifications:error'));
            setStatus('error');
          }
        })
        .finally(() => {
          if (inputFile.current) inputFile.current.value = '';
        });
    }
  };

  const handleCancel = () => {
    if (!!cancelToken) {
      cancelToken?.cancel('canceled');
    }
  };

  return (
    <>
      <Modal
        isOpen={opened}
        overlayClassName={styles.opened}
        bodyOpenClassName={styles.body}
        className={styles.modal}
        onRequestClose={() => handleClose()}
        onAfterClose={() => {
          setDocument(null);
          setType('poa');
          setCancelToken(null);
          setProgress(0);
          setDescription('');
          setStatus('init');
          if (inputFile.current) inputFile.current.value = '';
        }}
      >
        <CloseOutlined className={styles.close} onClick={() => handleClose()} />
        {status === 'init' ? (
          <div className={styles.form}>
            <UploadIcon />
            <div className={styles.title}>{t('upload:uploadDocument')}</div>
            <div onClick={openUpload} style={{ display: 'flex', width: '100%', marginBottom: 20 }}>
              <Input
                style={{
                  borderBottom: '1px solid black',
                  borderRadius: 0,
                  width: '100%',
                  paddingLeft: 0,
                  caretColor: 'transparent',
                }}
                value={document ? document.name : t<string>('upload:uploadDocument')}
                bordered={false}
              />
              <Button
                text={t('upload:browse')}
                type='secondary'
                inverted
                className={styles.button}
                onClick={() => {}}
              />
            </div>
            <div style={{ width: '100%', marginBottom: 20 }}>
              <Select
                value={type}
                disabled={!!selectedType}
                onChange={v => setType(v)}
                style={{ width: '100%', marginBottom: 5 }}
              >
                {documentTypes.map((item: { value: string; label: string }, i: number) => (
                  <Option key={i} value={item.value}>
                    {t(item.label)}
                  </Option>
                ))}
              </Select>
              {type === 'poa' && <div style={{ fontSize: 12, color: '#727171' }}>{t('upload:poaWarning')}</div>}
            </div>
            <div className={styles.input}>
              <div>{t('upload:addComment')}</div>
              <TextArea rows={4} onChange={e => setDescription(e.target.value)} value={description} />
            </div>
            <Button type='secondary' text={t('upload:upload')} onClick={() => handleUpload()} disabled={!document} />
          </div>
        ) : status === 'loading' ? (
          <>
            <UploadingIcon className={styles.icon} />
            <div className={styles.text}>Uploading</div>
            <div className={styles.progressWrapper}>
              <div className={styles.infoWrapper}>
                <div>{document?.name}</div>
                <div>{t(documentTypes.find(item => item.value === type)?.label || '')}</div>
                <div className={styles.action} onClick={() => handleCancel()}>
                  <CloseCircleOutlined />
                  Cancel
                </div>
              </div>
              <div className={styles.progressBar}>
                <div style={{ width: `${progress}%` }} />
              </div>
              <div className={styles.status}>
                <div>{progress}% done</div>
                {document && <div>{(document?.size / 1000000).toFixed(3)} mb</div>}
              </div>
            </div>
          </>
        ) : status === 'success' ? (
          <>
            <SuccessUploadIcon className={styles.icon} />
            <div className={styles.text}>File succesfully uploaded</div>
          </>
        ) : (
          <>
            <FailedUploadIcon className={styles.icon} />
            <div className={styles.text}>Sorry, something went wrong.</div>
            <div>Please, try again.</div>
            <Button
              text='Upload document'
              type='secondary'
              inverted
              className={styles.restartButton}
              onClick={() => {
                setDocument(null);
                setType('poa');
                setCancelToken(null);
                setProgress(0);
                setDescription('');
                setStatus('init');
                if (inputFile.current) inputFile.current.value = '';
              }}
            />
          </>
        )}
      </Modal>
      <input type='file'
             id='file' ref={inputFile}
             onChange={handleChange}
             style={{ display: 'none' }}
             accept={type === 'accreditation' ? '.pdf,.jpg,.png,.tiff' : ''}
      />
    </>
  );
};

export default UploadModal;
