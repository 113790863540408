import { Card, Select, DatePicker, Input } from 'antd';
import { Moment } from 'moment';
import { ChangeEvent } from 'react';
import styles from '../index.module.scss';
import { LanguageCode, ProjectStatus, SearchDateType } from '../projects.api';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;

interface SearchBoxProps {
  searchDateType: string;
  onSearchDateTypeChange: (value: string) => void;
  onDateChange: (
    date: [Moment, Moment] | any,
    dateString: [string, string]
  ) => void;
  search: string;
  onSearchChange: (e: ChangeEvent) => void;
  status: string;
  onChangeStatus: (value: string) => void;
  onSubmit: () => void;
  languageCode: string;
  onLanguageCodeChange: (value: string) => void;
}

export const SearchBoxProjects: React.FC<SearchBoxProps> = ({
  onDateChange,
  search,
  onSearchChange,
  searchDateType,
  onSearchDateTypeChange,
  onChangeStatus,
  status,
  onSubmit,
  languageCode,
  onLanguageCodeChange
}) => {
  return (
    <Card size="small" className="admin-card">
      <div
        className={`${styles.searchContainer} ${styles.searchContainerProjects}`}
      >
        <div className={styles.container}>
          <h3>Search date type: </h3>
          <Select
            className={styles.select}
            id="searchDateType"
            onChange={onSearchDateTypeChange}
            value={searchDateType}
          >
            <Option value={SearchDateType.Created}>Created</Option>
            <Option value={SearchDateType.Updated}>Updated</Option>
          </Select>
        </div>
        <div className={styles.container}>
          <h3>Date range: </h3>
          <RangePicker onChange={onDateChange} className={styles.date} />
        </div>
        <div className={styles.container}>
          <h3>Search status: </h3>
          <Select
            className={styles.select}
            id="status"
            onChange={onChangeStatus}
            value={status}
          >
            <Option value={ProjectStatus.ComingSoon}>Coming Soon</Option>
            <Option value={ProjectStatus.Ongoing}>Ongoing</Option>
            <Option value={ProjectStatus.Past}>Past</Option>
          </Select>
        </div>
        <div className={styles.container}>
          <h3>Query: </h3>
          <Search
            value={search}
            id="search"
            name="search"
            onChange={onSearchChange}
            onSearch={onSubmit}
          />
        </div>
        <div className={styles.container}>
          <h3>Search language: </h3>
          <Select
            className={styles.select}
            id="languageCode"
            onChange={onLanguageCodeChange}
            value={languageCode}
          >
            <Option value={LanguageCode.EN}>English</Option>
            <Option value={LanguageCode.UK}>Ukrainian</Option>
          </Select>
        </div>
      </div>
    </Card>
  );
};
