import React, { useEffect } from 'react';
import Router from 'router';
import ScrollTop from 'components/ScrollTop';
import { useDispatch, useSelector } from 'react-redux';
import { detectCountry, detectLanguage, getCategories, getUser } from 'actions';
import { StoreState } from 'reducers';
import { getCurrencies } from './actions/currency';
import GoogleAnalytics from './components/GoogleAnalytics/GoogleAnalytics'

const App: React.FC = () => {
  const dispatch = useDispatch();
  const language: string = useSelector((state: StoreState) => state.language.language);
  const loggingIn: boolean = useSelector((state: StoreState) => state.login.loggingIn);


  useEffect(() => {

    dispatch(detectLanguage());
    dispatch(detectCountry());
    dispatch(getCurrencies());

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  });

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch, language]);

  if (loggingIn) return null;

  return (
    <>
      <ScrollTop />
      <Router />
      <GoogleAnalytics />
    </>
  );
};

export default App;
