import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Description from './Description';
import styles from './index.module.scss';
import { useAppDispatch } from 'store';
import { getOffer, Offer } from 'actions';
import { useParams } from 'react-router-dom';
import { StoreState } from 'reducers';
import { useSelector } from 'react-redux';
import Slider from './Slider';
import Viewer from 'react-viewer';
import { ReactComponent as MarkerIcon } from 'assets/icons/marker.svg';
import { ReactComponent as HouseIcon } from 'assets/icons/house.svg';
import { ReactComponent as QuestionIcon } from 'assets/icons/question.svg';
import { ReactComponent as GalleryIcon } from 'assets/icons/gallery.svg';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';
import { use100vh } from 'react-div-100vh';
import Status from './Status';
import Button from 'components/common/Button';
import _ from 'lodash';
import cls from 'classnames';
import StatusBadge from 'components/StatusBadge';
import Loader from 'components/Loader';
import { getImageLink } from 'pages/Admin/utils/get-image-link';

interface ProjectProps {}

const Project: React.FC<ProjectProps> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id }: { id: string } = useParams();
  const [project, setProject] = useState<Offer | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const language: string = useSelector(
    (state: StoreState) => state.language.language
  );
  const [visible, setVisible] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [activeSliderIndex, setActiveSliderIndex] = useState<number>(0);
  const [isHide, setHide] = useState<boolean>(true);
  const height: number = use100vh() || window.innerHeight;

  useEffect(() => {
    const throttledScrollHandler = _.throttle(handleScroll, 300);

    window.addEventListener('scroll', throttledScrollHandler);

    return () => window.removeEventListener('scroll', throttledScrollHandler);
  }, []);

  useEffect(() => {
    setLoading(true);

    dispatch(getOffer(id)).then((r) => {
      setProject(r);
      setTimeout(() => setLoading(false), 1000);
    });
  }, [dispatch, id, language]);

  const handlers = useSwipeable({
    onTap: () => {
      setTimeout(() => {
        setVisible(false);
      }, 100);

      return false;
    },
    onSwipedLeft: () => {
      if (visible && project?.descriptionImages.length) {
        setActiveIndex((index) => {
          if (index === project.descriptionImages.length - 1) return 0;

          return index + 1;
        });
      }
    },
    onSwipedRight: () => {
      if (visible && project?.descriptionImages.length) {
        setActiveIndex((index) => {
          if (index === 0) return project.descriptionImages.length - 1;
          return index - 1;
        });
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    trackTouch: true
  });

  const handleOpenImage = (index: number): void => {
    setActiveIndex(index);
    setVisible(true);
  };

  const handleOpenGallery = (): void => {
    setActiveIndex(0);
    setVisible(true);
  };

  const handleScroll = () => {
    window.scrollY < 400 ? setHide(true) : setHide(false);
  };

  if (loading) return <Loader />;

  return project ? (
    <>
      {project?.projectName && (
        <Helmet>
          <title>{project.projectName} - Smartlands</title>
        </Helmet>
      )}
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.categories}>
            {project?.categories.map((category, i) => (
              <div key={i} className={styles.chip}>
                {category}
              </div>
            ))}
          </div>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>{project.projectName} </div>
            <StatusBadge status={project.status} />
          </div>
          <div className={styles.icons}>
            {!!project.company && (
              <div className={styles.icon}>
                <HouseIcon />
                <span>{project.company}</span>
              </div>
            )}
            {!!project.location && (
              <div className={styles.icon}>
                <MarkerIcon />
                <span>{project.location}</span>
              </div>
            )}
            {!!(project as any).website && (
              <a
                href={(project as any).website}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.icon}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8" cy="8" r="7.5" stroke="#276DF1" />
                  <path
                    d="M11.5 8C11.5 10.1483 11.0635 12.0686 10.3812 13.4332C9.68393 14.8278 8.81316 15.5 8 15.5C7.18684 15.5 6.31607 14.8278 5.61879 13.4332C4.93646 12.0686 4.5 10.1483 4.5 8C4.5 5.85165 4.93646 3.93141 5.61879 2.56675C6.31607 1.17219 7.18684 0.5 8 0.5C8.81316 0.5 9.68393 1.17219 10.3812 2.56675C11.0635 3.93141 11.5 5.85165 11.5 8Z"
                    stroke="#276DF1"
                  />
                  <path d="M1 6L15 6" stroke="#276DF1" />
                  <path d="M1 10L15 10" stroke="#276DF1" />
                </svg>

                <span>{(project as any).website}</span>
              </a>
            )}
            <div
              className={styles.icon}
              onClick={() => {
                window.open('mailto:support@definder.global');
              }}
            >
              <QuestionIcon />
              <span>{t('common:askQuestion')}</span>
            </div>
          </div>
          <div className={styles.info}>
            <Status project={project} className={styles.statusMobile} />
            {!!project.descriptionImages?.length && (
              <div className={styles.slider}>
                <Slider
                  items={project.descriptionImages}
                  setActiveIndex={setActiveSliderIndex}
                  handleClick={handleOpenImage}
                />
                <div className={styles.gallery} onClick={handleOpenGallery}>
                  <GalleryIcon />
                  {t('common:viewGallery')}
                </div>
                <div className={styles.count} onClick={handleOpenGallery}>
                  <GalleryIcon />
                  {activeSliderIndex + 1} / {project.descriptionImages.length}
                </div>
              </div>
            )}
          </div>
        </div>
        <Description project={project} />
      </div>
      {!!project.descriptionImages?.length && visible && (
        <>
          <div
            className={styles.overlay}
            style={{
              height: height - 100
            }}
            {...handlers}
          />
          <Viewer
            visible={visible}
            activeIndex={activeIndex}
            onChange={(activeImage, index) => {
              setActiveIndex(index);
            }}
            onClose={() => setVisible(false)}
            images={project.descriptionImages.map((image: string) => ({
              src: getImageLink(image),
              alt: ''
            }))}
            attribute={false}
            rotatable={false}
            scalable={false}
            changeable={true}
            zoomSpeed={0.3}
          />
        </>
      )}
      <div className={cls(styles.banner, { [styles.hide]: isHide })}>
        <div className={styles.invest}>
          <div>
            {t('project:minimumInvestment')}: $
            {project.minimumInvestment.toLocaleString('en-US')}
          </div>
          {project.status === 'ONGOING' && (
            <Button
              disabled={true}
              className={styles.button}
              onClick={() => {}}
              text={t('common:invest')}
            />
          )}
        </div>
      </div>
    </>
  ) : (
    <div />
  );
};

export default Project;
