import * as React from 'react';
import styles from './index.module.scss';
import Carousel from 'react-multi-carousel';
import Buttons from './Buttons';

const responsive = {
  desktop: {
    breakpoint: { max: 11000, min: 1100 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1099, min: 700 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 699, min: 0 },
    items: 1,
  },
};

export interface SliderProps {
  children: React.ReactNode;
}

const Slider: React.FC<SliderProps> = ({ children }) => {
  return (
    <div className={styles.carousel}>
      <Carousel
        draggable={true}
        keyBoardControl={false}
        responsive={responsive}
        showDots={true}
        arrows={false}
        customButtonGroup={<Buttons />}
        renderButtonGroupOutside
        className=''
        containerClass={styles.container}
        dotListClass=''
        itemClass={styles.item}
        sliderClass=''
        infinite={false}
        additionalTransfrom={0}
        centerMode={false}
        slidesToSlide={1}
      >
        {children}
      </Carousel>
    </div>
  );
};

export default Slider;
