import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';

function ScrollTop() {
  const history = useHistory();

  useEffect(() => {
    smoothscroll.polyfill();

    const listener = history.listen(() => {
      window.scrollTo({ top: 0, left: 0, behavior: history.location.pathname === '/' ? 'smooth' : 'auto' });
    });
    return () => {
      listener();
    };
  }, [history]);

  return null;
}

export default ScrollTop;
