import { Button, Card, Divider, Empty, Table } from 'antd';
import { useFormik } from 'formik';
import { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SearchBoxProjects } from '../components/SearchBoxProjects';
import {
  getAllProjects,
  LanguageCode,
  ProjectStatus,
  SearchDateType
} from '../projects.api';
import { projectsColumns } from './projects-columns';

export const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [totalProjects, setTotalProjects] = useState(0);
  const [searchResults, setSearchResult] = useState([]);
  const [page, setPage] = useState(0);

  const formik = useFormik({
    initialValues: {
      search: '',
      startDate: '',
      endDate: '',
      searchDateType: SearchDateType.Created,
      status: ProjectStatus.ComingSoon,
      languageCode: LanguageCode.EN
    },
    onSubmit: async () => {
      const {
        data: { results }
      } = await getAllProjects({ ...formik.values });
      setSearchResult(results);
    }
  });

  const handleDateChange = (
    date: [Moment, Moment] | any,
    dateString: [string, string]
  ) => {
    const [startDate, endDate] = dateString;
    formik.setFieldValue('startDate', startDate);
    formik.setFieldValue('endDate', endDate);
  };

  const handleSelectChange = (
    value: string,
    field: keyof typeof formik.values
  ) => {
    formik.setFieldValue(field, value);
  };

  useEffect(() => {
    getAllProjects({ page }).then((response) => {
      const { results, total } = response.data;
      setProjects(results);
      setTotalProjects(total);
    });
  }, [page]);

  const handlePaginationChange = (currentPage: number) => {
    setPage(currentPage - 1);
  };

  return (
    <>
      <SearchBoxProjects
        search={formik.values.search}
        onSearchChange={formik.handleChange}
        searchDateType={formik.values.searchDateType}
        onSearchDateTypeChange={(value) =>
          handleSelectChange(value, 'searchDateType')
        }
        onSubmit={formik.handleSubmit}
        onDateChange={handleDateChange}
        status={formik.values.status}
        onChangeStatus={(value) => handleSelectChange(value, 'status')}
        languageCode={formik.values.languageCode}
        onLanguageCodeChange={(value) =>
          handleSelectChange(value, 'languageCode')
        }
      />
      <Divider className="admin-divider" />
      {searchResults.length ? (
        <Card className="admin-card">
          <h2>Search Results: </h2>
          <Divider className="admin-divider" />
          <Table
            className="admin-table"
            dataSource={searchResults}
            columns={projectsColumns}
          ></Table>
        </Card>
      ) : formik.submitCount > 0 ? (
        <Empty />
      ) : null}
      <Divider className="admin-divider" />
      <Link to="/admin/projects/create">
        <Button
          type="ghost"
          color="#3BB46C"
          className="admin-success-button"
          block
          size="large"
        >
          Create new project
        </Button>
      </Link>
      <Divider className="admin-divider" />
      <Table
        dataSource={projects}
        columns={projectsColumns}
        className="admin-table"
        pagination={{
          total: totalProjects,
          onChange: handlePaginationChange,
          current: page + 1,
          pageSize: 20
        }}
      />
    </>
  );
};
