import { Card, Select, DatePicker, Input } from 'antd';
import { Moment } from 'moment';
import { ChangeEvent } from 'react';
import styles from '../index.module.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;

interface SearchBoxProps {
  field: string;
  onFieldChange: (value: string) => void;
  onDateChange: (
    date: [Moment, Moment] | any,
    dateString: [string, string]
  ) => void;
  search: string;
  onSearchChange: (e: ChangeEvent) => void;
  onSubmit: () => void;
}

export const SearchBox: React.FC<SearchBoxProps> = ({
  field,
  onFieldChange,
  onDateChange,
  search,
  onSearchChange,
  onSubmit
}) => {
  return (
    <Card size="small" className="admin-card">
      <div className={styles.searchContainer}>
        <div className={styles.container}>
          <h3>Search by: </h3>
          <Select
            className={styles.select}
            id="field"
            onChange={onFieldChange}
            value={field}
          >
            <Option value="firstName">First Name</Option>
            <Option value="email">Email</Option>
            <Option value="country">Country</Option>
          </Select>
        </div>
        <div className={styles.container}>
          <h3>Date range: </h3>
          <RangePicker onChange={onDateChange} className={styles.date} />
        </div>
        <div className={styles.container}>
          <h3>Query: </h3>
          <Search
            value={search}
            id="search"
            name="search"
            onChange={onSearchChange}
            onSearch={onSubmit}
          />
        </div>
      </div>
    </Card>
  );
};
