const messages = {
  required: 'This field is required',
  email: {
    invalid: 'You entered an invalid email'
  },
  password: {
    invalid:
      'Must contain at least 6 characters, one uppercase, one lowercase, one number and one special case character (@$!-%*#?&)',
    notMatch: 'Passwords must match'
  },
  name: {
    first: {
      invalid: 'Invalid first name'
    },
    last: {
      invalid: 'Invalid last name'
    }
  },
  phone: {
    invalid: 'Invalid phone number'
  },
  terms: {
    invalid: 'You must accept the terms'
  }
};

export default messages;
