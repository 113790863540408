import * as Yup from 'yup';
import regexp from './regexp';
import messages from './messages';

export const string = {
  default: Yup.string(),
  required: Yup.string().required(messages.required),
  nullable: Yup.string().nullable()
};

export const firstName = Yup.string()
  .required(messages.required)
  .matches(regexp.name, messages.name.first.invalid);

export const lastName = Yup.string()
  .required(messages.required)
  .matches(regexp.name, messages.name.last.invalid);

export const phone = Yup.string()
  .required(messages.required)
  .min(7, messages.phone.invalid);

export const email = Yup.string()
  .required(messages.required)
  .matches(regexp.email, messages.email.invalid);

export const password = Yup.string()
  .required(messages.required)
  .matches(regexp.password, messages.password.invalid);

export const repeatPassword = Yup.string()
  .required(messages.required)
  .test('repeat', messages.password.notMatch, function (value) {
    return this.parent.password === value;
  });

export const terms = Yup.boolean().isTrue(messages.terms.invalid);
