import { Card, Divider } from 'antd';
import { ImagesUploaderMultiple } from 'pages/Admin/components/ImagesUploaderMultiple';
import { ImageUploaderSingle } from 'pages/Admin/components/ImagesUploaderSingle';
import { getProjectById } from 'pages/Admin/projects.api';
import { getImageLink } from 'pages/Admin/utils/get-image-link';
import { useEffect, useState } from 'react';

import styles from '../../../index.module.scss';

interface DefaultImage {
  uid: string;
  name: string;
  status: string;
  url: string;
}

const imagesToDefaultImages = (images: Array<string>) => {
  if (!images || !images.length) return [];
  return images.map((image, i) => ({
    uid: String(i),
    name: String(i),
    status: 'done',
    key: image,
    response: '201 Created',
    url: getImageLink(image),
    thumbUrl: getImageLink(image)
  }));
};

interface Props {
  id: string;
}

export const ProjectImagesForm: React.FC<Props> = ({ id }) => {
  const [defaultProjectImage, setDefaultProjectImage] = useState('');
  const [defaultDescriptionImages, setDefaultDescriptionImages] = useState<
    Array<DefaultImage>
  >([]);

  useEffect(() => {
    (async () => {
      let {
        data: { descriptionImages, projectImage }
      } = await getProjectById(id);

      projectImage = projectImage ? projectImage : '';

      setDefaultDescriptionImages(imagesToDefaultImages(descriptionImages));
      setDefaultProjectImage(projectImage);
    })();
  }, [id]);

  return (
    <>
      <Card className="admin-card">
        <div className={styles.flex}>
          <div className={styles.part}>
            <h3>Main Photo</h3>
            {defaultProjectImage.length ? (
              <ImageUploaderSingle
                id={id}
                defaultProjectImage={defaultProjectImage}
                className="admin-image-uploader-main"
              />
            ) : null}
            {!defaultProjectImage.length ? (
              <ImageUploaderSingle
                id={id}
                defaultProjectImage={''}
                className="admin-image-uploader-main"
              />
            ) : null}
          </div>
          <div className={styles.part}>
            <h3>Photos</h3>

            <div className={styles.uploader}>
              {defaultDescriptionImages.length ? (
                <ImagesUploaderMultiple
                  id={id}
                  defaultFileList={defaultDescriptionImages}
                />
              ) : null}
            </div>
            <div className={styles.uploader}>
              {!defaultDescriptionImages.length ? (
                <ImagesUploaderMultiple id={id} defaultFileList={[]} />
              ) : null}
            </div>
          </div>
        </div>
      </Card>
      <Divider />
    </>
  );
};
