const name = /^[a-zA-Zа-яёґєіїА-ЯЁҐЄІЇ\s]+$/;
const password =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\-$%^&*])(?=.{6,})/;
const email =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const regexp = {
  name,
  password,
  email
};

export default regexp;
