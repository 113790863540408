import forge from 'node-forge';
import { StrKey } from 'stellar-sdk';

const sixteenBytes = '________________';
const eightBytes = '________';

function formatPassword(pass: string) {
    const keySize = 16;
    const key = forge.pkcs5.pbkdf2(pass, eightBytes, 1000, keySize);
    return key;
}

export const validSeed = (seed: string) => StrKey.isValidEd25519SecretSeed(seed);

export const encrypt = (payload: string, password: string) => {
    const key = formatPassword(password);

    const secretKey = forge.util.createBuffer(payload);

    const cipher = forge.cipher.createCipher('AES-CBC', key);

    cipher.start({ iv: sixteenBytes });
    cipher.update(secretKey);
    cipher.finish();

    const encrypted = cipher.output;

    const ciphertextHEX = encrypted.toHex();

    return ciphertextHEX;
}

export const decrypt = (cipherHEX: string, password: string) => {
    const key = formatPassword(password);

    const cipherText = forge.util.hexToBytes(cipherHEX);

    const decipher = forge.cipher.createDecipher('AES-CBC', key);

    decipher.start({ iv: sixteenBytes });
    decipher.update(forge.util.createBuffer(cipherText));

    const result = decipher.finish();

    if (result) {
        return decipher.output.data; 
    } else {
        console.error("Decryption failed");
        return null;
    }
}