import httpClient from 'http-client';

type GetAllUsersConfig = {
  page?: number;
  field?: string;
  search?: string;
  startDate?: string;
  endDate?: string;
  limit?: number;
};

export const getAllUsers = (config: GetAllUsersConfig) => {
  return httpClient.get('/admin-accounts', {
    params: {
      ...config
    }
  });
};

export const getUserById = (id: string) => {
  return httpClient.get(`/admin-accounts/${id}`);
};

export const getUserDocumentsById = (id: string) => {
  return httpClient.get(`/admin-accounts/${id}/documents`);
};

export const banUserById = (id: string, isBanned: boolean) => {
  return httpClient.put(`/admin-accounts/${id}/ban`, {
    isBanned
  });
};

export enum KycStatus {
  NotInitialized = 'not-initialized',
  Considered = 'consider',
  Clear = 'clear'
}

export const changeUserStatusById = (id: string, kycStatus: KycStatus) => {
  return httpClient.put(`/admin-accounts/status/${id}`, {
    kycStatus
  });
};
