import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { confirmEmail } from 'actions';
import smartlands from 'assets/icons/smartlands.svg';
import Button from 'components/common/Button';
import styles from './index.module.scss';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Confirmation: React.FC = () => {
  const { token }: { token: string } = useParams();
  const [loading, setLoading] = useState<Boolean>(true);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(confirmEmail(token))
      .then(() => {
        setLoading(false);
      })
      .catch(e => {
        if (typeof e.response.data.message === 'string') {
          toast.error(e.response.data.message);
        }
        history.push('/');
      });
  }, [dispatch, history, token]);

  return loading ? null : (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.title}>{t('login:emailConfirmedTitle')}</div>
        <img className={styles.icon} src={smartlands} alt='' />
        <div className={styles.green}>{t('login:emailConfirmedSubtitle')}</div>
        <div className={styles.text}>{t('login:emailConfirmedText')}</div>
        <Button text={t('common:backToSite')} type='secondary' className={styles.button} to='/' />
      </div>
    </div>
  );
};

export default Confirmation;
