import * as React from 'react';
import Input from 'components/common/Input';
import cls from 'classnames';
import Button from 'components/common/Button';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

interface FormProps {
  formik: any;
}

const Form: React.FC<FormProps> = ({ formik }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}> {t('login:registration')}</div>
      {((formik.touched.password && formik.errors.password) ||
        (formik.touched.repeatPassword && formik.errors.repeatPassword)) && (
        <div className={styles.errorsMobile}>
          {formik.touched.password && formik.errors.password && (
            <div className={styles.errorMobile}>
              <div className={styles.circle} />
              <div>{formik.errors.password}</div>
            </div>
          )}
          {formik.touched.repeatPassword && formik.errors.repeatPassword && (
            <div className={styles.errorMobile}>
              <div className={styles.circle} />
              <div>{formik.errors.repeatPassword}</div>
            </div>
          )}
        </div>
      )}
      <div className={styles.inputWrapper}>
        <Input
          autoFocus
          id='password'
          name='password'
          className={cls(styles.input, {
            [styles.inputError]: formik.touched.password && formik.errors.password,
          })}
          type='password'
          placeholder={t('common:password')}
          value={formik.values.password}
          onBlur={formik.handleBlur}
          handleChange={formik.handleChange}
        />
        {formik.touched.password && formik.errors.password && (
          <div className={styles.error}>
            <div className={styles.circle} />
            <div>{formik.errors.password}</div>
          </div>
        )}
      </div>
      <div className={styles.inputWrapper}>
        <Input
          id='repeatPassword'
          name='repeatPassword'
          className={cls(styles.input, {
            [styles.inputError]: formik.touched.repeatPassword && formik.errors.repeatPassword,
          })}
          type='password'
          placeholder={t('common:repeatPassword')}
          value={formik.values.repeatPassword}
          onBlur={formik.handleBlur}
          handleChange={formik.handleChange}
        />
        {formik.touched.repeatPassword && formik.errors.repeatPassword && (
          <div className={styles.error}>
            <div className={styles.circle} />
            <div>{formik.errors.repeatPassword}</div>
          </div>
        )}
      </div>
      <Button
        text={t('login:register')}
        type='secondary'
        className={styles.button}
        disabled={formik.isSubmitting}
        loading={formik.isSubmitting}
        onClick={formik.handleSubmit}
      />
    </div>
  );
};

export default Form;
