import * as React from 'react';
import { Link } from 'react-router-dom';
import cls from 'classnames';
import styles from './index.module.scss';
import { LoadingOutlined } from '@ant-design/icons';

interface ButtonProps {
  to?: string | { pathname: string };
  text: string;
  size?: 'lg' | 'md' | 'sm';
  type?: 'primary' | 'secondary' | 'error';
  inverted?: boolean;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  target?: '_blank' | '_self';
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  to = '',
  onClick,
  size = 'lg',
  text,
  className,
  type = 'primary',
  inverted,
  disabled,
  loading,
  target = '_self'
}) => {
  return onClick ? (
    <button
      className={cls(
        styles.primary,
        styles[size],
        {
          [styles.inverted]: inverted,
          [styles.secondary]: type === 'secondary',
          [styles.error]: type === 'error',
          [styles.secondaryInverted]: type === 'secondary' && inverted
        },
        className
      )}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      {loading ? <LoadingOutlined /> : text}
    </button>
  ) : (
    <Link
      to={to}
      target={target}
      className={cls(
        styles.primary,
        styles[size],
        {
          [styles.inverted]: inverted,
          [styles.secondary]: type === 'secondary',
          [styles.error]: type === 'error',
          [styles.secondaryInverted]: type === 'secondary' && inverted,
          [styles.errorInverted]: type === 'error' && inverted
        },
        className
      )}
    >
      {loading ? <LoadingOutlined /> : text}
    </Link>
  );
};

export default Button;
