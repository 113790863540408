import styles from './index.module.scss';

interface Props {
  message: string;
}

const InputTooltip: React.FC<Props> = ({ message }) => {
  return (
    <div className={styles.tooltip}>
      <span>?</span>
      <div className={styles.tooltip__content}>{message}</div>
    </div>
  );
};

export default InputTooltip;
