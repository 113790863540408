import { Card, Divider, Empty, Tag } from 'antd';
import { UserDocument } from './index';

import styles from 'pages/Admin/index.module.scss';

import { FileSearchOutlined } from '@ant-design/icons';

type UserDocumentsProps = {
  documents: Array<UserDocument>;
};

export const UserDocuments: React.FC<UserDocumentsProps> = ({ documents }) => {
  return (
    <div className={styles.documents}>
      <Card
        bodyStyle={{ display: 'flex', flexDirection: 'column' }}
        className="admin-card"
      >
        <h4 className={styles.heading}>Documents</h4>
        <Divider />

        {documents.length ? (
          documents.map((document) => (
            <Tag className={styles.document}>
              <a href="document" download>
                <FileSearchOutlined />
                <span className={styles.title}>{document.name}</span>
              </a>
            </Tag>
          ))
        ) : (
          <Empty />
        )}
      </Card>
    </div>
  );
};
