import common from './common.json';
import kyc from './kyc.json';
import landing from './landing.json';
import footer from './footer.json';
import upload from './upload.json';
import profile from './profile.json';
import login from './login.json';
import project from './project.json';
import notifications from './notifications.json';
import authenticator from './google-authenticator.json';
import accreditation from './accreditation.json';

const en = {
  common,
  landing,
  footer,
  login,
  project,
  notifications,
  kyc,
  upload,
  profile,
  authenticator,
  accreditation
};

export default en;
