import { ChangeLanguageAction, ChangeCountryAction } from './language';
import {
  LoginSuccessAction,
  LogoutSuccessAction,
  GoogleAuthenticatorEnabled,
  GoogleAuthenticatorDisabled, CategorySuccessAction
} from './login';
import { SetCategoriesAction } from './offers';
import { ICurrenciesLoaded, ISetExchangeCurrency, ISetUserCurrency } from './currency';

export enum ActionTypes {
  changeLanguage,
  changeCountry,
  detectLanguage,
  setCategories,
  loginSuccess,
  logoutSuccess,
  googleAuthenticatorEnabled,
  googleAuthenticatorDisabled,
  setAllowedCurrenciesList,
  userCurrencyCode,
  setExchangeCurrency,
  userCategory
}

export type Action =
  | ChangeLanguageAction
  | ChangeCountryAction
  | SetCategoriesAction
  | LogoutSuccessAction
  | LoginSuccessAction
  | GoogleAuthenticatorEnabled
  | GoogleAuthenticatorDisabled
  | ICurrenciesLoaded
  | ISetUserCurrency
  | ISetExchangeCurrency
  | CategorySuccessAction;

export type Category = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

export type Document = {
  id: string;
  name: string;
  type: string;
  createdAt: string;
  description: string;
  key: string;
};

export type User = {
  id: string;
  email: string;
  password: string;
  avatar: string;
  isSubscribed: boolean;
  isConfirmed: boolean;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  isAllowed: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  companyStreet: string;
  companyName: string;
  jobTitle: string;
  street: string;
  postcode: string;
  town: string;
  country: string;
  companyPhoneNumber: string;
  website: string;
  companyEmail: string;
  companyPostcode: string;
  companyTown: string;
  companyCountry: string;
  kycStatus: string;
  currencyCode: string;
};

export type Offer = {
  id: string;
  projectName: string;
  projectImage: string;
  company: string;
  shortDescription: string;
  tagline: string;
  terms: string;
  video: string;
  startDate: string;
  endDate: string;
  description: string;
  documents: { url: string; name: string }[];
  descriptionImages: string[];
  raisedFunds: number;
  location: string;
  goal: number;
  status: string;
  categories: string[];
  minimumInvestment: number;
  totalInvestors: number;
  expectedReturns: string;
  currencyCode: string;
};

export const statuses = {
  COMING_SOON: { name: 'common:soon', color: '#276df1' },
  ONGOING: { name: 'common:ongoing', color: '#3bb46c' },
  PAST: { name: 'common:past', color: '#a5a5a5' }
};

export const languages: { label: string; value: string }[] = [
  {
    label: 'eng',
    value: 'en'
  },
  {
    label: 'ukr',
    value: 'uk'
  }
];

export const documentTypes: { label: string; value: string }[] = [
  {
    label: 'common:proofOfResidence',
    value: 'poa'
  },
  {
    label: 'common:other',
    value: 'other'
  },
  {
    label: 'accreditation:accreditation',
    value: 'accreditation'
  }
];

export const countries: { alpha2: string; alpha3: string; name: string }[] = [
  { alpha2: 'af', alpha3: 'AFG', name: 'Afghanistan' },
  { alpha2: 'ax', alpha3: 'ALA', name: 'Åland Islands' },
  { alpha2: 'al', alpha3: 'ALB', name: 'Albania' },
  { alpha2: 'dz', alpha3: 'DZA', name: 'Algeria' },
  { alpha2: 'as', alpha3: 'ASM', name: 'American Samoa' },
  { alpha2: 'ad', alpha3: 'AND', name: 'Andorra' },
  { alpha2: 'ao', alpha3: 'AGO', name: 'Angola' },
  { alpha2: 'ai', alpha3: 'AIA', name: 'Anguilla' },
  { alpha2: 'aq', alpha3: 'ATA', name: 'Antarctica' },
  { alpha2: 'ag', alpha3: 'ATG', name: 'Antigua and Barbuda' },
  { alpha2: 'ar', alpha3: 'ARG', name: 'Argentina' },
  { alpha2: 'am', alpha3: 'ARM', name: 'Armenia' },
  { alpha2: 'aw', alpha3: 'ABW', name: 'Aruba' },
  { alpha2: 'au', alpha3: 'AUS', name: 'Australia' },
  { alpha2: 'at', alpha3: 'AUT', name: 'Austria' },
  { alpha2: 'az', alpha3: 'AZE', name: 'Azerbaijan' },
  { alpha2: 'bs', alpha3: 'BHS', name: 'Bahamas' },
  { alpha2: 'bh', alpha3: 'BHR', name: 'Bahrain' },
  { alpha2: 'bd', alpha3: 'BGD', name: 'Bangladesh' },
  { alpha2: 'bb', alpha3: 'BRB', name: 'Barbados' },
  { alpha2: 'by', alpha3: 'BLR', name: 'Belarus' },
  { alpha2: 'be', alpha3: 'BEL', name: 'Belgium' },
  { alpha2: 'bz', alpha3: 'BLZ', name: 'Belize' },
  { alpha2: 'bj', alpha3: 'BEN', name: 'Benin' },
  { alpha2: 'bm', alpha3: 'BMU', name: 'Bermuda' },
  { alpha2: 'bt', alpha3: 'BTN', name: 'Bhutan' },
  { alpha2: 'bo', alpha3: 'BOL', name: 'Bolivia, Plurinational State of' },
  { alpha2: 'bq', alpha3: 'BES', name: 'Bonaire, Sint Eustatius and Saba' },
  { alpha2: 'ba', alpha3: 'BIH', name: 'Bosnia and Herzegovina' },
  { alpha2: 'bw', alpha3: 'BWA', name: 'Botswana' },
  { alpha2: 'bv', alpha3: 'BVT', name: 'Bouvet Island' },
  { alpha2: 'br', alpha3: 'BRA', name: 'Brazil' },
  { alpha2: 'io', alpha3: 'IOT', name: 'British Indian Ocean Territory' },
  { alpha2: 'bn', alpha3: 'BRN', name: 'Brunei Darussalam' },
  { alpha2: 'bg', alpha3: 'BGR', name: 'Bulgaria' },
  { alpha2: 'bf', alpha3: 'BFA', name: 'Burkina Faso' },
  { alpha2: 'bi', alpha3: 'BDI', name: 'Burundi' },
  { alpha2: 'kh', alpha3: 'KHM', name: 'Cambodia' },
  { alpha2: 'cm', alpha3: 'CMR', name: 'Cameroon' },
  { alpha2: 'ca', alpha3: 'CAN', name: 'Canada' },
  { alpha2: 'cv', alpha3: 'CPV', name: 'Cape Verde' },
  { alpha2: 'ky', alpha3: 'CYM', name: 'Cayman Islands' },
  { alpha2: 'cf', alpha3: 'CAF', name: 'Central African Republic' },
  { alpha2: 'td', alpha3: 'TCD', name: 'Chad' },
  { alpha2: 'cl', alpha3: 'CHL', name: 'Chile' },
  { alpha2: 'cn', alpha3: 'CHN', name: 'China' },
  { alpha2: 'cx', alpha3: 'CXR', name: 'Christmas Island' },
  { alpha2: 'cc', alpha3: 'CCK', name: 'Cocos (Keeling) Islands' },
  { alpha2: 'co', alpha3: 'COL', name: 'Colombia' },
  { alpha2: 'km', alpha3: 'COM', name: 'Comoros' },
  { alpha2: 'cg', alpha3: 'COG', name: 'Congo' },
  {
    alpha2: 'cd',
    alpha3: 'COD',
    name: 'Congo, the Democratic Republic of the'
  },
  { alpha2: 'ck', alpha3: 'COK', name: 'Cook Islands' },
  { alpha2: 'cr', alpha3: 'CRI', name: 'Costa Rica' },
  { alpha2: 'ci', alpha3: 'CIV', name: 'Côte d\'Ivoire' },
  { alpha2: 'hr', alpha3: 'HRV', name: 'Croatia' },
  { alpha2: 'cu', alpha3: 'CUB', name: 'Cuba' },
  { alpha2: 'cw', alpha3: 'CUW', name: 'Curaçao' },
  { alpha2: 'cy', alpha3: 'CYP', name: 'Cyprus' },
  { alpha2: 'cz', alpha3: 'CZE', name: 'Czech Republic' },
  { alpha2: 'dk', alpha3: 'DNK', name: 'Denmark' },
  { alpha2: 'dj', alpha3: 'DJI', name: 'Djibouti' },
  { alpha2: 'dm', alpha3: 'DMA', name: 'Dominica' },
  { alpha2: 'do', alpha3: 'DOM', name: 'Dominican Republic' },
  { alpha2: 'ec', alpha3: 'ECU', name: 'Ecuador' },
  { alpha2: 'eg', alpha3: 'EGY', name: 'Egypt' },
  { alpha2: 'sv', alpha3: 'SLV', name: 'El Salvador' },
  { alpha2: 'gq', alpha3: 'GNQ', name: 'Equatorial Guinea' },
  { alpha2: 'er', alpha3: 'ERI', name: 'Eritrea' },
  { alpha2: 'ee', alpha3: 'EST', name: 'Estonia' },
  { alpha2: 'et', alpha3: 'ETH', name: 'Ethiopia' },
  { alpha2: 'fk', alpha3: 'FLK', name: 'Falkland Islands (Malvinas)' },
  { alpha2: 'fo', alpha3: 'FRO', name: 'Faroe Islands' },
  { alpha2: 'fj', alpha3: 'FJI', name: 'Fiji' },
  { alpha2: 'fi', alpha3: 'FIN', name: 'Finland' },
  { alpha2: 'fr', alpha3: 'FRA', name: 'France' },
  { alpha2: 'gf', alpha3: 'GUF', name: 'French Guiana' },
  { alpha2: 'pf', alpha3: 'PYF', name: 'French Polynesia' },
  { alpha2: 'tf', alpha3: 'ATF', name: 'French Southern Territories' },
  { alpha2: 'ga', alpha3: 'GAB', name: 'Gabon' },
  { alpha2: 'gm', alpha3: 'GMB', name: 'Gambia' },
  { alpha2: 'ge', alpha3: 'GEO', name: 'Georgia' },
  { alpha2: 'de', alpha3: 'DEU', name: 'Germany' },
  { alpha2: 'gh', alpha3: 'GHA', name: 'Ghana' },
  { alpha2: 'gi', alpha3: 'GIB', name: 'Gibraltar' },
  { alpha2: 'gr', alpha3: 'GRC', name: 'Greece' },
  { alpha2: 'gl', alpha3: 'GRL', name: 'Greenland' },
  { alpha2: 'gd', alpha3: 'GRD', name: 'Grenada' },
  { alpha2: 'gp', alpha3: 'GLP', name: 'Guadeloupe' },
  { alpha2: 'gu', alpha3: 'GUM', name: 'Guam' },
  { alpha2: 'gt', alpha3: 'GTM', name: 'Guatemala' },
  { alpha2: 'gg', alpha3: 'GGY', name: 'Guernsey' },
  { alpha2: 'gn', alpha3: 'GIN', name: 'Guinea' },
  { alpha2: 'gw', alpha3: 'GNB', name: 'Guinea-Bissau' },
  { alpha2: 'gy', alpha3: 'GUY', name: 'Guyana' },
  { alpha2: 'ht', alpha3: 'HTI', name: 'Haiti' },
  { alpha2: 'hm', alpha3: 'HMD', name: 'Heard Island and McDonald Islands' },
  { alpha2: 'va', alpha3: 'VAT', name: 'Holy See (Vatican City State)' },
  { alpha2: 'hn', alpha3: 'HND', name: 'Honduras' },
  { alpha2: 'hk', alpha3: 'HKG', name: 'Hong Kong' },
  { alpha2: 'hu', alpha3: 'HUN', name: 'Hungary' },
  { alpha2: 'is', alpha3: 'ISL', name: 'Iceland' },
  { alpha2: 'in', alpha3: 'IND', name: 'India' },
  { alpha2: 'id', alpha3: 'IDN', name: 'Indonesia' },
  { alpha2: 'ir', alpha3: 'IRN', name: 'Iran, Islamic Republic of' },
  { alpha2: 'iq', alpha3: 'IRQ', name: 'Iraq' },
  { alpha2: 'ie', alpha3: 'IRL', name: 'Ireland' },
  { alpha2: 'im', alpha3: 'IMN', name: 'Isle of Man' },
  { alpha2: 'il', alpha3: 'ISR', name: 'Israel' },
  { alpha2: 'it', alpha3: 'ITA', name: 'Italy' },
  { alpha2: 'jm', alpha3: 'JAM', name: 'Jamaica' },
  { alpha2: 'jp', alpha3: 'JPN', name: 'Japan' },
  { alpha2: 'je', alpha3: 'JEY', name: 'Jersey' },
  { alpha2: 'jo', alpha3: 'JOR', name: 'Jordan' },
  { alpha2: 'kz', alpha3: 'KAZ', name: 'Kazakhstan' },
  { alpha2: 'ke', alpha3: 'KEN', name: 'Kenya' },
  { alpha2: 'ki', alpha3: 'KIR', name: 'Kiribati' },
  {
    alpha2: 'kp',
    alpha3: 'PRK',
    name: 'Korea, Democratic People\'s Republic of'
  },
  { alpha2: 'kr', alpha3: 'KOR', name: 'Korea, Republic of' },
  { alpha2: 'xk', alpha3: 'XKX', name: 'Kosovo' },
  { alpha2: 'kw', alpha3: 'KWT', name: 'Kuwait' },
  { alpha2: 'kg', alpha3: 'KGZ', name: 'Kyrgyzstan' },
  { alpha2: 'la', alpha3: 'LAO', name: 'Lao People\'s Democratic Republic' },
  { alpha2: 'lv', alpha3: 'LVA', name: 'Latvia' },
  { alpha2: 'lb', alpha3: 'LBN', name: 'Lebanon' },
  { alpha2: 'ls', alpha3: 'LSO', name: 'Lesotho' },
  { alpha2: 'lr', alpha3: 'LBR', name: 'Liberia' },
  { alpha2: 'ly', alpha3: 'LBY', name: 'Libya' },
  { alpha2: 'li', alpha3: 'LIE', name: 'Liechtenstein' },
  { alpha2: 'lt', alpha3: 'LTU', name: 'Lithuania' },
  { alpha2: 'lu', alpha3: 'LUX', name: 'Luxembourg' },
  { alpha2: 'mo', alpha3: 'MAC', name: 'Macao' },
  {
    alpha2: 'mk',
    alpha3: 'MKD',
    name: 'Macedonia, the Former Yugoslav Republic of'
  },
  { alpha2: 'mg', alpha3: 'MDG', name: 'Madagascar' },
  { alpha2: 'mw', alpha3: 'MWI', name: 'Malawi' },
  { alpha2: 'my', alpha3: 'MYS', name: 'Malaysia' },
  { alpha2: 'mv', alpha3: 'MDV', name: 'Maldives' },
  { alpha2: 'ml', alpha3: 'MLI', name: 'Mali' },
  { alpha2: 'mt', alpha3: 'MLT', name: 'Malta' },
  { alpha2: 'mh', alpha3: 'MHL', name: 'Marshall Islands' },
  { alpha2: 'mq', alpha3: 'MTQ', name: 'Martinique' },
  { alpha2: 'mr', alpha3: 'MRT', name: 'Mauritania' },
  { alpha2: 'mu', alpha3: 'MUS', name: 'Mauritius' },
  { alpha2: 'yt', alpha3: 'MYT', name: 'Mayotte' },
  { alpha2: 'mx', alpha3: 'MEX', name: 'Mexico' },
  { alpha2: 'fm', alpha3: 'FSM', name: 'Micronesia, Federated States of' },
  { alpha2: 'md', alpha3: 'MDA', name: 'Moldova, Republic of' },
  { alpha2: 'mc', alpha3: 'MCO', name: 'Monaco' },
  { alpha2: 'mn', alpha3: 'MNG', name: 'Mongolia' },
  { alpha2: 'me', alpha3: 'MNE', name: 'Montenegro' },
  { alpha2: 'ms', alpha3: 'MSR', name: 'Montserrat' },
  { alpha2: 'ma', alpha3: 'MAR', name: 'Morocco' },
  { alpha2: 'mz', alpha3: 'MOZ', name: 'Mozambique' },
  { alpha2: 'mm', alpha3: 'MMR', name: 'Myanmar' },
  { alpha2: 'na', alpha3: 'NAM', name: 'Namibia' },
  { alpha2: 'nr', alpha3: 'NRU', name: 'Nauru' },
  { alpha2: 'np', alpha3: 'NPL', name: 'Nepal' },
  { alpha2: 'nl', alpha3: 'NLD', name: 'Netherlands' },
  { alpha2: 'nc', alpha3: 'NCL', name: 'New Caledonia' },
  { alpha2: 'nz', alpha3: 'NZL', name: 'New Zealand' },
  { alpha2: 'ni', alpha3: 'NIC', name: 'Nicaragua' },
  { alpha2: 'ne', alpha3: 'NER', name: 'Niger' },
  { alpha2: 'ng', alpha3: 'NGA', name: 'Nigeria' },
  { alpha2: 'nu', alpha3: 'NIU', name: 'Niue' },
  { alpha2: 'nf', alpha3: 'NFK', name: 'Norfolk Island' },
  { alpha2: 'mp', alpha3: 'MNP', name: 'Northern Mariana Islands' },
  { alpha2: 'no', alpha3: 'NOR', name: 'Norway' },
  { alpha2: 'om', alpha3: 'OMN', name: 'Oman' },
  { alpha2: 'pk', alpha3: 'PAK', name: 'Pakistan' },
  { alpha2: 'pw', alpha3: 'PLW', name: 'Palau' },
  { alpha2: 'ps', alpha3: 'PSE', name: 'Palestine, State of' },
  { alpha2: 'pa', alpha3: 'PAN', name: 'Panama' },
  { alpha2: 'pg', alpha3: 'PNG', name: 'Papua New Guinea' },
  { alpha2: 'py', alpha3: 'PRY', name: 'Paraguay' },
  { alpha2: 'pe', alpha3: 'PER', name: 'Peru' },
  { alpha2: 'ph', alpha3: 'PHL', name: 'Philippines' },
  { alpha2: 'pn', alpha3: 'PCN', name: 'Pitcairn' },
  { alpha2: 'pl', alpha3: 'POL', name: 'Poland' },
  { alpha2: 'pt', alpha3: 'PRT', name: 'Portugal' },
  { alpha2: 'pr', alpha3: 'PRI', name: 'Puerto Rico' },
  { alpha2: 'qa', alpha3: 'QAT', name: 'Qatar' },
  { alpha2: 're', alpha3: 'REU', name: 'Réunion' },
  { alpha2: 'ro', alpha3: 'ROU', name: 'Romania' },
  { alpha2: 'ru', alpha3: 'RUS', name: 'Russian Federation' },
  { alpha2: 'rw', alpha3: 'RWA', name: 'Rwanda' },
  { alpha2: 'bl', alpha3: 'BLM', name: 'Saint Barthélemy' },
  {
    alpha2: 'sh',
    alpha3: 'SHN',
    name: 'Saint Helena, Ascension and Tristan da Cunha'
  },
  { alpha2: 'kn', alpha3: 'KNA', name: 'Saint Kitts and Nevis' },
  { alpha2: 'lc', alpha3: 'LCA', name: 'Saint Lucia' },
  { alpha2: 'mf', alpha3: 'MAF', name: 'Saint Martin (French part)' },
  { alpha2: 'pm', alpha3: 'SPM', name: 'Saint Pierre and Miquelon' },
  { alpha2: 'vc', alpha3: 'VCT', name: 'Saint Vincent and the Grenadines' },
  { alpha2: 'ws', alpha3: 'WSM', name: 'Samoa' },
  { alpha2: 'sm', alpha3: 'SMR', name: 'San Marino' },
  { alpha2: 'st', alpha3: 'STP', name: 'Sao Tome and Principe' },
  { alpha2: 'sa', alpha3: 'SAU', name: 'Saudi Arabia' },
  { alpha2: 'sn', alpha3: 'SEN', name: 'Senegal' },
  { alpha2: 'rs', alpha3: 'SRB', name: 'Serbia' },
  { alpha2: 'sc', alpha3: 'SYC', name: 'Seychelles' },
  { alpha2: 'sl', alpha3: 'SLE', name: 'Sierra Leone' },
  { alpha2: 'sg', alpha3: 'SGP', name: 'Singapore' },
  { alpha2: 'sx', alpha3: 'SXM', name: 'Sint Maarten (Dutch part)' },
  { alpha2: 'sk', alpha3: 'SVK', name: 'Slovakia' },
  { alpha2: 'si', alpha3: 'SVN', name: 'Slovenia' },
  { alpha2: 'sb', alpha3: 'SLB', name: 'Solomon Islands' },
  { alpha2: 'so', alpha3: 'SOM', name: 'Somalia' },
  { alpha2: 'za', alpha3: 'ZAF', name: 'South Africa' },
  {
    alpha2: 'gs',
    alpha3: 'SGS',
    name: 'South Georgia and the South Sandwich Islands'
  },
  { alpha2: 'ss', alpha3: 'SSD', name: 'South Sudan' },
  { alpha2: 'es', alpha3: 'ESP', name: 'Spain' },
  { alpha2: 'lk', alpha3: 'LKA', name: 'Sri Lanka' },
  { alpha2: 'sd', alpha3: 'SDN', name: 'Sudan' },
  { alpha2: 'sr', alpha3: 'SUR', name: 'Suriname' },
  { alpha2: 'sj', alpha3: 'SJM', name: 'Svalbard and Jan Mayen' },
  { alpha2: 'sz', alpha3: 'SWZ', name: 'Swaziland' },
  { alpha2: 'se', alpha3: 'SWE', name: 'Sweden' },
  { alpha2: 'ch', alpha3: 'CHE', name: 'Switzerland' },
  { alpha2: 'sy', alpha3: 'SYR', name: 'Syrian Arab Republic' },
  { alpha2: 'tw', alpha3: 'TWN', name: 'Taiwan, Province of China' },
  { alpha2: 'tj', alpha3: 'TJK', name: 'Tajikistan' },
  { alpha2: 'tz', alpha3: 'TZA', name: 'Tanzania, United Republic of' },
  { alpha2: 'th', alpha3: 'THA', name: 'Thailand' },
  { alpha2: 'tl', alpha3: 'TLS', name: 'Timor-Leste' },
  { alpha2: 'tg', alpha3: 'TGO', name: 'Togo' },
  { alpha2: 'tk', alpha3: 'TKL', name: 'Tokelau' },
  { alpha2: 'to', alpha3: 'TON', name: 'Tonga' },
  { alpha2: 'tt', alpha3: 'TTO', name: 'Trinidad and Tobago' },
  { alpha2: 'tn', alpha3: 'TUN', name: 'Tunisia' },
  { alpha2: 'tr', alpha3: 'TUR', name: 'Turkey' },
  { alpha2: 'tm', alpha3: 'TKM', name: 'Turkmenistan' },
  { alpha2: 'tc', alpha3: 'TCA', name: 'Turks and Caicos Islands' },
  { alpha2: 'tv', alpha3: 'TUV', name: 'Tuvalu' },
  { alpha2: 'ug', alpha3: 'UGA', name: 'Uganda' },
  { alpha2: 'ua', alpha3: 'UKR', name: 'Ukraine' },
  { alpha2: 'ae', alpha3: 'ARE', name: 'United Arab Emirates' },
  { alpha2: 'gb', alpha3: 'GBR', name: 'United Kingdom' },
  { alpha2: 'us', alpha3: 'USA', name: 'United States' },
  { alpha2: 'um', alpha3: 'UMI', name: 'United States Minor Outlying Islands' },
  { alpha2: 'uy', alpha3: 'URY', name: 'Uruguay' },
  { alpha2: 'uz', alpha3: 'UZB', name: 'Uzbekistan' },
  { alpha2: 'vu', alpha3: 'VUT', name: 'Vanuatu' },
  { alpha2: 've', alpha3: 'VEN', name: 'Venezuela, Bolivarian Republic of' },
  { alpha2: 'vn', alpha3: 'VNM', name: 'Viet Nam' },
  { alpha2: 'vg', alpha3: 'VGB', name: 'Virgin Islands, British' },
  { alpha2: 'vi', alpha3: 'VIR', name: 'Virgin Islands, U.S.' },
  { alpha2: 'wf', alpha3: 'WLF', name: 'Wallis and Futuna' },
  { alpha2: 'eh', alpha3: 'ESH', name: 'Western Sahara' },
  { alpha2: 'ye', alpha3: 'YEM', name: 'Yemen' },
  { alpha2: 'zm', alpha3: 'ZMB', name: 'Zambia' },
  { alpha2: 'zw', alpha3: 'ZWE', name: 'Zimbabwe' }
];
