import React from 'react';
import { withRouter } from 'react-router-dom';

class GoogleAnalytics extends React.Component {
  // @ts-ignore
  componentWillUpdate ({ location, history }) {
  // @ts-ignore
    const gtag = window.gtag;
    const {REACT_APP_GA_ID} = process.env;

  // @ts-ignore
    if (location.pathname === this.props.location.pathname) {
      return;
    }

    if (history.action === 'PUSH' &&
      typeof(gtag) === 'function') {
      gtag('config', REACT_APP_GA_ID, {
        'page_title': document.title,
        'page_location': window.location.href,
        'page_path': location.pathname
      });
    }
  }

  render () {
    return null;
  }
}

  // @ts-ignore
export default withRouter(GoogleAnalytics);