import { parse } from 'psl';

export function getDomain(): string {
    const url = window.location.host;
    const parsed = parse(url);
    if(!parsed.error) {
        console.log(`DOMAIN: ${parsed.domain}`);
        if (parsed.tld === "cloudfront.net") {
            return '.cloudfront.net';
        }
        return `.${parsed.domain}`;
    } else {
        console.log("DOMAIN: local");
        return '';
    }
}