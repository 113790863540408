import { Dispatch } from 'redux';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { ActionTypes } from './types';
import { toast } from 'react-toastify';
import i18n from 'i18next';

const cookies = new Cookies();
const { REACT_APP_API, REACT_APP_WALLET_API } = process.env;

export type IAllowedCurrency = {
  code: 'string',
  name: 'string'
}

export type IExchangeCurrency = {
  [key: string]: any,
}

export interface ICurrenciesState {
  allowedCurrenciesList: IAllowedCurrency[];
  exchangeCurrency: IExchangeCurrency;
}

export interface ISetExchangeCurrency {
  type: ActionTypes.setExchangeCurrency;
  exchangeCurrency: IExchangeCurrency;
}


export interface ICurrenciesLoaded {
  type: ActionTypes.setAllowedCurrenciesList;
  allowedCurrenciesList: IAllowedCurrency[];
}

export interface ISetUserCurrency {
  type: ActionTypes.userCurrencyCode;
  currencyCode: string;
}

export const currenciesLoaded = (allowedCurrenciesList: IAllowedCurrency[]): ICurrenciesLoaded => ({
  type: ActionTypes.setAllowedCurrenciesList,
  allowedCurrenciesList
});

export const getCurrencies = () => async (dispatch: Dispatch, getState: any) => {

  const url = REACT_APP_API + '/misc/currencies';
  const options = {
    headers: { Authorization: `Bearer ${cookies.get('token')}` }
  };

  const response = await axios.get(url, options);
  dispatch(currenciesLoaded(response.data));

};

export const setExchangeRate = (exchangeCurrency: IExchangeCurrency): ISetExchangeCurrency => ({
  type: ActionTypes.setExchangeCurrency,
  exchangeCurrency
});

export const getExchangeRate = (currentCurrency: string, allowedCurrencies: string) => async (dispatch: Dispatch) => {

  const url = REACT_APP_WALLET_API + `/exchange-rates?currencyCode=${currentCurrency}&rateCodes=${allowedCurrencies}`;

  const options = {
    headers: { Authorization: `Bearer ${cookies.get('token')}` }
  };

  const response = await axios.get(url, options);

  if (response.status === 200) {
    dispatch(setExchangeRate({[currentCurrency]: response.data}));
  }
};

export const setUserCurrency = (currencyCode: string): ISetUserCurrency => ({
  type: ActionTypes.userCurrencyCode,
  currencyCode
});

export const changeUserCurrency = (id: string, code: string) => async (dispatch: Dispatch, getState: any) => {

  const url = REACT_APP_API + `/profile/${id}`;
  const options = {
    headers: { Authorization: `Bearer ${cookies.get('token')}` }
  };

  try {
    const response = await axios.put(url, {
      currencyCode: code
    }, options);
    if (response.status === 200) {
      toast.success(i18n.t('profile:currencyChangedSuccessfully'));
    }

    dispatch(setUserCurrency(response.data.currencyCode));

  } catch (e) {
    toast.error(i18n.t('profile:currencyChangedFailed'));
    console.log('CHANGE CURRENCY ERROR', e);
  }
};
