import * as React from 'react';
import cls from 'classnames';
import styles from './index.module.scss';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

interface InputProps {
  value: string | number;
  placeholder: string;
  min?: number;
  type?: string;
  className?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  id?: string;
  name?: string;
  disabled?: boolean;
  autoFocus?: boolean;
}

const Input: React.FC<InputProps> = ({
  value = '',
  className,
  placeholder = '',
  id,
  min,
  name,
  disabled = false,
  type = 'text',
  handleChange,
  onBlur,
  autoFocus,
}) => {
  const [showPassword, toggleShowPassword] = React.useState<Boolean>(false);
  const togglePasswordVisiblity = () => {
    toggleShowPassword(showPassword ? false : true);
  };
  return (
    <div className={styles.wrapper}>
      <input
        autoFocus={autoFocus}
        name={name}
        min={min}
        disabled={disabled}
        id={id}
        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
        placeholder={placeholder}
        className={cls(styles.input, className, { [styles.disabled]: disabled })}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
      />
      {type === 'password' && (
        <div onClick={togglePasswordVisiblity} className={styles.icon}>
          {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        </div>
      )}
    </div>
  );
};

export default Input;
