import { Card, Divider, Select } from 'antd';
import { useState } from 'react';
import styles from 'pages/Admin/index.module.scss';
import { DocumentsUploader } from 'pages/Admin/components/DocumentsUploader';

const { Option } = Select;

interface Props {
  id: string;
}

export const ProjectDocumentsForm: React.FC<Props> = ({ id }) => {
  const [languageCode, setLanguageCode] = useState('en');

  return (
    <>
      <Card>
        <div className={styles.flex}>
          <div className={styles.part}>
            <h3>Documents</h3>
            <div className={styles.documents}>
              <Select
                id="langaugeCode"
                className={styles.select}
                value={languageCode}
                onChange={(value: string) => setLanguageCode(value)}
              >
                <Option value="en">EN</Option>
                <Option value="uk">UA</Option>
              </Select>
            </div>
            <DocumentsUploader languageCode={languageCode} id={id} />
          </div>
        </div>
      </Card>
      <Divider />
    </>
  );
};
