import { Card, Divider, Select } from 'antd';
import { DocumentsUploader } from 'pages/Admin/components/DocumentsUploader';
import { getProjectDocumentsById } from 'pages/Admin/projects.api';
import { getImageLink } from 'pages/Admin/utils/get-image-link';
import { useEffect, useState } from 'react';
import styles from 'pages/Admin/index.module.scss';

const { Option } = Select;

const documentsToDefaultDocuments = (documents: Array<any>) => {
  if (!documents || !documents.length) return [];

  return documents.map((document) => ({
    uid: document.id,
    key: document.key,
    status: 'done',
    url: getImageLink(document.key),
    name: document.name
  }));
};

interface Props {
  id: string;
}

export const ProjectDocumentsForm: React.FC<Props> = ({ id }) => {
  const [languageCode, setLanguageCode] = useState('en');
  const [defaultDocuments, setDefaultDocuments] = useState<Array<any>>([]);

  useEffect(() => {
    (async () => {
      let { data } = await getProjectDocumentsById(id, languageCode);

      const documents = data;
      console.log({ documents });
      setDefaultDocuments(documentsToDefaultDocuments(documents));
    })();
  }, [id, languageCode]);

  return (
    <>
      <Card className="admin-card">
        <div className={styles.flex}>
          <div className={styles.part}>
            <h3>Documents</h3>
            <div className={styles.documents}>
              <Select
                id="langaugeCode"
                className={styles.select}
                value={languageCode}
                onChange={(value: string) => setLanguageCode(value)}
              >
                <Option value="en">EN</Option>
                <Option value="uk">UA</Option>
              </Select>
            </div>
            {defaultDocuments.length ? (
              <DocumentsUploader
                languageCode={languageCode}
                id={id}
                defaultFileList={defaultDocuments}
              />
            ) : null}

            {!defaultDocuments.length ? (
              <DocumentsUploader languageCode={languageCode} id={id} />
            ) : null}
          </div>
        </div>
      </Card>
      <Divider />
    </>
  );
};
