import * as Yup from 'yup';

const THIS_FIELD_IS_REQUIRED = 'This field is required';
const SELECT_AT_LEAST_ONE_CATEOGORY = 'Select at least one category';

export const ProjectSchema = Yup.object().shape({
  goal: Yup.number().required(THIS_FIELD_IS_REQUIRED),
  minimumInvestment: Yup.number().required(THIS_FIELD_IS_REQUIRED),
  expectedReturns: Yup.number().required(THIS_FIELD_IS_REQUIRED),
  website: Yup.string(),
  startDate: Yup.string().required(THIS_FIELD_IS_REQUIRED).nullable(),
  endDate: Yup.string().required(THIS_FIELD_IS_REQUIRED).nullable(),
  categoryIds: Yup.array()
    .min(1, SELECT_AT_LEAST_ONE_CATEOGORY)
    .required(THIS_FIELD_IS_REQUIRED),
  tokenPrice: Yup.string().required(THIS_FIELD_IS_REQUIRED),
  issuer: Yup.string().required(THIS_FIELD_IS_REQUIRED),
  ticker: Yup.string().required(THIS_FIELD_IS_REQUIRED)
});

export const TranslationFieldsSchema = Yup.object().shape({
  projectName: Yup.string().required(THIS_FIELD_IS_REQUIRED),
  location: Yup.string().required(THIS_FIELD_IS_REQUIRED),
  company: Yup.string().nullable(),
  tagline: Yup.string().nullable(),
  shortDescription: Yup.string().required(THIS_FIELD_IS_REQUIRED).nullable(),
  terms: Yup.string().required(THIS_FIELD_IS_REQUIRED),
  description: Yup.string().required(THIS_FIELD_IS_REQUIRED)
});

export const ProjectCreatingSchema = Yup.object().shape({
  ...ProjectSchema.fields,
  translationFields: TranslationFieldsSchema
});
