import { CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import { documentTypes, Document } from 'actions';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { Tooltip } from 'antd';

const { REACT_APP_API } = process.env;
//const REACT_APP_API = "http://localhost:3000/api";

export interface DocumentProps {
  document: Document;
  handleDelete: (id: string) => void;
}

const DocumentItem: React.FC<DocumentProps> = ({ document, handleDelete }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Tooltip title={document.name}>
        <div className={styles.name}>{document.name}</div>
      </Tooltip>
      <Tooltip title={t(documentTypes.find((item: { value: string }) => item.value === document.type)?.label || '')}>
        <div>{t(documentTypes.find((item: { value: string }) => item.value === document.type)?.label || '')}</div>
      </Tooltip>
      <Tooltip title={document.description}>
        <div>{document.description}</div>
      </Tooltip>
      <Tooltip title={document.createdAt}>
        <div>{document.createdAt}</div>
      </Tooltip>
      <div className={styles.actions}>
        <a className={styles.link} href={REACT_APP_API + '/storage/file-stream/' + document.key} download>
          <DownloadOutlined />
          {t('upload:download')}
        </a>
        <div className={styles.remove} onClick={() => handleDelete(document.id)}>
          <CloseOutlined />
          {t('upload:remove')}
        </div>
      </div>
    </div>
  );
};

export default DocumentItem;
