import { Button, Card, Divider, Input, Select, Checkbox } from 'antd';
import { useFormik } from 'formik';
import { Error } from 'pages/Admin/components/Error';
import { HTMLEditor } from 'pages/Admin/components/HTMLEditor';
import {
  getProjectTranslationsById,
  LanguageCode,
  updateProjectTranslationById
} from 'pages/Admin/projects.api';
import { TranslationFieldsSchema } from 'pages/Admin/validations';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styles from '../../../index.module.scss';

const { Option } = Select;
const { TextArea } = Input;

interface Props {
  id: string;
}

export const ProjectTranslationsForm: React.FC<Props> = ({ id }) => {
  const [translationsObject, setTranslationsObject] = useState({
    en: {},
    uk: {}
  });

  const [languageCode, setLanguageCode] = useState(LanguageCode.EN);

  const translationFields = useFormik({
    validationSchema: TranslationFieldsSchema,
    initialValues: {
      projectName: '',
      location: '',
      company: '',
      tagline: '',
      shortDescription: '',
      terms: '',
      description: '',
      isDefault: true,
      languageCode: '',
      isVisibleCompany: false
    },
    onSubmit: async (values) => {
      const payload = { ...values };

      try {
        await updateProjectTranslationById(id, languageCode, payload);

        toast.success('Translation added');
      } catch (e: any) {
        if (e.response.data.message) {
          const errorMessages = e.response.data.message;
          for (let key in errorMessages) {
            for (let k in errorMessages[key]) {
              toast.error(errorMessages[key][k][0]);
            }
          }
        }
      }
    }
  });

  useEffect(() => {
    const getInitialData = async () => {
      const translations = await (await getProjectTranslationsById(id)).data;

      const uk =
        translations.find(
          (translation: any) => translation.languageCode === 'uk'
        ) || {};
      const en =
        translations.find(
          (translation: any) => translation.languageCode === 'en'
        ) || {};

      setTranslationsObject({
        en,
        uk
      });

      translationFields.setValues(en);
    };

    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const translation = translationsObject[languageCode] as any;

    translationFields.setValues(translation);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageCode]);

  return (
    <>
      <Card className="admin-card">
        <h1>Translation Fields</h1>
        <div className={styles.field}>
          <label className={styles.label}>Language</label>
          <Select
            className={styles.select}
            value={languageCode}
            onChange={(value) => setLanguageCode(value)}
          >
            <Option value="en">EN</Option>
            <Option value="uk">UK</Option>
          </Select>
        </div>
        <div className={styles.field}>
          <label className={styles.label}>
            <span style={{ paddingRight: '20px' }}>Is Default</span>
            <Checkbox
              id="isDefault"
              name="isDefault"
              onChange={translationFields.handleChange}
              checked={translationFields.values.isDefault}
            />
          </label>
        </div>
        <Divider />
        <div className={styles.flex}>
          <div className={styles.part}>
            <div className={styles.field}>
              <label className={styles.label}>Project Name</label>
              <Input
                id="projectName"
                name="projectName"
                value={translationFields.values.projectName}
                onChange={translationFields.handleChange}
                placeholder="Project Name"
              />
              <Error
                visible={
                  translationFields.touched.projectName &&
                  translationFields.errors.projectName
                }
                message={translationFields.errors.projectName}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Location</label>
              <Input
                id="location"
                name="location"
                value={translationFields.values.location}
                onChange={translationFields.handleChange}
                placeholder="Location"
              />
              <Error
                visible={
                  translationFields.touched.location &&
                  translationFields.errors.location
                }
                message={translationFields.errors.location}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Company</label>
              <Input
                id="company"
                name="company"
                value={translationFields.values.company}
                onChange={translationFields.handleChange}
                placeholder="Company"
              />
              <div style={{ padding: '15px 0 0' }}>
                <Checkbox
                  checked={translationFields.values.isVisibleCompany}
                  onChange={(e) =>
                    translationFields.setFieldValue(
                      'isVisibleCompany',
                      e.target.checked
                    )
                  }
                />{' '}
                <span>Publish data?</span>
              </div>
              <Error
                visible={
                  translationFields.touched.company &&
                  translationFields.errors.company
                }
                message={translationFields.errors.company}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Tagline</label>
              <Input
                id="tagline"
                name="tagline"
                value={translationFields.values.tagline}
                onChange={translationFields.handleChange}
                placeholder="Tagline"
              />
              <Error
                visible={
                  translationFields.touched.tagline &&
                  translationFields.errors.tagline
                }
                message={translationFields.errors.tagline}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Short Description</label>
              <TextArea
                id="shortDescription"
                name="shortDescription"
                placeholder="Short Description"
                onChange={translationFields.handleChange}
                value={translationFields.values.shortDescription}
              />
              <Error
                visible={
                  translationFields.touched.shortDescription &&
                  translationFields.errors.shortDescription
                }
                message={translationFields.errors.shortDescription}
              />
            </div>
          </div>
          <div className={styles.part}>
            <div className={styles.field}>
              <label className={styles.label}>Terms</label>
              <TextArea
                id="terms"
                name="terms"
                value={translationFields.values.terms}
                onChange={translationFields.handleChange}
                placeholder="Terms"
              />
              <Error
                visible={
                  translationFields.touched.terms &&
                  translationFields.errors.terms
                }
                message={translationFields.errors.terms}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Description</label>
              <HTMLEditor
                id="description"
                value={String(translationFields.values.description)}
                onChange={(e: any) => {
                  translationFields.setFieldValue('description', e);
                }}
              />
              <Error
                visible={
                  translationFields.touched.description &&
                  translationFields.errors.description
                }
                message={translationFields.errors.description}
              />
            </div>
          </div>
        </div>
        <Divider />
        <Button
          type="ghost"
          block
          className="admin-success-button"
          size="large"
          onClick={(e) => translationFields.handleSubmit()}
        >
          Submit {languageCode.toUpperCase()} Translation
        </Button>
      </Card>
    </>
  );
};
