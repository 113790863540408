import styles from '../index.module.scss';

export const Error: React.FC<{ visible?: any; message?: any }> = ({
  visible,
  message
}) => {
  if (!visible) {
    return null;
  }

  return <div className={styles.error}>{message}</div>;
};
