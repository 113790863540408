import { useEffect, useState } from 'react';
import * as Onfido from 'onfido-sdk-ui';
import { useAppDispatch } from 'store';
import { createCheck } from 'actions';

export interface OnfidoStepProps {
  token: string | undefined;
  number: string | undefined;
  applicant: string | undefined;
  handleNext: () => void;
}

const OnfidoStep: React.FC<OnfidoStepProps> = ({ token, number, applicant, handleNext }) => {
  const [onfidoInstance, setOnfidoInstance] = useState<Onfido.SdkHandle | null>(null);
  const dispatch = useAppDispatch();

  const initOnfido = async () => {
    try {
      const instance = Onfido.init({
        token,
        userDetails: {
          smsNumber: number,
        },
        customUI: {
          colorBackgroundSurfaceModal: 'transparent',
          colorBorderSurfaceModal: 'rgb(132 59 98)',
          borderWidthSurfaceModal: '0',
          borderStyleSurfaceModal: 'groove',
        },
        useModal: false,
        onComplete: async data => {
          if (applicant) {
            dispatch(createCheck(applicant))
              .then(() => handleNext())
              .catch(() => {});
            handleNext();
          }
        },
        onUserExit: () => {},
        onError: () => {},
        steps: ['document', 'face'],
      });

      setOnfidoInstance(instance);
    } catch {}
  };

  useEffect(() => {
    initOnfido();

    return () => {
      onfidoInstance && onfidoInstance.tearDown();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id='onfido-mount' />;
};

export default OnfidoStep;
