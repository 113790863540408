import styles from './index.module.scss';
import { ChangeEvent, FC, useMemo } from 'react';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { DatePicker } from 'antd';
import { serializedLineDiagramData } from '../../../../utils/serializedLineDiagramData';
import { VolumeDiagram } from '../../overview.api';

const { RangePicker } = DatePicker;

interface ILineDiagram {
  data: VolumeDiagram;
  title: string;
  lineAmount: number;
  mainLineColor: string;
  secondLineColor?: string;
  name: string;
  onChangeSelect: (e: ChangeEvent<HTMLSelectElement>) => void;
  onChangeDateRange: (dateString: [string, string], name: string) => void;
  value: string
}

const options = [
  {
    value: '1',
    optionText: '1 month'

  },
  {
    value: '3',
    optionText: '3 months'

  },
  {
    value: '6',
    optionText: '6 months'

  },
  {
    value: '12',
    optionText: '1 year'

  },
  {
    value: 'custom',
    optionText: 'custom range'

  }
];

const LineDiagram: FC<ILineDiagram> = (
  {
    data,
    title,
    lineAmount,
    mainLineColor,
    secondLineColor,
    name,
    onChangeSelect,
    value,
    onChangeDateRange
  }
) => {



  const serializedData = useMemo(() => {
    return serializedLineDiagramData(data)
  }, [data])

  return (
    <div>
      <div className={styles.header}>
        <h2 className={styles.title}>{title}</h2>
        <select
          className={styles.select}
          name={name}
          onChange={onChangeSelect}
          value={value}
        >
          {options.map(({ value, optionText }) => {
            return <option
              key={Math.random()} value={value}>{optionText}
            </option>;
          })}
        </select>
      </div>
      <div className={styles.range}>
        {value === 'custom' &&
          <RangePicker onChange={(_, dateString) => onChangeDateRange(dateString, name)} />
        }
      </div>
      <div className={styles.diagramWrapper}>
        <LineChart width={500} height={290} data={serializedData}
                   margin={{ top: 5, right: 0, left: 0, bottom: 0 }}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis />
          <Tooltip />
          <Line type='monotone' dataKey='amount' stroke={mainLineColor} />
          {lineAmount === 2 &&
            <Line type='monotone' dataKey='amount2' stroke={secondLineColor} />
          }
        </LineChart>
      </div>
    </div>
  );
};

export default LineDiagram;
