import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { Projects } from './projects';
import { Users, UserPage } from './users';

import styles from './index.module.scss';
import { ProjectEditing } from './projects/Project/Editing';
import { ProjectCreating } from './projects/Project/Creating';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from 'reducers';
import 'pages/Admin/rewrite.scss';
import { logout } from 'actions';

const Admin = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector((state: StoreState) => state.login.loggedIn);
  const user = useSelector((state: StoreState) => state.login.user);

  const isAdmin = !!(loggedIn && (user as any)?.role === 'ADMIN');

  if (!isAdmin) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.layout}>
      <Helmet>
        <title>Admin - Smartlands</title>
      </Helmet>
      <div className={styles.navigation}>
        <NavLink
          to="/admin/users"
          activeClassName={styles.active}
          className={styles.link}
        >
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0)">
              <path
                d="M9.50114 0C4.25237 0 0 4.25237 0 9.50114C0 14.7499 4.25237 19.0023 9.50114 19.0023C12.7161 19.0068 15.7121 17.3811 17.4632 14.6838C20.3247 10.2855 19.0798 4.40058 14.6838 1.53906C13.1402 0.533541 11.3389 0 9.50114 0ZM16.4121 14.5265L16.2593 14.7248L16.0838 14.9437L15.9219 15.133L15.7372 15.3382L15.5662 15.5137L15.3724 15.7075L15.1922 15.874L14.9825 16.0541L14.7932 16.2114L14.5743 16.3779L14.3805 16.5193L14.1525 16.672L13.9473 16.7997L13.7102 16.9365L13.5004 17.0505L13.2542 17.1736L13.0398 17.2785L12.7822 17.388L12.5633 17.4792L12.2965 17.5749L12.0776 17.6502L11.8018 17.73L11.5783 17.7916L11.2887 17.8577L11.0698 17.9056L10.762 17.9534L10.5523 17.9876L10.2057 18.0218H10.0324C9.85455 18.0218 9.67671 18.0218 9.50114 18.0218C9.19561 18.0218 8.89235 18.0059 8.5891 17.974H8.55034C8.26305 17.942 7.97804 17.8942 7.69531 17.8326H7.6155C7.34417 17.771 7.07512 17.6958 6.81291 17.6091L6.70347 17.5749C6.44582 17.4837 6.19501 17.3857 5.94876 17.2717L5.83931 17.2192C5.59762 17.1052 5.36505 16.9776 5.13248 16.8385L5.02304 16.7724C4.79731 16.6333 4.57842 16.4737 4.36181 16.2981L4.27517 16.2502C4.0654 16.086 3.86247 15.9105 3.66639 15.7281L3.57062 15.6369C3.37681 15.4476 3.18529 15.247 3.00972 15.0395L2.98008 15.0007C5.72303 11.1565 11.063 10.2627 14.9072 13.0056C15.4841 13.4183 16.0085 13.8994 16.4668 14.4398L16.4121 14.5265ZM17.0004 13.6053C13.4913 9.70407 7.48554 9.38714 3.5843 12.8962C3.14197 13.2952 2.73611 13.7353 2.37585 14.2095C1.4433 12.8164 0.948518 11.177 0.950798 9.50114C0.948518 4.77907 4.77451 0.948518 9.49658 0.946238C14.2186 0.943958 18.0492 4.76995 18.0515 9.49202C18.0515 10.9308 17.6889 12.349 16.9981 13.6099L17.0004 13.6053Z"
                fill="#C4C4C4"
              />
              <path
                d="M9.50113 2.375C7.66566 2.375 6.17676 3.8639 6.17676 5.69937C6.17676 7.53485 7.66566 9.02375 9.50113 9.02375C11.3366 9.02375 12.8255 7.53485 12.8255 5.69937C12.8255 3.8639 11.3366 2.375 9.50113 2.375ZM9.50113 8.07523C8.19008 8.07523 7.12528 7.01271 7.12528 5.69937C7.12528 4.38604 8.1878 3.32352 9.50113 3.32352C10.8145 3.32352 11.8747 4.38832 11.8747 5.69937C11.8747 7.01043 10.8122 8.07523 9.50113 8.07523Z"
                fill="#C4C4C4"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="19" height="19" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Users</span>
        </NavLink>
        <NavLink
          to="/admin/projects"
          className={styles.link}
          activeClassName={styles.active}
        >
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.5 0C9.17344 0 8.90625 0.26129 8.90625 0.580645V3.54194C7.54062 3.80323 6.53125 4.99355 6.53125 6.3871C6.53125 7.78064 7.54062 8.97097 8.90625 9.23226V17.4194C8.90625 17.7387 9.17344 18 9.5 18C9.82656 18 10.0938 17.7387 10.0938 17.4194V9.23226C11.4594 8.97097 12.4688 7.78064 12.4688 6.3871C12.4688 4.99355 11.4594 3.80323 10.0938 3.54194V0.580645C10.0938 0.26129 9.82656 0 9.5 0ZM11.2812 6.3871C11.2812 7.34516 10.4797 8.12903 9.5 8.12903C8.52031 8.12903 7.71875 7.34516 7.71875 6.3871C7.71875 5.42903 8.52031 4.64516 9.5 4.64516C10.4797 4.64516 11.2812 5.42903 11.2812 6.3871Z"
              fill="#C4C4C4"
            />
            <path
              d="M16.625 8.76774V0.580645C16.625 0.26129 16.3578 0 16.0312 0C15.7047 0 15.4375 0.26129 15.4375 0.580645V8.76774C14.0719 9.02903 13.0625 10.2194 13.0625 11.6129C13.0625 13.0065 14.0719 14.1968 15.4375 14.4581V17.4194C15.4375 17.7387 15.7047 18 16.0312 18C16.3578 18 16.625 17.7387 16.625 17.4194V14.4581C17.9906 14.1968 19 13.0065 19 11.6129C19 10.2194 17.9906 9.02903 16.625 8.76774ZM16.0312 13.3548C15.0516 13.3548 14.25 12.571 14.25 11.6129C14.25 10.6548 15.0516 9.87097 16.0312 9.87097C17.0109 9.87097 17.8125 10.6548 17.8125 11.6129C17.8125 12.571 17.0109 13.3548 16.0312 13.3548Z"
              fill="#C4C4C4"
            />
            <path
              d="M2.375 14.4581V17.4194C2.375 17.7387 2.64219 18 2.96875 18C3.29531 18 3.5625 17.7387 3.5625 17.4194V14.4581C4.92812 14.1968 5.9375 13.0065 5.9375 11.6129C5.9375 10.2194 4.92812 9.02903 3.5625 8.76774V0.580645C3.5625 0.26129 3.29531 0 2.96875 0C2.64219 0 2.375 0.26129 2.375 0.580645V8.76774C1.00937 9.02903 0 10.2194 0 11.6129C0 13.0065 1.00937 14.1968 2.375 14.4581ZM2.96875 9.87097C3.94844 9.87097 4.75 10.6548 4.75 11.6129C4.75 12.571 3.94844 13.3548 2.96875 13.3548C1.98906 13.3548 1.1875 12.571 1.1875 11.6129C1.1875 10.6548 1.98906 9.87097 2.96875 9.87097Z"
              fill="#C4C4C4"
            />
          </svg>
          <span>Projects</span>
        </NavLink>
        <div onClick={() => dispatch(logout())} className={styles.link}>
          <svg
            viewBox="64 64 896 896"
            focusable="false"
            data-icon="logout"
            width="19px"
            height="18px"
            fill="#C4C4C4"
            aria-hidden="true"
          >
            <path d="M868 732h-70.3c-4.8 0-9.3 2.1-12.3 5.8-7 8.5-14.5 16.7-22.4 24.5a353.84 353.84 0 01-112.7 75.9A352.8 352.8 0 01512.4 866c-47.9 0-94.3-9.4-137.9-27.8a353.84 353.84 0 01-112.7-75.9 353.28 353.28 0 01-76-112.5C167.3 606.2 158 559.9 158 512s9.4-94.2 27.8-137.8c17.8-42.1 43.4-80 76-112.5s70.5-58.1 112.7-75.9c43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 7.9 7.9 15.3 16.1 22.4 24.5 3 3.7 7.6 5.8 12.3 5.8H868c6.3 0 10.2-7 6.7-12.3C798 160.5 663.8 81.6 511.3 82 271.7 82.6 79.6 277.1 82 516.4 84.4 751.9 276.2 942 512.4 942c152.1 0 285.7-78.8 362.3-197.7 3.4-5.3-.4-12.3-6.7-12.3zm88.9-226.3L815 393.7c-5.3-4.2-13-.4-13 6.3v76H488c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 000-12.6z"></path>
          </svg>
          <span>Logout</span>
        </div>
      </div>
      <Switch>
        <Route
          exact
          path="/admin"
          render={() => <Redirect to="/admin/projects" />}
        />
        <Route exact path="/admin/users">
          <div className={styles.container}>
            <Users />
          </div>
        </Route>
        <Route exact path="/admin/users/:id">
          <div className={styles.container}>
            <UserPage />
          </div>
        </Route>
        <Route exact path="/admin/projects">
          <div className={styles.container}>
            <Projects />
          </div>
        </Route>
        <Route exact path="/admin/projects/edit/:id">
          <div className={styles.container}>
            <ProjectEditing />
          </div>
        </Route>
        <Route exact path="/admin/projects/create">
          <div className={styles.container}>
            <ProjectCreating />
          </div>
        </Route>
      </Switch>
    </div>
  );
};

export default Admin;
