import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Input,
  Select
} from 'antd';
import { SelectValue } from 'antd/lib/select';
import { useFormik } from 'formik';
import moment, { Moment } from 'moment';
import { Error } from 'pages/Admin/components/Error';
import {
  getAllCategories,
  getProjectById,
  ProjectStatus,
  updateProjectById
} from 'pages/Admin/projects.api';
import { ProjectSchema } from 'pages/Admin/validations';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styles from '../../../index.module.scss';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../../../reducers';

interface Props {
  id: string;
}

const { Option } = Select;

export const ProjectSettingsForm: React.FC<Props> = ({ id }) => {
  const [isChangeCurrencyEnable, setIsChangeCurrencyEnable] = useState<boolean>(false);
  const currencyList = useSelector((store: StoreState) => store.currency.allowedCurrenciesList);

  const projectSettings = useFormik({
    validationSchema: ProjectSchema,
    initialValues: {
      goal: 0,
      status: ProjectStatus.ComingSoon,
      minimumInvestment: 0,
      expectedReturns: 0,
      website: '',
      currencyCode: '',
      startDate: null,
      endDate: null,
      categoryIds: [],
      tokenPrice: '',
      issuer: '',
      ticker: '',
      isVisibleWebsite: false
    },
    onSubmit: async (values) => {
      try {
        await updateProjectById(id, values);
        toast.success('Project settings updated');
      } catch (e) {
        console.log(e);
      }
    }
  });

  const [categories, setCategories] = useState<
    Array<{ id: string; name: string }>
  >([]);

  const handleProjectSettingsSelectChange = (
    value: SelectValue,
    key: keyof typeof projectSettings.values
  ) => {
    projectSettings.setFieldValue(key, value);
  };

  const handleProjectSettingsDateChange = (
    date: Moment | null,
    dateString: string,
    key: keyof typeof projectSettings.values
  ) => {
    projectSettings.setFieldValue(key, date);
  };

  const mapIdsToCategories = (
    categories: Array<string>,
    categoriesWithId: Array<{ id: string; name: string }>
  ) => {
    return categories
      .map((c) => {
        return categoriesWithId.find((cwi) => cwi.name === c);
      })
      .filter(Boolean);
  };

  useEffect(() => {
    const getInitialData = async () => {
      const project = (await getProjectById(id)).data;

      project.categoryIds = project.categories;
      project.endDate = moment(project.endDate);
      project.startDate = moment(project.startDate);

      setIsChangeCurrencyEnable(project.isChangeCurrencyEnable)
      projectSettings.setValues(project);

      const categories = (await getAllCategories()).data.results;

      setCategories(categories);

      const mappedCategories = mapIdsToCategories(
        project.categoryIds,
        categories
      ).map((c) => c && c.id);

      mappedCategories.length &&
        projectSettings.setFieldValue('categoryIds', mappedCategories);
    };

    getInitialData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Card className="admin-card">
        <h1>Project Settings</h1>
        <Divider />
        <div className={styles.flex}>
          <div className={styles.part}>
            <div className={styles.field}>
              <label className={styles.label}>Goal</label>
              <Input
                id="goal"
                name="goal"
                value={projectSettings.values.goal}
                onChange={projectSettings.handleChange}
                placeholder="Project Goal"
                type="number"
              />
              <Error
                visible={
                  projectSettings.touched.goal && projectSettings.errors.goal
                }
                message={projectSettings.errors.goal}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Status</label>
              <Select
                id="status"
                value={projectSettings.values.status}
                onChange={(value) =>
                  handleProjectSettingsSelectChange(value, 'status')
                }
                className={styles.select}
              >
                <Option value={ProjectStatus.ComingSoon}>Coming Soon</Option>
                <Option value={ProjectStatus.Ongoing}>Ongoing</Option>
                <Option value={ProjectStatus.Past}>Past</Option>
              </Select>
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Currency</label>
              <Select
                id='status'
                disabled={!isChangeCurrencyEnable}
                value={projectSettings.values.currencyCode}
                onChange={(value) =>
                  handleProjectSettingsSelectChange(value, 'currencyCode')
                }
                className={styles.select}
              >
                {currencyList.map((item) => (
                  <Option value={item.code}>{item.code}</Option>
                ))}
              </Select>
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Minimum investment</label>
              <Input
                id="minimumInvestment"
                name="minimumInvestment"
                value={projectSettings.values.minimumInvestment}
                onChange={projectSettings.handleChange}
                placeholder="Minimum investment"
                type="number"
              />
              <Error
                visible={
                  projectSettings.touched.minimumInvestment &&
                  projectSettings.errors.minimumInvestment
                }
                message={projectSettings.errors.minimumInvestment}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Expected Returns</label>
              <Input
                id="expectedReturns"
                name="expectedReturns"
                value={projectSettings.values.expectedReturns}
                onChange={projectSettings.handleChange}
                placeholder="Expected Returns"
                type="number"
              />
              <Error
                visible={
                  projectSettings.touched.expectedReturns &&
                  projectSettings.errors.expectedReturns
                }
                message={projectSettings.errors.expectedReturns}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Token Price</label>
              <Input
                id="tokenPrice"
                name="tokenPrice"
                value={projectSettings.values.tokenPrice}
                onChange={projectSettings.handleChange}
                placeholder="Token price"
              />
              <Error
                visible={
                  projectSettings.touched.tokenPrice &&
                  projectSettings.errors.tokenPrice
                }
                message={projectSettings.errors.tokenPrice}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Issuer</label>
              <Input
                id="issuer"
                name="issuer"
                value={projectSettings.values.issuer}
                onChange={projectSettings.handleChange}
                placeholder="Issuer"
              />
              <Error
                visible={
                  projectSettings.touched.issuer &&
                  projectSettings.errors.issuer
                }
                message={projectSettings.errors.issuer}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Ticker</label>
              <Input
                id="ticker"
                name="ticker"
                value={projectSettings.values.ticker}
                onChange={projectSettings.handleChange}
                placeholder="Ticker"
              />
              <Error
                visible={
                  projectSettings.touched.ticker &&
                  projectSettings.errors.ticker
                }
                message={projectSettings.errors.ticker}
              />
            </div>
          </div>
          <div className={styles.part}>
            <div className={styles.field}>
              <label className={styles.label}>Website</label>
              <Input
                id="website"
                name="website"
                value={projectSettings.values.website}
                onChange={projectSettings.handleChange}
                placeholder="Website"
              />
              <div style={{ padding: '15px 0 0' }}>
                <Checkbox
                  checked={projectSettings.values.isVisibleWebsite}
                  onChange={(e) =>
                    projectSettings.setFieldValue(
                      'isVisibleWebsite',
                      e.target.checked
                    )
                  }
                />{' '}
                <span>Publish data?</span>
              </div>
              <Error
                visible={
                  projectSettings.touched.website &&
                  projectSettings.errors.website
                }
                message={projectSettings.errors.website}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Start Date</label>
              <DatePicker
                id="startDate"
                name="startDate"
                format={'YYYY-MM-DD'}
                defaultValue={moment()}
                onChange={(value, dateString) =>
                  handleProjectSettingsDateChange(
                    value,
                    dateString,
                    'startDate'
                  )
                }
                value={projectSettings.values.startDate}
              />
              <Error
                visible={
                  projectSettings.touched.startDate &&
                  projectSettings.errors.startDate
                }
                message={projectSettings.errors.startDate}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>End Date</label>
              <DatePicker
                id="endDate"
                name="endDate"
                defaultValue={moment()}
                format={'YYYY-MM-DD'}
                onChange={(value, dateString) =>
                  handleProjectSettingsDateChange(value, dateString, 'endDate')
                }
                value={projectSettings.values.endDate}
              />
              <Error
                visible={
                  projectSettings.touched.endDate &&
                  projectSettings.errors.endDate
                }
                message={projectSettings.errors.endDate}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Categories</label>
              <Select
                mode="multiple"
                placeholder="Please select"
                id="categoryIds"
                style={{ width: '100%' }}
                onChange={(value) => {
                  handleProjectSettingsSelectChange(value, 'categoryIds');
                }}
                value={projectSettings.values.categoryIds}
              >
                {categories.map((category) => (
                  <Option value={category.id} key={category.name}>
                    {category.name}
                  </Option>
                ))}
              </Select>
              <Error
                visible={
                  projectSettings.touched.categoryIds &&
                  projectSettings.errors.categoryIds
                }
                message={projectSettings.errors.categoryIds}
              />
            </div>
          </div>
        </div>
        <Divider />
        <Button
          type="ghost"
          className="admin-success-button"
          block
          size="large"
          onClick={(e) => projectSettings.handleSubmit()}
        >
          Submit Project Settings
        </Button>
      </Card>
      <Divider />
    </>
  );
};
