import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { ProjectStatus } from '../projects.api';
import { getImageLink } from '../utils/get-image-link';

type ProjectColumn = {
  id: number;
  image: string;
  name: string;
  categories: string;
  creationDate: string;
  lastUpdatedDate: string;
  status: string;
};

const updateIndex = () => {
  let i = 1;
  return () => {
    i = i + 1;
    console.log(i);
  };
};

export const projectsColumns = [
  {
    title: 'Project Image',
    dataIndex: 'projectImage',
    key: 'projectImage',
    render: (projectImage: string) => (
      <img
        width={200}
        height={100}
        style={{ objectFit: 'cover' }}
        src={getImageLink(projectImage)}
        alt="project"
      />
    )
  },
  {
    title: 'Project Name',
    dataIndex: 'projectName',
    key: 'projectName'
  },
  {
    title: 'Categories',
    dataIndex: 'categories',
    key: 'categories',
    filters: [
      {
        text: 'Office',
        value: 'Office'
      },
      {
        text: 'Commercial',
        value: 'Commercial'
      },
      {
        text: 'Residential',
        value: 'Residential'
      }
    ],
    onFilter: (value: any, record: ProjectColumn) => {
      return record.categories.includes(value);
    },
    render: (categories: Array<string>) => {
      return (
        <>
          {categories.map((category) => (
            <div>{category} </div>
          ))}
        </>
      );
    }
  },
  {
    title: 'Minimum Investment',
    dataIndex: 'minimumInvestment',
    key: 'minimumInvestment'
  },
  {
    title: 'Goal',
    dataIndex: 'goal',
    key: 'goal'
  },
  {
    title: 'Project Status',
    dataIndex: 'status',
    key: 'status',
    filters: [
      {
        text: 'Ongoing',
        value: ProjectStatus.Ongoing
      },
      {
        text: 'Past',
        value: ProjectStatus.Past
      },
      {
        text: 'Coming Soon',
        value: ProjectStatus.ComingSoon
      }
    ],
    onFilter: (value: any, record: ProjectColumn) => {
      return record.status.includes(value);
    }
  },
  {
    title: '',
    dataIndex: '',
    key: `button-${updateIndex()}`,
    render: (_: string, column: ProjectColumn) => {
      return (
        <Link to={`/admin/projects/edit/${column.id}`}>
          <Button
            type="ghost"
            size="middle"
            block
            style={{
              width: '80px',
              borderColor: '#3BB46C',
              color: '#3BB46C',
              borderRadius: '25px'
            }}
          >
            Edit
          </Button>
        </Link>
      );
    }
  }
];
