/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import cls from 'classnames';
import { ReactComponent as VerifyIcon } from 'assets/icons/verify.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as DocumentsIcon } from 'assets/icons/documents.svg';
import { ReactComponent as OverviewIcon } from 'assets/icons/overview.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/wallet-icon.svg';
import styles from './index.module.scss';
import { useAppDispatch } from 'store';
import { getUser, logout, User } from 'actions';
import { useTranslation } from 'react-i18next';
import { Link, Switch, Route, Redirect, useParams } from 'react-router-dom';
import Overview from './Overview';
import Verify from './Verify';
import Edit from './Edit';
import Documents from './Documents';
import { LogoutOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { StoreState } from 'reducers';
import { ChangePassword } from './Edit/ChangePassword';

const { REACT_APP_WALLET_LINK } = process.env;

export interface ProfileProps {}

const Profile: React.FC<ProfileProps> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { tab }: { tab: string } = useParams();
  const user: User = useSelector((state: StoreState) => state.login.user);

  useEffect(() => {
    setTimeout(() => dispatch(getUser()), 5000);
  }, []);

  // useEffect(() => {
  //   if (user.kycStatus === 'pending') {
  //     setTimeout(() => dispatch(getUser()), 5000);
  //   }
  // }, [user]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.sidebar}>
        <div className={styles.links}>
          <Link
            to="/profile/overview"
            className={cls(styles.link, {
              [styles.active]: tab === 'overview'
            })}
          >
            <OverviewIcon />
            <div>{t('profile:overview')}</div>
          </Link>
          <Link
            to="/profile/edit"
            className={cls(styles.link, { [styles.active]: tab === 'edit' })}
          >
            <EditIcon />
            <div>{t('profile:editProfile')}</div>
          </Link>
          <Link
            to="/profile/verify"
            className={cls(styles.link, { [styles.active]: tab === 'verify' })}
          >
            <VerifyIcon />
            <div>{t('profile:verifyYourIdentity')}</div>
          </Link>
          <Link
            to="/profile/documents"
            className={cls(styles.link, {
              [styles.active]: tab === 'documents'
            })}
          >
            <DocumentsIcon />
            <div>{t('profile:documents')}</div>
          </Link>
          <a
            href={REACT_APP_WALLET_LINK}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <WalletIcon />
            <div>Wallet</div>
          </a>
        </div>
        <div onClick={() => dispatch(logout())} className={styles.link}>
          <LogoutOutlined />
          <div>{t('login:logout')}</div>
        </div>
      </div>
      <div className={styles.content}>
        <Switch>
          <Route
            exact
            path="/profile"
            render={() => <Redirect to="/profile/overview" />}
          />

          <Route exact path="/profile/overview">
            <Overview />
          </Route>

          <Route exact path="/profile/edit">
            <Edit user={user} />
          </Route>
          <Route exact path="/profile/edit/change-password">
            <ChangePassword />
          </Route>
          <Route exact path="/profile/documents">
            <Documents />
          </Route>
          <Route exact path="/profile/verify">
            <Verify user={user} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default Profile;
