import { Card, Divider, Input, Select, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FormikErrors, FormikTouched } from 'formik';
import { Error } from 'pages/Admin/components/Error';
import { LanguageCode } from 'pages/Admin/projects.api';
import React, { useState } from 'react';
import styles from '../../../index.module.scss';
import { HTMLEditor } from 'pages/Admin/components/HTMLEditor';

const { Option } = Select;
const { TextArea } = Input;

type Form = {
  projectName: string;
  location: string;
  company: string;
  tagline: string;
  shortDescription: string;
  terms: string;
  description: string;
  isDefault: boolean;
  isVisibleCompany: boolean;
};

interface Props {
  form: Form;
  onChange: (e: React.ChangeEvent) => void;
  onCheckboxChange: (e: CheckboxChangeEvent) => void;
  errors: FormikErrors<Form> | undefined;
  touched: FormikTouched<Form> | undefined;
  onDescriptionChange: (value: any) => void;
  onCompanyCheckboxChange: (e: CheckboxChangeEvent) => void;
}

export const ProjectTranslationsForm: React.FC<Props> = ({
  form,
  onChange,
  onCheckboxChange,
  errors,
  touched,
  onDescriptionChange,
  onCompanyCheckboxChange
}) => {
  const [languageCode, setLanguageCode] = useState(LanguageCode.EN);

  return (
    <>
      <Divider className="admin-divider" />
      <Card className="admin-card">
        <h1>Translation Fields</h1>
        <div className={styles.field}>
          <label className={styles.label}>Language</label>
          <Select
            className={styles.select}
            value={languageCode}
            onChange={(value) => setLanguageCode(value)}
          >
            <Option value="en">EN</Option>
            <Option value="uk">UK</Option>
          </Select>
        </div>
        <div className={styles.field}>
          <label className={styles.label}>
            <span style={{ paddingRight: '20px' }}>Is Default</span>
            <Checkbox
              id="translationFields.isDefault"
              name="translationFields.isDefault"
              onChange={onCheckboxChange}
              checked={form.isDefault}
            />
          </label>
        </div>
        <Divider className="admin-divider" />
        <div className={styles.flex}>
          <div className={styles.part}>
            <div className={styles.field}>
              <label className={styles.label}>Project Name</label>
              <Input
                id="translationFields.projectName"
                name="translationFields.projectName"
                value={form.projectName}
                onChange={onChange}
                placeholder="Project Name"
              />
              <Error
                visible={touched?.projectName && errors?.projectName}
                message={errors?.projectName}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Location</label>
              <Input
                id="translationFields.location"
                name="translationFields.location"
                value={form.location}
                onChange={onChange}
                placeholder="Location"
              />
              <Error
                visible={touched?.location && errors?.location}
                message={errors?.location}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Company</label>
              <Input
                id="translationFields.company"
                name="translationFields.company"
                value={form.company}
                onChange={onChange}
                placeholder="Company"
              />
              <div style={{ padding: '15px 0 0' }}>
                <Checkbox
                  checked={form.isVisibleCompany}
                  onChange={onCompanyCheckboxChange}
                />{' '}
                <span>Publish data?</span>
              </div>
              <Error
                visible={touched?.company && errors?.company}
                message={errors?.company}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Tagline</label>
              <Input
                id="translationFields.tagline"
                name="translationFields.tagline"
                value={form.tagline}
                onChange={onChange}
                placeholder="Tagline"
              />
              <Error
                visible={touched?.tagline && errors?.tagline}
                message={errors?.tagline}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Short Description</label>
              <TextArea
                id="translationFields.shortDescription"
                name="translationFields.shortDescription"
                placeholder="Short Description"
                onChange={onChange}
                value={form.shortDescription}
              />
              <Error
                visible={touched?.shortDescription && errors?.shortDescription}
                message={errors?.shortDescription}
              />
            </div>
          </div>
          <div className={styles.part}>
            <div className={styles.field}>
              <label className={styles.label}>Terms</label>
              <TextArea
                id="translationFields.terms"
                name="translationFields.terms"
                value={form.terms}
                onChange={onChange}
                placeholder="Terms"
              />
              <Error
                visible={touched?.terms && errors?.terms}
                message={errors?.terms}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Description</label>
              <HTMLEditor
                id="translationFields.description"
                value={form.description}
                onChange={onDescriptionChange}
              />
              <Error
                visible={touched?.description && errors?.description}
                message={errors?.description}
              />
            </div>
          </div>
        </div>
        <Divider className="admin-divider" />
      </Card>
    </>
  );
};
