import React from 'react';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Button from 'components/common/Button';
import styles from './index.module.scss';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import { User } from 'actions';

export interface VerifyProps {
  user: User;
}

const Verify: React.FC<VerifyProps> = ({ user }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      {user.kycStatus === 'not-initialized' ? (
        <InfoCircleOutlined className={styles.icon} />
      ) : user.kycStatus === 'clear' ? (
        <CheckCircleOutlined className={styles.icon} />
      ) : user.kycStatus === 'consider' ? (
        <CloseCircleOutlined className={cls(styles.icon, styles.red)} />
      ) : (
        <ClockCircleOutlined className={styles.icon} />
      )}
      <div className={styles.info}>
        <div className={styles.title}>{t('kyc:personalDetails')}</div>
        <div className={styles.status}>
          {user.kycStatus === 'not-initialized'
            ? t('kyc:notInitialized')
            : user.kycStatus === 'clear'
            ? t('kyc:verified')
            : user.kycStatus === 'consider'
            ? t('kyc:failed')
            : t('kyc:pending')}
        </div>
        {user.kycStatus === 'not-initialized' ? (
          <Button text={t('kyc:verifyMyIdentity')} size='md' className={styles.button} to='/kyc' />
        ) : user.kycStatus === 'clear' ? null : user.kycStatus === 'consider' ? (
          <Button text={t('kyc:recheck')} size='md' type='error' className={styles.button} to='/kyc' />
        ) : null}
      </div>
    </div>
  );
};

export default Verify;
