import httpClient from '../../http-client';
import { IRange } from '../../hooks/useRangeForDiagrams';


export type VolumeDiagramItem = {
  amount: number;
  createdAt: string;
  currencyCode: string;
}

export type VolumeDiagram = VolumeDiagramItem[];

export type LastTransaction = {
  status: 'paid' | 'draft' | 'late';
  createdAt: string;
  amount: number;
  currencyCode: string;
}

export interface ILastTransactions {
  results: LastTransaction[];
  total: number
}

export const getLastTransactions = (page: string) => {
  return httpClient.get<ILastTransactions>(`/dashboard/transactions?page=${page}&limit=7`);
};

export const getVolumeDiagram = ({endDate, startDate}: IRange) => {
  return httpClient.get(`/dashboard/volume-investments?startDate=${startDate}&endDate=${endDate}`);
};


export const setUserCategory = (category: string) => {
  return httpClient.post(`/profile/compliance`, {category});
};