import styles from './index.module.scss';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLastTransactions, LastTransaction } from '../../overview.api';
import convertCurrency from 'utils/convertCurrency';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../../reducers';
import { getExchangeRate } from '../../../../actions/currency';
import { Pagination } from 'antd';


const TransactionsHistory: FC = () => {
  const { t } = useTranslation('profile');
  const dispatch = useDispatch();
  const exchangeCurrency = useSelector((state: StoreState) => state.currency.exchangeCurrency);
  const allowedCurrency = useSelector((state: StoreState) => state.currency.allowedCurrenciesList);
  const userCurrency = useSelector((state: StoreState) => state.login.user.currencyCode);
  const [lastTransactions, setLastTransactions] = useState<LastTransaction[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    try {
      const lastTransactionsReq = async () => {

        const { data: { results, total } } = await getLastTransactions((pageNumber - 1).toString());
        setLastTransactions(results);
        setTotal(total);
      };
      lastTransactionsReq();
    } catch (e) {
      console.log('LAST TRANSACTIONS ERROR', e);
    }
  }, [pageNumber]);

  useEffect(() => {
    const allowedSymbols = allowedCurrency.map((item) => item.code).join(',');
    Array.from(new Set(lastTransactions.map((item) => item.currencyCode)))
      .forEach((item) => {
        if (!exchangeCurrency[item]) {
          dispatch(getExchangeRate(item, allowedSymbols));
        }
      }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastTransactions]);

  const onChange = (page: number) => {
    setPageNumber(page);
  };

  return (
    <div>
      <h2 className={styles.title}>{t('lastTransaction')}</h2>

      <ul className={styles.list}>
        {lastTransactions.length === 0 ? <p>{t('noTransactions')}</p> : lastTransactions.map(({
                                                                                                currencyCode,
                                                                                                createdAt,
                                                                                                amount,
                                                                                                status
                                                                                              }) => (
          <li key={Math.random()} className={styles.item}>
            <span>{createdAt.slice(0, createdAt.indexOf('T'))}</span>
            <span>{convertCurrency(
              amount,
              userCurrency,
              exchangeCurrency[currencyCode]?.[userCurrency],
              currencyCode,
              undefined,
              true)}</span>
            <span className={`${styles[status]} ${styles.status}`}>{t(status)}</span>

          </li>
        ))}
      </ul>
      <div className={styles.pag}>
        <Pagination
          onChange={onChange}
          total={total}
          defaultPageSize={7}
          showSizeChanger={false}
          current={pageNumber}
          responsive={true}
        />
      </div>
    </div>
  );
};

export default TransactionsHistory;
