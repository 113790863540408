import httpClient from 'http-client';
import {
  GetOfferByIdRequestDto,
  GetAllOffersRequestDto,
  GetOfferByIdResponseDto,
  GetAllOffersResponseDto
} from 'types/dto/offers';

const getAllOffers = async (params: GetAllOffersRequestDto) => {
  return httpClient.get<GetAllOffersResponseDto>('/offers', {
    params
  });
};

const getOfferById = async (
  offerId: string,
  params: GetOfferByIdRequestDto
) => {
  return httpClient.get<GetOfferByIdResponseDto>(`/offers/${offerId}`, {
    params
  });
};

const getTransactionForOffer = async (
  offerId: string,
  amount: string,
  amountOffering: string
) => {
  return httpClient.post(`/offers/invest/${offerId}`, {
    amount: Number(amount),
    amountOffering: Number(amountOffering)
  });
};

export const offersAPI = {
  getAllOffers,
  getOfferById,
  getTransactionForOffer
};
