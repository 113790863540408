import { VolumeDiagram } from '../pages/Profile/overview.api';
import moment from 'moment';

export const serializedLineDiagramData = (data: VolumeDiagram ) => {
  if (data.length < 10) {
    return data
      .sort((a, b) =>
        moment(b.createdAt).unix() - moment(a.createdAt).unix())
      .map(({amount}, index) => ({name: index+1, amount}))
  }

  const SIZE = Math.ceil(data.length / 10);

  return data
    .sort((a, b) =>
      moment(b.createdAt).unix() - moment(a.createdAt).unix())
    .reduce((acc: any, item) => {
      if (acc[acc.length-1].length === SIZE) {
        acc.push([])
      }

      acc[acc.length-1].push(item)
      return acc
    }, [[]])

    .map((item: any) => item
      .sort((a: any, b: any) => b.amount - a.amount)[0]
    )
    .map(({amount}: any, index: number) => ({name: index+1, amount}))
}