import { ActionTypes, Action, Category } from 'actions';

export const offersReducer = (state: Category[] = [], action: Action) => {
  switch (action.type) {
    case ActionTypes.setCategories:
      return action.payload;
    default:
      return state;
  }
};
