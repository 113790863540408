import * as React from 'react';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import key from 'assets/icons/key.svg';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import cls from 'classnames';
import { Link } from 'react-router-dom';

interface FormProps {
  formik: any;
  resendConfirmation: (email: string) => void;
}

const Form: React.FC<FormProps> = ({ formik, resendConfirmation }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}> {t('login:forgotPassword')}</div>
      <img className={styles.icon} src={key} alt='' />
      <div className={styles.text}>{t('login:recoveryPrompt')}</div>
      {formik.touched.email && formik.errors.email && (
        <div className={styles.errorsMobile}>
          {formik.touched.email && formik.errors.email && (
            <div className={styles.errorMobile}>
              <div className={styles.circle} />
              <div>
                <div>{formik.errors.email}</div>
                {formik.errors.email.includes('confirmed') && (
                  <div className={styles.link} onClick={() => resendConfirmation(formik.values.email)}>
                    {t('login:resendConfirmation')}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <div className={styles.inputWrapper}>
        <Input
          className={cls(styles.input, { [styles.inputError]: formik.touched.email && formik.errors.email })}
          id='email'
          name='email'
          value={formik.values.email}
          placeholder={t('common:email')}
          onBlur={formik.handleBlur}
          handleChange={formik.handleChange}
        />
        {formik.touched.email && formik.errors.email && (
          <div className={styles.error}>
            <div className={styles.circle} />
            <div>
              <div>{formik.errors.email}</div>
              {formik.errors.email.includes('confirmed') && (
                <div className={styles.resendLink} onClick={() => resendConfirmation(formik.values.email)}>
                  {t('login:resendConfirmation')}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Button
        text={t('common:submit')}
        type='secondary'
        className={styles.button}
        disabled={formik.isSubmitting}
        loading={formik.isSubmitting}
        onClick={formik.handleSubmit}
      />
      <div className={styles.action}>
        <div>{t('login:notRegistered')}</div>
        <Link to='/registration' className={styles.link}>
          {t('login:createAccount')}
        </Link>
      </div>
    </div>
  );
};

export default Form;
