import * as React from 'react';
import mail from 'assets/icons/mail.svg';
import styles from './index.module.scss';
import Button from 'components/common/Button';
import { useTranslation } from 'react-i18next';

export interface ConfirmationProps {}

const Confirmation: React.FC<ConfirmationProps> = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{t('login:resetTitle')}</div>
      <img className={styles.icon} src={mail} alt='' />
      <div className={styles.green}>{t('login:resetConfirmationTitle')}</div>
      <div className={styles.text}>{t('login:resetConfirmationSubtitle')}</div>
      <Button text={t('common:backToSite')} type='secondary' className={styles.button} to='/' />
    </div>
  );
};

export default Confirmation;
