import * as React from 'react';
import Input from 'components/common/Input';
import cls from 'classnames';
import Button from 'components/common/Button';
import styles from './index.module.scss';
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { countries } from 'actions';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useSelector } from 'react-redux';
import { StoreState } from 'reducers';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Checkbox } from 'antd';
import InputTooltip from 'components/ui/input-tooltip';

const { Option } = Select;

interface FormProps {
  formik: any;
  loading: boolean;
  handleNext: () => void;
}

const Form: React.FC<FormProps> = ({ formik, loading, handleNext }) => {
  const { t } = useTranslation();
  const [companyOpened, setCompanyOpened] = React.useState<boolean>(false);
  const [checked, setChecked] = React.useState(false);
  const country: string = useSelector(
    (state: StoreState) => state.language.country.alpha2
  );

  const [isUsa, setIsUsa] = React.useState(false);

  React.useEffect(() => {
    if (formik.values.country === 'USA') {
      setIsUsa(true);
    } else {
      setIsUsa(false);
    }
  }, [formik.values.country]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{t('kyc:personalTitle')}</div>
      {isUsa && (
        <div className={styles.alert}>
          Sorry, we cant verify customers from USA
        </div>
      )}

      <div className={styles.row}>
        <div className={styles.inputWrapper}>
          <Input
            autoFocus
            id="firstName"
            name="firstName"
            value={formik.values.firstName}
            className={cls(styles.input, {
              [styles.inputError]:
                formik.touched.firstName && formik.errors.firstName
            })}
            placeholder={t('common:firstName')}
            onBlur={formik.handleBlur}
            handleChange={formik.handleChange}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            id="lastName"
            name="lastName"
            value={formik.values.lastName}
            className={cls(styles.input, {
              [styles.inputError]:
                formik.touched.lastName && formik.errors.lastName
            })}
            placeholder={t('common:lastName')}
            onBlur={formik.handleBlur}
            handleChange={formik.handleChange}
          />
        </div>
      </div>
      <div className={styles.inputWrapper}>
        <Input
          id="street"
          name="street"
          value={formik.values.street}
          className={cls(styles.input, {
            [styles.inputError]: formik.touched.street && formik.errors.street
          })}
          placeholder={t('common:street')}
          onBlur={formik.handleBlur}
          handleChange={formik.handleChange}
        />
        {formik.touched.street && formik.errors.street && (
          <div className={styles.error}>
            <div className={styles.circle} />
            <div>{formik.errors.street}</div>
          </div>
        )}
        <InputTooltip message="Street max length is 32 character for UK" />
      </div>
      <div className={styles.row}>
        <div className={styles.inputWrapper}>
          <Input
            id="town"
            name="town"
            value={formik.values.town}
            className={cls(styles.input, {
              [styles.inputError]: formik.touched.town && formik.errors.town
            })}
            placeholder={t('common:city')}
            onBlur={formik.handleBlur}
            handleChange={formik.handleChange}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            id="postcode"
            name="postcode"
            value={formik.values.postcode}
            className={cls(styles.input, {
              [styles.inputError]:
                formik.touched.postcode && formik.errors.postcode
            })}
            placeholder={t('common:postcode')}
            onBlur={formik.handleBlur}
            handleChange={formik.handleChange}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Select
            showSearch
            aria-autocomplete="none"
            className={cls(styles.select, {
              [styles.inputError]:
                formik.touched.country && formik.errors.country
            })}
            placeholder={t('common:country')}
            optionFilterProp="children"
            onChange={(v) => formik.setFieldValue('country', v)}
            value={formik.values.country}
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {countries.map((country: { alpha3: string; name: string }) => (
              <Option key={country.alpha3} value={country.alpha3}>
                {country.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div
        className={styles.companyButton}
        onClick={() => setCompanyOpened((prev) => !prev)}
      >
        {t('kyc:addCompany')}
        {companyOpened ? <UpCircleOutlined /> : <DownCircleOutlined />}
      </div>
      {companyOpened && (
        <>
          <div className={styles.row}>
            <div className={styles.inputWrapper}>
              <Input
                id="companyName"
                name="companyName"
                value={formik.values.companyName}
                className={cls(styles.input, {
                  [styles.inputError]:
                    formik.touched.companyName && formik.errors.companyName
                })}
                placeholder={t('common:companyName')}
                onBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Input
                id="jobTitle"
                name="jobTitle"
                value={formik.values.jobTitle}
                className={cls(styles.input, {
                  [styles.inputError]:
                    formik.touched.jobTitle && formik.errors.jobTitle
                })}
                placeholder={t('common:jobTitle')}
                onBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.inputWrapper}>
              <Input
                id="companyEmail"
                name="companyEmail"
                value={formik.values.companyEmail}
                className={cls(styles.input, {
                  [styles.inputError]:
                    formik.touched.companyEmail && formik.errors.companyEmail
                })}
                placeholder={t('common:companyEmail')}
                onBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
            </div>
            <div className={styles.inputWrapper} style={{ marginTop: 6 }}>
              <PhoneInput
                inputProps={{
                  name: 'companyPhoneNumber',
                  id: 'companyPhoneNumber',
                  onBlur: formik.handleBlur
                }}
                country={country}
                inputClass={cls(styles.phoneInput, {
                  [styles.inputError]:
                    formik.touched.companyPhoneNumber &&
                    formik.errors.companyPhoneNumber
                })}
                buttonClass={styles.dropdown}
                dropdownStyle={{ width: 205 }}
                countryCodeEditable={true}
                placeholder=""
                value={formik.values.companyPhoneNumber}
                onChange={(v) => {
                  formik.setFieldValue('companyPhoneNumber', v);
                }}
              />
            </div>
          </div>
          <div className={styles.inputWrapper}>
            <Input
              id="website"
              name="website"
              value={formik.values.website}
              className={cls(styles.input, {
                [styles.inputError]:
                  formik.touched.website && formik.errors.website
              })}
              placeholder={t('common:website')}
              onBlur={formik.handleBlur}
              handleChange={formik.handleChange}
            />
          </div>
          <div className={styles.inputWrapper}>
            <Input
              id="companyStreet"
              name="companyStreet"
              value={formik.values.companyStreet}
              className={cls(styles.input, {
                [styles.inputError]:
                  formik.touched.companyStreet && formik.errors.companyStreet
              })}
              placeholder={t('common:street')}
              onBlur={formik.handleBlur}
              handleChange={formik.handleChange}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.inputWrapper}>
              <Input
                id="companyTown"
                name="companyTown"
                value={formik.values.companyTown}
                className={cls(styles.input, {
                  [styles.inputError]:
                    formik.touched.companyTown && formik.errors.companyTown
                })}
                placeholder={t('common:city')}
                onBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Input
                id="companyPostcode"
                name="companyPostcode"
                value={formik.values.companyPostcode}
                className={cls(styles.input, {
                  [styles.inputError]:
                    formik.touched.companyPostcode &&
                    formik.errors.companyPostcode
                })}
                placeholder={t('common:postcode')}
                onBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Select
                showSearch
                aria-autocomplete="none"
                className={cls(styles.select, {
                  [styles.inputError]:
                    formik.touched.companyCountry &&
                    formik.errors.companyCountry
                })}
                placeholder={t('common:country')}
                optionFilterProp="children"
                onChange={(v) => formik.setFieldValue('companyCountry', v)}
                value={formik.values.companyCountry}
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {countries.map((country: { alpha3: string; name: string }) => (
                  <Option key={country.alpha3} value={country.alpha3}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </>
      )}
      <div className={styles.checkboxWrapper}>
        <Checkbox
          className={styles.checkbox}
          checked={checked}
          onChange={() => setChecked((prev) => !prev)}
          name="terms"
        >
          <Trans i18nKey="login:agreement">
            <Link
              className={styles.link}
              to={{ pathname: t('common:termsLink') }}
              target="_blank"
              rel="noopener noreferrer"
            />
            <Link
              className={styles.link}
              to={{ pathname: t('common:privacyPolicy') }}
              target="_blank"
              rel="noopener noreferrer"
            />
          </Trans>
        </Checkbox>
      </div>
      <Button
        text={t('common:Continue')}
        disabled={!checked || loading}
        loading={loading}
        type="secondary"
        className={styles.button}
        onClick={formik.handleSubmit}
      />
    </div>
  );
};

export default Form;
