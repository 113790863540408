import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

export interface AudienceProps {}

const Audience: React.FC<AudienceProps> = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper} id='audience'>
      <div className={styles.title}>{t('landing:audience.title')}</div>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.columnTitle}>{t('landing:audience.card1.title')}</div>
          <ul>
            <li>{t('landing:audience.card1.line1')}</li>
            <li>{t('landing:audience.card1.line2')}</li>
            <li>{t('landing:audience.card1.line3')}</li>
          </ul>
        </div>
        <div className={styles.column}>
          <div className={styles.columnTitle}>{t('landing:audience.card2.title')}</div>
          <ul>
            <li>{t('landing:audience.card2.line1')}</li>
            <li>{t('landing:audience.card2.line2')}</li>
            <li>{t('landing:audience.card2.line3')}</li>
          </ul>
        </div>
        <div className={styles.column}>
          <div className={styles.columnTitle}>{t('landing:audience.card3.title')}</div>
          <ul>
            <li>{t('landing:audience.card3.line1')}</li>
            <li>{t('landing:audience.card3.line2')}</li>
            <li>{t('landing:audience.card3.line3')}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Audience;
