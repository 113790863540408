import React from 'react';

import styles from './index.module.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

interface ButtonsProps {
  [x: string]: any;
}

const Buttons: React.FC<ButtonsProps> = ({ next, previous, ...rest }) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow },
  } = rest;

  return (
    <div className={styles.wrapper}>
      {currentSlide !== 0 && (
        <div className={styles.left} onClick={() => previous()}>
          <LeftOutlined />
        </div>
      )}
      {currentSlide !== totalItems - slidesToShow && totalItems > slidesToShow && (
        <div className={styles.right} onClick={() => next()}>
          <RightOutlined />
        </div>
      )}
    </div>
  );
};

export default Buttons;
