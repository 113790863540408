import * as Yup from 'yup';

const firstName = Yup.string()
  .required('This field is required')
  .matches(/^[a-zA-Zа-яёґєіїА-ЯЁҐЄІЇ\s]+$/, 'Invalid first name');
const lastName = Yup.string()
  .required('This field is required')
  .matches(/^[a-zA-Zа-яёґєіїА-ЯЁҐЄІЇ\s]+$/, 'Invalid last name');

const phoneNumber = Yup.string()
  .required('This field is required')
  .min(7, 'Invalid phone number');
export const email = Yup.string()
  .required('This field is required')
  .matches(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    'You entered an invalid email'
  );
const password = Yup.string()
  .required('This field is required')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\-$%^&*])(?=.{6,})/,
    'Must contain at least 6 characters, one uppercase, one lowercase, one number and one special case character (@$!-%*#?&)'
  );
const repeatPassword = Yup.string()
  .required('This field is required')
  .test('passwords-match', 'Passwords must match', function (value) {
    return this.parent.password === value;
  });

const newPassword = Yup.string()
  .required('This field is required')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\-$%^&*])(?=.{6,})/,
    'Must contain at least 6 characters, one uppercase, one lowercase, one number and one special case character (@$!-%*#?&)'
  )
  .test('password-different', 'Passwords must be different', function (value) {
    return this.parent.oldPassword !== value;
  });

const repeatNewPassword = Yup.string()
  .required('This field is required')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\-$%^&*])(?=.{6,})/,
    'Must contain at least 6 characters, one uppercase, one lowercase, one number and one special case character (@$!-%*#?&)'
  )
  .test('passwords-match', 'Passwords must match', function (value) {
    return this.parent.newPassword === value;
  });

export const LoginSchema = Yup.object().shape({
  email,
  password: Yup.string().required('This field is required'),
  googleCode: Yup.string().min(6, 'Must be 6 digits').max(6, 'Must be 6 digits')
});

export const RecoverySchema = Yup.object().shape({
  email
});

export const RegistrationSchema = Yup.object().shape({
  firstName,
  lastName,
  email,
  password,
  repeatPassword,
  phoneNumber,
  terms: Yup.boolean().oneOf([true], 'You must accept the terms')
});

export const ApplicationSchema = Yup.object().shape({
  firstName,
  lastName,
  street: Yup.string()
    .required('This field is required')
    .when('country', {
      is: (country: string) => {
        const countriesWithMax32CharactersStreetLength = ['GBR'];
        return countriesWithMax32CharactersStreetLength.includes(country);
      },
      then: Yup.string()
        .required('This field is required')
        .max(32, 'Max street length is 32 for UK and USA')
    }),
  postcode: Yup.string().required('This field is required'),
  town: Yup.string().required('This field is required'),
  country: Yup.string()
    .required('This field is required')
    .test(
      'country-is-not-usa',
      'Sorry, we cant verify customers from USA',
      function (value) {
        return value !== 'USA';
      }
    ),
  companyStreet: Yup.string(),
  companyPostcode: Yup.string(),
  companyTown: Yup.string(),
  companyCountry: Yup.string().nullable(),
  companyName: Yup.string(),
  jobTitle: Yup.string(),
  website: Yup.string(),
  companyPhoneNumber: Yup.string().min(7, 'Invalid phone number'),
  companyEmail: Yup.string().matches(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    'You entered an invalid email'
  )
});

export const EditSchema = Yup.object().shape({
  firstName,
  lastName,
  email,
  phoneNumber,
  street: Yup.string().required('This field is required'),
  postcode: Yup.string().required('This field is required'),
  town: Yup.string().required('This field is required'),
  country: Yup.string().required('This field is required'),
  companyStreet: Yup.string(),
  companyPostcode: Yup.string(),
  companyTown: Yup.string(),
  companyCountry: Yup.string().nullable(),
  companyName: Yup.string(),
  jobTitle: Yup.string(),
  website: Yup.string(),
  companyPhoneNumber: Yup.string().min(7, 'Invalid phone number'),
  companyEmail: Yup.string().matches(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    'You entered an invalid email'
  )
});

export const ResetSchema = Yup.object().shape({
  password,
  repeatPassword
});

export const ChangePasswordScheme = Yup.object().shape({
  oldPassword: password,
  newPassword: newPassword,
  repeatNewPassword
});
