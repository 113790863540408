import * as React from 'react';
import Input from 'components/common/Input';
import cls from 'classnames';
import Button from 'components/common/Button';
import styles from './index.module.scss';
import { Checkbox } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useSelector } from 'react-redux';
import { StoreState } from 'reducers';

interface FormProps {
  formik: any;
  loading: boolean;
  handleNext: () => void;
}

const Form: React.FC<FormProps> = ({ formik, loading, handleNext }) => {
  const { t } = useTranslation();
  const country: string = useSelector((state: StoreState) => state.language.country.alpha2);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}> {t('login:registration')}</div>
      {((formik.touched.email && formik.errors.email) ||
        (formik.touched.terms && formik.errors.terms) ||
        (formik.touched.phoneNumber && formik.errors.phoneNumber) ||
        (formik.touched.firstName && formik.errors.firstName) ||
        (formik.touched.lastName && formik.errors.lastName)) && (
        <div className={styles.errorsMobile}>
          {formik.touched.firstName && formik.errors.firstName && (
            <div className={styles.errorMobile}>
              <div className={styles.circle} />
              <div>{formik.errors.firstName}</div>
            </div>
          )}
          {formik.touched.lastName && formik.errors.lastName && (
            <div className={styles.errorMobile}>
              <div className={styles.circle} />
              <div>{formik.errors.lastName}</div>
            </div>
          )}
          {formik.touched.email && formik.errors.email && (
            <div className={styles.errorMobile}>
              <div className={styles.circle} />
              <div>{formik.errors.email}</div>
            </div>
          )}
          {formik.touched.phoneNumber && formik.errors.phoneNumber && (
            <div className={styles.errorMobile}>
              <div className={styles.circle} />
              <div>{formik.errors.phoneNumber}</div>
            </div>
          )}
          {formik.touched.terms && formik.errors.terms && (
            <div className={styles.errorMobile}>
              <div className={styles.circle} />
              <div>{formik.errors.terms}</div>
            </div>
          )}
        </div>
      )}
      <div className={styles.inputWrapper}>
        <Input
          autoFocus
          id='firstName'
          name='firstName'
          value={formik.values.firstName}
          className={cls(styles.input, {
            [styles.inputError]: formik.touched.firstName && formik.errors.firstName,
          })}
          placeholder={t('common:firstName')}
          onBlur={formik.handleBlur}
          handleChange={formik.handleChange}
        />
        {formik.touched.firstName && formik.errors.firstName && (
          <div className={styles.error}>
            <div className={styles.circle} />
            <div>{formik.errors.firstName}</div>
          </div>
        )}
      </div>
      <div className={styles.inputWrapper}>
        <Input
          id='lastName'
          name='lastName'
          value={formik.values.lastName}
          className={cls(styles.input, {
            [styles.inputError]: formik.touched.lastName && formik.errors.lastName,
          })}
          placeholder={t('common:lastName')}
          onBlur={formik.handleBlur}
          handleChange={formik.handleChange}
        />
        {formik.touched.lastName && formik.errors.lastName && (
          <div className={styles.error}>
            <div className={styles.circle} />
            <div>{formik.errors.lastName}</div>
          </div>
        )}
      </div>
      <div className={styles.inputWrapper}>
        <Input
          id='email'
          name='email'
          value={formik.values.email}
          className={cls(styles.input, {
            [styles.inputError]: formik.touched.email && formik.errors.email,
          })}
          placeholder={t('common:email')}
          onBlur={formik.handleBlur}
          handleChange={formik.handleChange}
        />
        {formik.touched.email && formik.errors.email && (
          <div className={styles.error}>
            <div className={styles.circle} />
            <div>{formik.errors.email}</div>
          </div>
        )}
      </div>
      <div className={styles.inputWrapper}>
        <PhoneInput
          inputProps={{
            name: 'phoneNumber',
            id: 'phoneNumber',
            onBlur: formik.handleBlur,
          }}
          country={country}
          inputClass={cls(styles.phoneInput, {
            [styles.inputError]: formik.touched.phoneNumber && formik.errors.phoneNumber,
          })}
          buttonClass={styles.dropdown}
          dropdownStyle={{ width: 222 }}
          countryCodeEditable={false}
          placeholder=''
          value={formik.values.phoneNumber}
          onChange={v => {
            formik.setFieldValue('phoneNumber', v);
          }}
        />
        {formik.touched.phoneNumber && formik.errors.phoneNumber && (
          <div className={styles.error}>
            <div className={styles.circle} />
            <div>{formik.errors.phoneNumber}</div>
          </div>
        )}
      </div>
      <div style={{ marginBottom: 8, position: 'relative' }}>
        <Checkbox
          className={styles.checkbox}
          checked={formik.values.terms}
          onChange={e => {
            if (!formik.touched.terms && !e.target.checked) {
              formik.setFieldTouched('terms');
            }
            formik.setFieldValue('terms', e.target.checked);
          }}
          name='terms'
        >
          <Trans i18nKey='login:warning'>
            <Link
              className={styles.link}
              to={{ pathname: t('common:termsLink') }}
              target='_blank'
              rel='noopener noreferrer'
            />
            <Link
              className={styles.link}
              to={{ pathname: t('common:privacyPolicy') }}
              target='_blank'
              rel='noopener noreferrer'
            />
          </Trans>
        </Checkbox>
        {formik.touched.terms && formik.errors.terms && (
          <div className={styles.error}>
            <div className={styles.circle} />
            <div>{formik.errors.terms}</div>
          </div>
        )}
      </div>
      <div style={{ marginBottom: 22 }}>
        <Checkbox
          className={styles.checkbox}
          checked={formik.values.isSubscribed}
          onChange={() => formik.setFieldValue('isSubscribed', !formik.values.isSubscribed)}
          name='isSubscribed'
        >
          {t('login:emailSubscription')}
        </Checkbox>
      </div>
      <Button
        text={t('login:register')}
        disabled={loading}
        loading={loading}
        type='secondary'
        className={styles.button}
        onClick={handleNext}
      />
    </div>
  );
};

export default Form;
