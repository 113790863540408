import React, { useEffect } from 'react';
import { useState } from 'react';
import styles from './index.module.scss';
import { Steps } from 'antd';
import Personal from './Personal';
import { useFormik } from 'formik';
import { ApplicationSchema } from 'utils/validation';
import Onfido from './Onfido';
import { useAppDispatch } from 'store';
import { createApplicant, getUser, User } from 'actions';
import Confirmation from './Confirmation';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StoreState } from 'reducers';
import { sendMetrikGoogleEvents } from '../../utils/sendMetrikGoogleEvents';

const { Step } = Steps;

export interface KYCProps {}

interface InitialValues {
  firstName?: string;
  lastName?: string;
  street?: string;
  postcode?: string;
  town?: string;
  country?: string;
  companyStreet?: string;
  companyPostcode?: string;
  companyTown?: string;
  companyCountry?: string;
  companyName?: string;
  jobTitle?: string;
  website?: string;
  companyPhoneNumber?: string;
  companyEmail?: string;
}

const KYC: React.FC<KYCProps> = () => {
  const [step, setStep] = useState<number>(0);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [applicant, setApplicant] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user: User = useSelector((state: StoreState) => state.login.user);

  const initialValues: InitialValues = {
    firstName: user.firstName || undefined,
    lastName: user.lastName || undefined,
    street: user.street || undefined,
    postcode: user.postcode || undefined,
    town: user.town || undefined,
    country: user.country || undefined,
    companyName: user.companyName || undefined,
    companyPhoneNumber: user.companyPhoneNumber || undefined,
    companyEmail: user.companyEmail || undefined,
    companyStreet: user.companyStreet || undefined,
    companyPostcode: user.companyPostcode || undefined,
    companyTown: user.companyTown || undefined,
    companyCountry: user.companyCountry || undefined,
    jobTitle: user.jobTitle || undefined,
    website: user.website || undefined
  };
  const formik = useFormik({
    validationSchema: ApplicationSchema,
    initialValues,
    onSubmit: (values) => {
      setLoading(true);
      dispatch(createApplicant(values))
        .then((r) => {
          sendMetrikGoogleEvents('KYC', 'KYC-response')
          setApplicant(r.data.applicant.id);
          setToken(r.data.sdkToken);
          dispatch(getUser());
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    }
  });

  useEffect(() => {
    if (step === 0 && token) {
      handleNext();
    }
  }, [token, step]);

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  useEffect(() => {
    if (user.kycStatus === 'pending' || user.kycStatus === 'clear') {
      history.push('/profile');
    }
  }, [history, user.kycStatus]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.stepper}>
          <Steps size="small" current={step} labelPlacement="vertical">
            <Step
              title={<span className={styles.step}>{t('kyc:step1')}</span>}
            />
            <Step
              title={<span className={styles.step}>{t('kyc:step3')}</span>}
            />
            <Step
              title={<span className={styles.step}>{t('kyc:step4')}</span>}
            />
          </Steps>
        </div>
        <div className={styles.form}>
          {step === 0 ? (
            <Personal
              formik={formik}
              loading={loading}
              handleNext={handleNext}
            />
          ) : step === 1 ? (
            <Onfido
              token={token}
              number={user.phoneNumber}
              applicant={applicant}
              handleNext={handleNext}
            />
          ) : (
            <Confirmation />
          )}
        </div>
      </div>
    </div>
  );
};

export default KYC;
