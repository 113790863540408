import { deleteDocument, Document, getDocuments } from 'actions';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'store';
import styles from './index.module.scss';
import moment from 'moment';
import Button from 'components/common/Button';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import UploadModal from 'components/UploadModal';
import DocumentItem from './Document';
import Loader from 'components/Loader';

export interface DocumentsProps {}

const Documents: React.FC<DocumentsProps> = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadOpened, setUploadOpened] = useState(false);
  const [documents, setDocuments] = useState<Array<Document>>([]);
  const { t } = useTranslation();

  const fetchDocuments = async () => {
    setIsLoading(true);

    const { data } = await dispatch(getDocuments());

    const documents = data.map(document => ({ 
      ...document,
      createdAt: moment(document.createdAt).format('DD/MM/YYYY')
    }))

    setDocuments(documents);
    setIsLoading(false);
  };

  const handleUploadFinished = () => {
    fetchDocuments();
  };

  const handleDelete = async (id: string) => {
    try{
      await dispatch(deleteDocument(id));

      toast.success(t('notifications:deleteSuccess'));
      
      fetchDocuments();
    }catch(e){
      toast.error(t('notifications:error'))
    }
  };

  useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {
        isLoading && (<Loader />)
      }
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>{t('profile:documents')}</div>
          <Button text={t('upload:upload')} size='sm' onClick={() => setUploadOpened(true)} />
        </div>
        {!!documents?.length ? (
          <div>
            <div className={styles.header}>
              <div>{t('upload:documentName')}</div>
              <div>{t('upload:documentType')}</div>
              <div>{t('upload:documentDescription')}</div>
              <div>{t('upload:documentDate')}</div>
              <div>{t('upload:documentActions')}</div>
            </div>
            {documents.map((document: Document) => (
              <DocumentItem document={document} key={document.id} handleDelete={handleDelete} />
            ))}
          </div>
        ) : (
          // <Table columns={columns} dataSource={documents} pagination={false} />
          <div>{t('profile:noDocuments')}</div>
        )}
      </div>
      <UploadModal
        opened={uploadOpened}
        handleClose={() => setUploadOpened(false)}
        handleUploadFinished={handleUploadFinished}
      />
    </>
  );
};

export default Documents;
