import styles from './index.module.scss';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import BackButton from './back-button';
import { Steps } from 'antd';

const { Step } = Steps;
interface Props {
  steps: Array<React.ReactNode>;
  onPrevious: () => void;
  current: number;
}

const Disable: React.FC<Props> = ({ steps, current, onPrevious }) => {
  const { t } = useTranslation('authenticator');

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.card}>
          <div className={cls(styles.form, styles.disabled)}>
            {current > 0 && <BackButton onClick={onPrevious} />}
            <div className={styles.stepper}>
              <Steps size="small" current={current} labelPlacement="vertical">
                <Step
                  title={
                    <span className={styles.step}>{t('steps.email')}</span>
                  }
                />
                <Step
                  title={
                    <span className={styles.step}>
                      {t('steps.enableGoogleAuthenticator')}
                    </span>
                  }
                />
              </Steps>
            </div>
            {steps[current]}
          </div>
        </div>
      </div>
    </>
  );
};

export default Disable;
