import React from 'react';
import styles from './index.module.scss';
import cls from 'classnames';

interface InputErrorProps {
  message: string;
}

const InputError: React.FC<InputErrorProps> = ({ message = '' }) => {
  return (
    <div
      className={cls({
        [styles.error]: true
      })}
    >
      <div className={styles.circle} />
      <div>{message}</div>
    </div>
  );
};

export default InputError;
