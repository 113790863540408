import { Card, Divider, Tag } from 'antd';
import { UserInfo } from './index';

import styles from 'pages/Admin/index.module.scss';

export const UserHeader: React.FC<{ user: UserInfo }> = ({ user }) => {
  const withDefaultValue = (value: string, defaultValue = 'No data') => {
    return value ? value : defaultValue;
  };

  return (
    <>
      <Card className="admin-card">
        <div className={styles.header}>
          <h2 className={styles.heading}>
            {withDefaultValue(user.firstName)} {withDefaultValue(user.lastName)}
          </h2>
          <Tag className={styles.text}>
            Last Update: {withDefaultValue(user.updatedAt)}
          </Tag>
          <Tag className={styles.text}>
            Registration: {withDefaultValue(user.createdAt)}
          </Tag>
          <Tag className={styles.text}>
            KYC: {withDefaultValue(user.kycCompletedAt)}
          </Tag>
          <Tag className={styles.text}>
            Categorization: {withDefaultValue(user.categorizationCompletedAt)}
          </Tag>
        </div>
      </Card>
      <Divider />
    </>
  );
};
