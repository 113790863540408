import styles from './index.module.scss';
import { Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';
import BackButton from './back-button';

const { Step } = Steps;
interface Props {
  steps: Array<React.ReactNode>;
  current: number;
  onPrevious: () => void;
}

const Enable: React.FC<Props> = ({ current, steps, onPrevious }) => {
  const { t } = useTranslation('authenticator');

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.card}>
          {current > 0 && <BackButton onClick={onPrevious} />}
          <div className={styles.form}>
            <div className={styles.stepper}>
              <Steps size="small" current={current} labelPlacement="vertical">
                <Step
                  title={
                    <span className={styles.step}>
                      {t('steps.downloadApp')}
                    </span>
                  }
                />
                <Step
                  title={
                    <span className={styles.step}>{t('steps.scanQrCode')}</span>
                  }
                />
                <Step
                  title={
                    <span className={styles.step}>{t('steps.backupKey')}</span>
                  }
                />
                <Step
                  title={
                    <span className={styles.step}>{t('steps.email')}</span>
                  }
                />
                <Step
                  title={
                    <span className={styles.step}>
                      {t('steps.enableGoogleAuthenticator')}
                    </span>
                  }
                />
              </Steps>
            </div>

            {steps[current]}
          </div>
        </div>
      </div>
    </>
  );
};

export default Enable;
