import Input from 'components/common/Input';
import Button from 'components/common/Button';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as BackupIcon } from 'assets/icons/backup.svg';
import styles from './index.module.scss';
import CopyField from 'components/ui/copy-field';
import { useTranslation } from 'react-i18next';
import InputErrors from 'components/ui/input-errors';
import {
  VerifyGoogleAuthenticatorCodeSchema,
  VerifyGoogleAuthenticatorEmailCodeSchema
} from 'validations/schema';
import { useFormik } from 'formik';
interface RegisterAuthenticatorProps {
  onClickNext: () => void;
  disabled: boolean;
}

export const RegisterAuthenticator: React.FC<RegisterAuthenticatorProps> = ({
  disabled,
  onClickNext
}) => {
  const { t } = useTranslation('authenticator');

  return (
    <>
      <div className={styles.title}>Enable Google Authenticator</div>
      <DownloadIcon className={styles.icon} />
      <div className={styles.text}>{t('text.downloadGoogleAuthenticator')}</div>
      <div className={styles.buttons}>
        <a
          href="https://apps.apple.com/us/app/google-authenticator/id388497605?itsct=apps_box_badge&amp;itscg=30200"
          style={{
            display: 'inline-block',
            overflow: 'hidden',
            borderRadius: 13,
            width: 135,
            height: 52
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=250x83&amp;releaseDate=1284940800&h=288c84cc3566737997a818b26a76c159"
            alt="Download on the App Store"
            style={{
              borderRadius: 13,
              padding: 8,
              width: 135,
              height: 52
            }}
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            style={{ width: 135, height: 52 }}
          />
        </a>
      </div>
      <Button
        text={t('button.continue')}
        type="secondary"
        onClick={onClickNext}
        className={styles.button}
        disabled={disabled}
        loading={disabled}
      />
    </>
  );
};

interface QRCodeProps {
  qrcode: {
    qrcode: string;
    googleCode: string;
  };
  onClickNext: () => void;
}

export const QRCode: React.FC<QRCodeProps> = ({ qrcode, onClickNext }) => {
  const { t } = useTranslation('authenticator');

  return (
    <>
      <div className={styles.title}>Enable Google Authenticator</div>
      <div className={styles.text}>{t('text.scanQrCode')}</div>
      <div className={styles.code}>
        <div className={styles.qrcode}>
          <img src={qrcode.qrcode} alt="qrcode" />
        </div>
        <div>
          <div className={styles.warning}>{t('text.cantScanQrCode')}</div>
          <CopyField text={qrcode.googleCode} />
        </div>
      </div>
      <Button
        text={t('button.continue')}
        type="secondary"
        onClick={onClickNext}
        className={styles.button}
      />
    </>
  );
};

interface BackupKeyProps {
  onClickNext: () => void;
  backupCode: string;
}

export const BackupKey: React.FC<BackupKeyProps> = ({
  onClickNext,
  backupCode
}) => {
  const { t } = useTranslation('authenticator');

  return (
    <>
      <div className={styles.title}>Enable Google Authenticator</div>
      <div className={styles.text}>{t('text.writeDownBackupKeys')}</div>
      <div className={styles.code}>
        <BackupIcon className={styles.backup} />
        <div>
          <div className={styles.warning}>
            {t('text.resetGoogleAuthenticator')}
          </div>
          <CopyField text={backupCode} />
        </div>
      </div>
      <Button
        text={t('button.continue')}
        type="secondary"
        onClick={onClickNext}
        className={styles.button}
      />
    </>
  );
};
interface VerifyAuthenticatorEmailProps {
  email: string;
  handleSubmit: (emailCode: string) => void;
  handleResendCode: () => void;
  title: string;
  text: string;
}

export const VerifyAuthenticatorEmail: React.FC<VerifyAuthenticatorEmailProps> =
  ({ email, handleSubmit, handleResendCode, title, text }) => {
    const { t } = useTranslation('authenticator');

    const formik = useFormik({
      validationSchema: VerifyGoogleAuthenticatorEmailCodeSchema,
      initialValues: {
        emailCode: ''
      },
      onSubmit: ({ emailCode }) => {
        handleSubmit(emailCode);
      }
    });

    return (
      <>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>
        <div className={styles.inputs}>
          <div className={styles.first}>
            <div className={styles.inputWrapper}>
              <Input
                id="emailCode"
                name="emailCode"
                placeholder={t('input.placeholder.emailConfirmationCodeField')}
                value={formik.values.emailCode}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={styles.input}
                type="text"
              />

              {formik.touched.emailCode && formik.errors.emailCode && (
                <InputErrors message={formik.errors.emailCode} />
              )}
            </div>

            <div className={styles.info}>
              {t('input.label.enterCode')} {email}
            </div>
          </div>
        </div>
        <Button
          text={t('button.continue')}
          type="secondary"
          onClick={formik.handleSubmit}
          className={styles.button}
        />

        <span className={styles.resend__button} onClick={handleResendCode}>
          {t('button.sendCode')}
        </span>
      </>
    );
  };

interface VerifyAuthenticatorCodeProps {
  handleSubmit: (googleCode: string) => void;
  title: string;
  text: string;
}

export const VerifyAuthenticatorCode: React.FC<VerifyAuthenticatorCodeProps> =
  ({ handleSubmit, title, text }) => {
    const { t } = useTranslation('authenticator');

    const formik = useFormik({
      validationSchema: VerifyGoogleAuthenticatorCodeSchema,
      initialValues: {
        googleCode: ''
      },
      onSubmit: ({ googleCode }) => {
        handleSubmit(googleCode);
      }
    });

    return (
      <>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>
        <div className={styles.inputs}>
          <div className={styles.first}>
            <div className={styles.inputWrapper}>
              <Input
                id="googleCode"
                name="googleCode"
                placeholder={t('input.placeholder.googleConfirmationCodeField')}
                value={formik.values.googleCode}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={styles.input}
                type="text"
              />

              {formik.touched.googleCode && formik.errors.googleCode && (
                <InputErrors message={formik.errors.googleCode} />
              )}
            </div>

            <div className={styles.info}>
              {t('input.label.enterGoogleCode')}
            </div>
          </div>
        </div>
        <Button
          text={t('button.submit')}
          type="secondary"
          onClick={formik.handleSubmit}
          className={styles.button}
        />
      </>
    );
  };
