import { useState } from 'react';
import styles from './index.module.scss';
import Form from './Form';
import Confirmation from './Confirmation';
import { forgotPassword, resendConfirmation } from 'actions';
import { useAppDispatch } from 'store';
import { useFormik } from 'formik';
import { RecoverySchema } from 'utils/validation';

interface InitialValues {
  email: string;
}

const Registration: React.FC = () => {
  const [step, setStep] = useState<number>(0);
  const dispatch = useAppDispatch();

  const initialValues: InitialValues = { email: '' };

  const handleResendConfirmation = (email: string) => {
    dispatch(resendConfirmation(email));
  };

  const formik = useFormik({
    validationSchema: RecoverySchema,
    initialValues,
    onSubmit: ({ email }) => {
      dispatch(forgotPassword(email))
        .then(() => setStep(2))
        .catch(e => {
          if (typeof e.response.data.message === 'string') {
            formik.setFieldError('email', e.response.data.message);
          }
        })
        .finally(() => formik.setSubmitting(false));
    },
  });

  return (
    <div className={styles.wrapper}>
      {step === 0 ? <Form formik={formik} resendConfirmation={handleResendConfirmation} /> : <Confirmation />}
    </div>
  );
};

export default Registration;
