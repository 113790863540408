import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Input,
  Select
} from 'antd';
import { SelectValue } from 'antd/lib/select';
import { useFormik } from 'formik';
import moment, { Moment } from 'moment';
import { Error } from 'pages/Admin/components/Error';
import {
  createProject,
  getAllCategories,
  LanguageCode,
  ProjectStatus
} from 'pages/Admin/projects.api';
import { ProjectCreatingSchema } from 'pages/Admin/validations';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import styles from '../../../index.module.scss';
import { ProjectImagesForm } from '../Editing/ProjectImagesForm';
import { ProjectDocumentsForm } from './ProjectDocumentsForm';
import { ProjectTranslationsForm } from './ProjectsTranslationsForm';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../../../reducers';

const { Option } = Select;

export const ProjectCreatingForm = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [createdProjectID, setCreatedProjectID] = useState<null | string>(null);
  const currencyList = useSelector((store: StoreState) => store.currency.allowedCurrenciesList);

  const defaultStartDate = moment();
  const defaultEndDate = moment();

  const projectSettings = useFormik({
    validationSchema: ProjectCreatingSchema,
    initialValues: {
      projectImage: '',
      currencyCode: 'USD',
      goal: 0,
      status: ProjectStatus.ComingSoon,
      minimumInvestment: 0,
      expectedReturns: 0,
      website: '',
      isVisibleWebsite: false,
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      categoryIds: [],
      tokenPrice: '',
      issuer: '',
      ticker: '',
      translationFields: {
        projectName: '',
        location: '',
        company: '',
        tagline: '',
        shortDescription: '',
        terms: '',
        description: '',
        isDefault: true,
        isVisibleCompany: false
      }
    },
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const payload = values;
        const response = await createProject(LanguageCode.EN, payload);
        toast.success('Project Created');
        setCreatedProjectID(response.data.id);
        history.push(`/admin/projects/edit/${response.data.id}`);
      } catch (e: any) {
        if (e.response.status !== 422) {
          toast.error(e.response.data.message);
          return;
        }
        if (e.response.data.message) {
          const errorMessages = e.response.data.message;
          for (let key in errorMessages) {
            for (let k in errorMessages[key]) {
              toast.error(errorMessages[key][k][0]);
            }
          }
        }
      } finally {
        setLoading(false);
      }
    }
  });

  const [categories, setCategories] = useState<
    Array<{ id: string; name: string }>
  >([]);

  const handleProjectSettingsSelectChange = (
    value: SelectValue,
    key: keyof typeof projectSettings.values
  ) => {
    projectSettings.setFieldTouched(key);
    projectSettings.setFieldValue(key, value);
  };

  const handleProjectSettingsDateChange = (
    date: Moment | null,
    dateString: string,
    key: keyof typeof projectSettings.values
  ) => {
    projectSettings.setFieldValue(key, date);
  };

  useEffect(() => {
    const getInitialData = async () => {
      const categories = (await getAllCategories()).data.results;

      setCategories(categories);
    };

    getInitialData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card className='admin-card'>
        <h1>Project Settings</h1>
        <Divider className='admin-divider' />
        <div className={styles.flex}>
          <div className={styles.part}>
            <div className={styles.field}>
              <label className={styles.label}>Goal</label>
              <Input
                id='goal'
                name='goal'
                value={projectSettings.values.goal}
                onChange={projectSettings.handleChange}
                placeholder='Project Goal'
                type='number'
              />
              <Error
                visible={
                  projectSettings.touched.goal && projectSettings.errors.goal
                }
                message={projectSettings.errors.goal}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Status</label>
              <Select
                id='status'
                value={projectSettings.values.status}
                onChange={(value) =>
                  handleProjectSettingsSelectChange(value, 'status')
                }
                className={styles.select}
              >
                <Option value={ProjectStatus.ComingSoon}>Coming Soon</Option>
                <Option value={ProjectStatus.Ongoing}>Ongoing</Option>
                <Option value={ProjectStatus.Past}>Past</Option>
              </Select>
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Currency</label>
              <Select
                id='status'
                value={projectSettings.values.currencyCode}
                onChange={(value) =>
                  handleProjectSettingsSelectChange(value, 'currencyCode')
                }
                className={styles.select}
              >
                {currencyList.map((item) => (
                  <Option value={item.code}>{item.code}</Option>
                  ))}
              </Select>
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Minimum investment</label>
              <Input
                id='minimumInvestment'
                name='minimumInvestment'
                value={projectSettings.values.minimumInvestment}
                onChange={projectSettings.handleChange}
                placeholder='Minimum investment'
                type='number'
              />
              <Error
                visible={
                  projectSettings.touched.minimumInvestment &&
                  projectSettings.errors.minimumInvestment
                }
                message={projectSettings.errors.minimumInvestment}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Expected Returns</label>
              <Input
                id='expectedReturns'
                name='expectedReturns'
                value={projectSettings.values.expectedReturns}
                onChange={projectSettings.handleChange}
                placeholder='Expected Returns'
                type='number'
              />
              <Error
                visible={
                  projectSettings.touched.expectedReturns &&
                  projectSettings.errors.expectedReturns
                }
                message={projectSettings.errors.expectedReturns}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Token Price</label>
              <Input
                id='tokenPrice'
                name='tokenPrice'
                value={projectSettings.values.tokenPrice}
                onChange={projectSettings.handleChange}
                placeholder='Token price'
              />
              <Error
                visible={
                  projectSettings.touched.tokenPrice &&
                  projectSettings.errors.tokenPrice
                }
                message={projectSettings.errors.tokenPrice}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Issuer</label>
              <Input
                id='issuer'
                name='issuer'
                value={projectSettings.values.issuer}
                onChange={projectSettings.handleChange}
                placeholder='Issuer'
              />
              <Error
                visible={
                  projectSettings.touched.issuer &&
                  projectSettings.errors.issuer
                }
                message={projectSettings.errors.issuer}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Ticker</label>
              <Input
                id='ticker'
                name='ticker'
                value={projectSettings.values.ticker}
                onChange={projectSettings.handleChange}
                placeholder='Ticker'
              />
              <Error
                visible={
                  projectSettings.touched.ticker &&
                  projectSettings.errors.ticker
                }
                message={projectSettings.errors.ticker}
              />
            </div>
          </div>
          <div className={styles.part}>
            <div className={styles.field}>
              <label className={styles.label}>Website</label>
              <Input
                id='website'
                name='website'
                value={projectSettings.values.website}
                onChange={projectSettings.handleChange}
                placeholder='Website'
              />
              <div style={{ padding: '15px 0 0' }}>
                <Checkbox
                  checked={projectSettings.values.isVisibleWebsite}
                  onChange={(e) =>
                    projectSettings.setFieldValue(
                      'isVisibleWebsite',
                      e.target.checked
                    )
                  }
                />{' '}
                <span>Publish data?</span>
              </div>
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Start Date</label>
              <DatePicker
                id='startDate'
                name='startDate'
                defaultValue={moment('YYYY-MM-DD')}
                format={'YYYY-MM-DD'}
                onChange={(value, dateString) =>
                  handleProjectSettingsDateChange(
                    value,
                    dateString,
                    'startDate'
                  )
                }
                value={projectSettings.values.startDate}
              />
              <Error
                visible={
                  projectSettings.touched.startDate &&
                  projectSettings.errors.startDate
                }
                message={projectSettings.errors.startDate}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>End Date</label>
              <DatePicker
                id='endDate'
                name='endDate'
                defaultValue={moment()}
                onChange={(value, dateString) =>
                  handleProjectSettingsDateChange(value, dateString, 'endDate')
                }
                format={'YYYY-MM-DD'}
                value={projectSettings.values.endDate}
              />
              <Error
                visible={
                  projectSettings.touched.endDate &&
                  projectSettings.errors.endDate
                }
                message={projectSettings.errors.endDate}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Categories</label>
              <Select
                mode='multiple'
                placeholder='Please select'
                id='categoryIds'
                style={{ width: '100%' }}
                onChange={(value) => {
                  handleProjectSettingsSelectChange(value, 'categoryIds');
                }}
                value={projectSettings.values.categoryIds}
              >
                {categories.map((category) => (
                  <Option value={category.id} key={category.name}>
                    {category.name}
                  </Option>
                ))}
              </Select>
              <Error
                visible={
                  projectSettings.touched.categoryIds &&
                  projectSettings.errors.categoryIds
                }
                message={projectSettings.errors.categoryIds}
              />
            </div>
          </div>
        </div>
        <Divider className='admin-divider' />
      </Card>
      <ProjectTranslationsForm
        form={projectSettings.values.translationFields}
        touched={projectSettings.touched.translationFields}
        errors={projectSettings.errors.translationFields}
        onChange={projectSettings.handleChange}
        onDescriptionChange={(e: string) =>
          projectSettings.setFieldValue('translationFields.description', e)
        }
        onCheckboxChange={(e) => {
          projectSettings.setFieldValue(
            'translationFields.isDefault',
            e.target.checked
          );
        }}
        onCompanyCheckboxChange={(e) => {
          projectSettings.setFieldValue(
            'translationFields.isVisibleCompany',
            e.target.checked
          );
        }}
      />
      <Divider className='admin-divider' />
      <Card className='admin-card'>
        <Button
          type='ghost'
          block
          className='admin-success-button'
          size='large'
          loading={loading}
          onClick={(e) => projectSettings.handleSubmit()}
        >
          Submit Project Settings
        </Button>
      </Card>
      <Divider className='admin-divider' />
      {createdProjectID ? <ProjectImagesForm id={createdProjectID} /> : null}
      {createdProjectID ? <ProjectDocumentsForm id={createdProjectID} /> : null}
    </>
  );
};
