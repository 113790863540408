const convertCurrency = (
  amount: number,
  currentCurrency: string,
  rate: any,
  nativeCodeCurrency: string,
  format: string = 'en-EN',
  isShowCents: boolean = false
) => {

  if (!rate) {
    return new Intl.NumberFormat(format, {
      style: 'currency',
      currency: nativeCodeCurrency,
      minimumFractionDigits: 0
    }).format(amount);
  }

  const convertAmount = isShowCents ?  amount * rate : Math.ceil(amount * rate);

  return new Intl.NumberFormat(format, {
    style: 'currency',
    currency: currentCurrency,
    minimumFractionDigits: isShowCents ? 2 : 0
  }).format(convertAmount);
};

export default convertCurrency;