import { useParams } from 'react-router-dom';
import { ProjectSettingsForm } from './ProjectSettingsForm';
import styles from '../../../index.module.scss';
import { ProjectTranslationsForm } from './ProjectTranslationsForm';
import { ProjectImagesForm } from './ProjectImagesForm';
import { ProjectDocumentsForm } from './ProjectDocumentsForm';

export const ProjectEditing = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <div className={styles.projects}>
      <ProjectSettingsForm id={id} />
      <ProjectImagesForm id={id} />
      <ProjectDocumentsForm id={id} />
      <ProjectTranslationsForm id={id} />
    </div>
  );
};
