import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Layout from 'components/Layout';
import Landing from 'pages/Landing';
import Registration from 'pages/Registration';
import Login from 'pages/Login';
import Project from 'pages/Project';
import KYC from 'pages/KYC';
import Profile from 'pages/Profile';
import Admin from 'pages/Admin';
// import Invest from 'pages/Invest';
import Recovery from 'pages/Recovery';
import GoogleAuthenticator from 'pages/GoogleAuthenticator';
import Reset from 'pages/Reset';
import Confirmation from 'pages/Confirmation';
import { useSelector } from 'react-redux';
import { StoreState } from 'reducers';

interface PrivateRouteProps {
  component: React.ReactNode;
  path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const loggedIn: boolean = useSelector(
    (state: StoreState) => state.login.loggedIn
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn ? (
          Component
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const Router: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Switch>
      <Route exact path="/">
        <Layout title={t('common:home')}>
          <Landing />
        </Layout>
      </Route>
      <Route path="/registration">
        <Layout title={t('login:registration')}>
          <Registration />
        </Layout>
      </Route>
      <Route path="/recovery">
        <Layout title={t('login:recovery')}>
          <Recovery />
        </Layout>
      </Route>
      <Route path="/confirm/:token">
        <Layout title={t('login:confirmation')}>
          <Confirmation />
        </Layout>
      </Route>
      <Route path="/login">
        <Layout title={t('login:login')}>
          <Login />
        </Layout>
      </Route>
      <Route path="/reset/:token">
        <Layout title={t('login:reset')}>
          <Reset />
        </Layout>
      </Route>
      <PrivateRoute
        path="/2fa"
        component={
          <Layout title="2FA">
            <GoogleAuthenticator />
          </Layout>
        }
      />
      {/* <PrivateRoute
        path='/project/:id/invest'
        component={
          <Layout title={t('common:project')}>
            <Invest />
          </Layout>
        }
      /> */}
      <PrivateRoute
        path="/project/:id"
        component={
          <Layout title={t('common:project')}>
            <Project />
          </Layout>
        }
      />
      <PrivateRoute
        path="/kyc"
        component={
          <Layout title={t('common:kyc')}>
            <KYC />
          </Layout>
        }
      />
      <PrivateRoute
        path="/profile/:tab?"
        component={
          <Layout title={t('common:profile')} isProfile>
            <Profile />
          </Layout>
        }
      />
      <Route path="/admin">
        <Layout title={t('common:404')}>
          <Admin />
        </Layout>
      </Route>
      <Route>
        <Layout title={t('common:404')}>
          <div>{t('common:404')}</div>
        </Layout>
      </Route>
    </Switch>
  );
};

export default Router;
