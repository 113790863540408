import { ChangeEvent, FC, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import { StoreState } from 'reducers';
// import { useTranslation } from 'react-i18next';
// import HeaderOverview from './Header';

import LineDiagram from './LineDiagram';
// import BarDiagram from './BarDiagram';
import TransactionsHistory from './TransactionsHistory';
// import PortfolioOverview from './Portfolio';
import TwoFA from './Cards/TwoFA';
import Currency from './Cards/Currency';
import Verify from '../Verify';
import { User } from '../../../actions';
// import Accreditation from './Cards/Accreditation';
import { useTranslation } from 'react-i18next';
import { getVolumeDiagram, VolumeDiagram } from '../overview.api';
import { useRangeForDiagrams } from '../../../hooks/useRangeForDiagrams';



const Overview: FC = () => {
  const user: User = useSelector((state: StoreState) => state.login.user);
  const [ranges, setRanges] = useRangeForDiagrams('volumeOfInvestment');
  const [volumeOfInvestmentsData, setVolumeOfInvestmentsData] = useState<VolumeDiagram>([]);
  const [diagramsValue, setDiagramsValue] = useState({volumeOfInvestment: '1'});
  const { t } = useTranslation('profile');
  const isTfaEnabled = useSelector(
    (state: StoreState) => state.login.user.isTfaEnabled
  );

  useEffect(() => {
    if (!ranges.volumeOfInvestment) return
    try {
      const VolumeOfInvestmentsReq = async () => {

        const {data} = await getVolumeDiagram(ranges.volumeOfInvestment);
        setVolumeOfInvestmentsData(data);
      };
      VolumeOfInvestmentsReq();
    } catch (e) {
      console.log('VolumeOfInvestmentsReq ERROR', e);
    }
  }, [ranges.volumeOfInvestment]);

  const onChangeDiagramSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setDiagramsValue((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))

    if (e.target.value !== 'custom') {
      setRanges({
        [e.target.name]: e.target.value
      })
    }
  }

  const onChangeDateRange = (dateString: [string, string] ,
                             name: string) => {
    setRanges({
      [name]: {
        startDate: dateString[0],
        endDate: dateString[1]
      }
    })
  }

  return (
    <div className={styles.container}>
      {/*<HeaderOverview*/}
      {/*  totalOffering={124.3245}*/}
      {/*  projects={234}*/}
      {/*  active={234}*/}
      {/*  completed={345}*/}
      {/*  pending={654}*/}
      {/*  earnings={222}*/}
      {/*  currentYear={1111}*/}
      {/*  priorYears={0}*/}
      {/*/>*/}
      <div className={styles.wrapperDiagram}>

      {/*  <LineDiagram*/}
      {/*    data={data}*/}
      {/*    title='ROI'*/}
      {/*    lineAmount={2}*/}
      {/*    mainLineColor={'#3BB46C'}*/}
      {/*    secondLineColor={'#D75033'}*/}
      {/*  />*/}

        <LineDiagram
          data={volumeOfInvestmentsData}
          title={t('volumeOfInvestment')}
          lineAmount={1}
          mainLineColor={'#276DF1'}
          name={'volumeOfInvestment'}
          onChangeSelect={onChangeDiagramSelect}
          value={diagramsValue.volumeOfInvestment}
          onChangeDateRange={onChangeDateRange}
        />
      </div>

      {/*<PortfolioOverview />*/}

      <div className={styles.wrapperDiagram}>
        {/*<BarDiagram*/}
        {/*  data={data1}*/}
        {/*  title={t('volumeOfInvestmentsByCategory')}*/}
        {/*/>*/}

        <TransactionsHistory />
      </div>

      <div className={styles.cards}>
        <Currency />
        <TwoFA type={isTfaEnabled ? 'on' : 'off'} />
        <Verify user={user} />
        {/*{user.kycStatus === 'clear' &&*/}
        {/*  // <Accreditation user={user}/>*/}
        {/*}*/}
      </div>
    </div>
  );
};

export default Overview;
