import React from 'react';
import { ReactComponent as MarkerIcon } from 'assets/icons/marker.svg';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { Offer } from 'actions';
import { Link } from 'react-router-dom';
import StatusBadge from 'components/StatusBadge';
import cls from 'classnames';
import { getImageLink } from 'pages/Admin/utils/get-image-link';
import { useSelector } from 'react-redux';
import { StoreState } from '../../reducers';
import convertCurrency from '../../utils/convertCurrency';

interface ProjectCardProps {
  item: Offer;
  blocked: boolean;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  item: {
    id,
    projectName,
    projectImage,
    location,
    status,
    categories,
    company,
    goal,
    minimumInvestment,
    expectedReturns,
    currencyCode
  },
  blocked
}) => {
  const { t } = useTranslation();
  const user = useSelector((state: StoreState) => state.login.user);
  const exchangeCurrency = useSelector((state: StoreState) => state.currency.exchangeCurrency);


  return (
    <Link className={styles.hiddenLink} to={`/project/${id}`}>
      <div className={cls(styles.wrapper, { [styles.blocked]: blocked })}>
        <StatusBadge status={status} className={styles.badge} />
        <img className={styles.image} src={getImageLink(projectImage)} alt="" />
        <div className={styles.info}>
          <div className={styles.categories}>
            {categories.map((category, i) => (
              <div key={i} className={styles.chip}>
                {category}
              </div>
            ))}
          </div>
          <div className={styles.title}>{projectName}</div>
          <div className={styles.location}>
            <MarkerIcon />
            {location}
          </div>
          <div className={styles.properties}>
            <div>
              <div className={styles.property}>
                <div className={styles.key}>{t('project:goal')}</div>
                <div className={styles.value}>
                  {/*${goal.toLocaleString('en-US')}*/}
                  {convertCurrency(
                    goal,
                    user.currencyCode,
                    exchangeCurrency[currencyCode]?.[user.currencyCode],
                    currencyCode)}
                </div>
              </div>
              <div className={styles.property}>
                <div className={styles.key}>
                  {t('project:minimumInvestment')}
                </div>
                <div className={styles.value}>
                  {/*${minimumInvestment.toLocaleString('en-US')}*/}
                  {convertCurrency(
                    minimumInvestment,
                    user.currencyCode,
                    exchangeCurrency[currencyCode]?.[user.currencyCode],
                    currencyCode
                  )}
                </div>
              </div>
            </div>
            <div>
              {company && (
                <div className={styles.property}>
                  <div className={styles.key}>{t('project:company')}</div>
                  <div className={`${styles.value} ${styles.companyDescription}`}>{company}</div>
                </div>
              )}
              <div className={styles.property}>
                <div className={styles.key}>{t('project:expectedReturns')}</div>
                <div className={styles.value}>
                  <div
                    className={styles.progress}
                    style={{ width: `${expectedReturns}%` }}
                  />
                  <span>{expectedReturns}%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProjectCard;
