import axios from 'axios';
import Cookies from 'universal-cookie';
import { API_URL } from 'config';

const cookies = new Cookies();

const httpClient = axios.create({
  baseURL: API_URL
});

httpClient.interceptors.request.use(
  (config) => {
    const token = cookies.get('token');
    const authorization = token ? `Bearer ${token}` : '';
    config.headers.common['Authorization'] = authorization;
    return config;
  },
  (error) => Promise.reject(error)
);

export default httpClient;
