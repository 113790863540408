import * as React from 'react';
import styles from './index.module.scss';
import Carousel from 'react-multi-carousel';
import Buttons from './Buttons';
import { getImageLink } from 'pages/Admin/utils/get-image-link';

const responsive = {
  desktop: {
    breakpoint: { max: 11120, min: 1280 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 1279, min: 0 },
    items: 1
  }
};

export interface SliderProps {
  items: string[];
  handleClick: (id: number) => void;
  setActiveIndex: (index: number) => void;
}

const Slider: React.FC<SliderProps> = ({
  items,
  handleClick,
  setActiveIndex
}) => {
  return (
    <div className={styles.carousel}>
      <Carousel
        afterChange={(previousSlide, { currentSlide }) => {
          setActiveIndex(currentSlide);
        }}
        draggable={true}
        keyBoardControl={false}
        responsive={responsive}
        infinite={false}
        arrows={false}
        customButtonGroup={<Buttons />}
        renderButtonGroupOutside
        className=""
        containerClass={styles.container}
        dotListClass=""
        itemClass={styles.item}
        sliderClass=""
        additionalTransfrom={0}
        centerMode={false}
        slidesToSlide={1}
      >
        {items.map((image: string, i: number) => (
          <div key={i} className={styles.image} onClick={() => handleClick(i)}>
            <img src={getImageLink(image)} alt="" />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Slider;
