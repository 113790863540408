import { statuses } from 'actions';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

export interface StatusBadgeProps {
  status: string;
  className?: string;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status, className }) => {
  const { t } = useTranslation();
  return (
    <div className={cls(styles.wrapper, className)}>
      <div className={styles.circle} style={{ backgroundColor: statuses[status as keyof typeof statuses].color }} />
      {t(statuses[status as keyof typeof statuses].name)}
    </div>
  );
};

export default StatusBadge;
