import axios from 'axios';
import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import { ActionTypes } from 'actions';
import Cookies from 'universal-cookie';
import { User } from './types';
import { getDomain } from 'utils/domain';

const cookies = new Cookies();

const { REACT_APP_API } = process.env;

export interface LoginSuccessAction {
  type: ActionTypes.loginSuccess;
  user: User;
}

export interface CategorySuccessAction {
  type: ActionTypes.userCategory;
  data: any;
}

export interface LogoutSuccessAction {
  type: ActionTypes.logoutSuccess;
}

export interface GoogleAuthenticatorEnabled {
  type: ActionTypes.googleAuthenticatorEnabled;
}

export interface GoogleAuthenticatorDisabled {
  type: ActionTypes.googleAuthenticatorDisabled;
}

export const login =
  (email: string, password: string, googleCode?: string) =>
    (dispatch: Dispatch) => {
      const url = REACT_APP_API + '/auth/login';

      const payload = {
        email,
        password,
        googleCode: googleCode ? googleCode : undefined
      };

      return axios.post<{ access_token: string; expires_in: number }>(
        url,
        payload
      );
    };

export const logout = (): LogoutSuccessAction => {
  cookies.remove('token', { path: '/', domain: getDomain() });

  return {
    type: ActionTypes.logoutSuccess
  };
};

export const getUser = () => async (dispatch: Dispatch) => {
  const url = REACT_APP_API + '/profile/me';
  const complianceUrl = REACT_APP_API + '/profile/compliance';

  const options = {
    headers: { Authorization: `Bearer ${cookies.get('token')}` }
  };

  try {
    const { data } = await axios.get(url, options);
    dispatch({ type: ActionTypes.loginSuccess, user: data });
try {
  const { data: complianceData } = await axios.get(complianceUrl, options);
  dispatch({ type: ActionTypes.userCategory, data: complianceData });

} catch (e) {
  console.log(e);
}
  } catch {
    dispatch({ type: ActionTypes.logoutSuccess });
  }
};

export const verifyEmail = (email: string) => (dispatch: Dispatch) => {
  const url = REACT_APP_API + '/accounts/signUp/email-verify';
  return axios.get(url, { params: { email } });
};

export const register =
  (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    isSubscribed: boolean,
    phoneNumber: string
  ) =>
    (dispatch: Dispatch) => {
      const url = REACT_APP_API + '/accounts/signUp';

      return axios.post<{ access_token: string }>(url, {
        firstName,
        lastName,
        email,
        password,
        isSubscribed,
        phoneNumber
      });
    };

export const resendConfirmation = (email: string) => (dispatch: Dispatch) => {
  const url = REACT_APP_API + '/accounts/resend-confirmation';

  axios
    .post(url, { email })
    .then(() => toast.success(i18n.t('notifications:emailResent')))
    .catch((e) => {
      if (typeof e.response.data.message === 'string') {
        toast.error(e.response.data.message);
      }
    });
};

export const subscribe = (email: string) => (dispatch: Dispatch) => {
  const url = REACT_APP_API + '/accounts/subscribe';

  axios
    .post(url, { email })
    .then(() => toast.success(i18n.t('notifications:emailSubscribed')))
    .catch((e) => {
      if (typeof e.response.data.message === 'string') {
        toast.error(e.response.data.message);
      } else {
        toast.error(i18n.t('notifications:error'));
      }
    });
};

export const confirmEmail = (token: string) => (dispatch: Dispatch) => {
  const url = REACT_APP_API + `/accounts/signUp/accept/${token}`;

  return axios.post(url);
};

export const forgotPassword = (email: string) => (dispatch: Dispatch) => {
  const url = REACT_APP_API + '/accounts/forgot-password';

  return axios.post(url, { email });
};

export const verifyToken = (token: string) => (dispatch: Dispatch) => {
  const url = REACT_APP_API + `/accounts/forgot-password/verify/${token}`;

  return axios.get(url, { params: { token } });
};

export const resetPassword =
  (token: string, password: string) => (dispatch: Dispatch) => {
    const url = REACT_APP_API + `/accounts/restore-password/${token}`;

    return axios.put(url, { password });
  };
